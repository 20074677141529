import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { TopbarProps } from '../../interfaces/topbar'
import Select from "react-select";
import { connect } from "react-redux";
import { authAzure, userList } from "../../redux/actions/user";
import jwt from 'jsonwebtoken'
import config from '../config/setting'
// import { logoutUser } from '../../../../yum-admin/src/redux'
import { logoutUser } from './../../redux/actions/user';
import { secretKey } from "../../secret";

class Topbar extends Component<TopbarProps, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  componentDidMount() {
    this.props.userList();
  }
  handleItemsOnUser = (e: any) => {
    if (e.target.value != '') {
      window.location.href = "/user-record";
    }
  };

  azure = () => {
    this.props.authAzure();
  }

  render() {
    console.log("sessionStorage: ", sessionStorage)
    let user = sessionStorage.userId;
    let roleId: any = sessionStorage.getItem("role");
    // if (sessionStorage.token) {
    //   jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
    //       if (err) {
    //           this.props.logoutUser();
    //       }
    //   });
    // } else {
    //     return <Redirect to="/" />
    // }
    // let {users} = this.props;
    return (
      <header className="header">
        <nav className="navbar">

          {/* <div className="search-box">
            <button className="dismiss">
              <i className="icon-close"></i>
            </button>
            
            <form id="searchForm" action="#" role="search">
              <select
                name="user"
                className="form-control"
                required
                data-msg="Please select Leaner"
                onChange={(e: any) => this.handleItemsOnUser(e)}
              >
                <option value="">Select User</option>
                <option>Junaid Sheikh</option>

              </select>
              
             
            </form>
          </div> */}

          <div className="container-fluid">
            <div className="navbar-holder d-flex align-items-center justify-content-between">
              {/* <!-- Navbar Header--> */}
              <div className="navbar-header">

                {/* Sidebar Toggle Button */}
                <div className="pull-left">
                  {/* <!-- Navbar Brand --> */}
                  {roleId == 3 || roleId == 5 ?
                    <div className="brand-text d-none d-lg-inline-block">
                      <span>Simplex</span>
                      <strong className="ml-1">Services</strong>
                    </div> :
                    <Link
                      to="/dashboard"
                      className="navbar-brand d-none d-sm-inline-block lms-dashoard"
                    >
                      <div className="brand-text d-none d-lg-inline-block">
                        <span>Simplex</span>
                        <strong className="ml-1">Services</strong>
                      </div>
                    </Link>}

                  {/* <!-- Toggle Button--> */}
                  <Link id="toggle-btn" to="#" className="menu-btn active ml-2">
                    <span></span>
                    <span></span>
                    <span></span>
                  </Link>
                </div>

                <div className="clearfix"></div>
                <div className="clear"></div>
              </div>
              {/* Navigation Bar Buttons Right side */}
              <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">

                {roleId != 5 &&
                  <li className="nav-item">
                  <Link className="btn btn-primary mr-5" to="/requester"><i className="fa fa-plus"></i> &nbsp; Add Ticket</Link>
                </li>
                }  
                {/* <button onClick={this.azure} className="btn btn-warning mr-5">authAzure</button> */}
                {/* Search */}
                {/* <li className="nav-item d-flex align-items-center" title="Search">
                  <a className="acClass" id="search" href="#">
                    <i className="icon-search"></i>
                  </a>
                </li>
*/}
                <li className="nav-item" title="Profile">
                  <Link to={`/profile/${user}`} className="nav-link">
                    <i className="fa fa-user-circle"></i>
                  </Link>
                </li> 
                {/* <!-- Logout    --> */}
                <li onClick={() => this.props.logoutUser()} className="nav-item btn p-l-0" title="Logout">
                  <i className="fa fa-sign-out"></i>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    users: state.user.userList,
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    userList: function () {
      dispatch(userList())
    },
    authAzure: function () {
      dispatch(authAzure())
    },
    logoutUser: function () {
      dispatch(logoutUser());
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
