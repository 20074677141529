import React, { Component } from 'react';
import { EditStoreProps, EditStoreState } from '../../interfaces/store'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import config from '../config/setting'
import Sidebar from '../sidebar/sidebar'
import CheckChanges from '../confirmOnLeave';
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';
import { getStore, updateStore, companyList } from '../../redux/actions/store';
import { logoutUser } from '../../redux/actions/user';

class EditStore extends Component<EditStoreProps, EditStoreState> {
    id: any;
    constructor(props: any) {
        super(props);
        this.id = this.props.match.params.id;
        this.state = {
            name: "",
            brand: "",
            type: "",
            email: "",
            escalation: "",
            phone: "",
            website: "",
            address: "",
            opening: "",
            closing: "",
            ownerContact: "",
            ownerName: "",
            company: "",
            status: "",
            isValidEmail: true,
            isValidPhone: true,
            isValidOwnerContact: true,
            isValidEscalation: true
        };

        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.companyList();
        this.props.getStore(this.id);
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        console.log("nextProps", nextProps);
        this.setState({
            name: nextProps.store.name,
            brand: nextProps.store.brand,
            type: nextProps.store.type,
            email: nextProps.store.email,
            company: nextProps.store.company,
            escalation: nextProps.store.escalation,
            phone: nextProps.store.phone,
            website: nextProps.store.website,
            address: nextProps.store.address,
            opening: nextProps.store.openong,
            closing: nextProps.store.closing,
            ownerContact: nextProps.store.ownerContact,
            ownerName: nextProps.store.ownerName,
            status: nextProps.store.status,
        });
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }

    isStoreReady = () => {
        const { name, type, email, phone, ownerContact, ownerName, address, status, company } = this.state
        return (name !== "" && type !== "" && email !== "" && phone !== "" && ownerContact !== "" && ownerName !== "" && address !== "" && status !== "" && company !== "");
    }

    validateEmail = (event: { target: { name: any; value: any; }; }) => {
        var reg = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (reg.test(event.target.value) === false) {
            if(event.target.name == "email"){
                this.setState({ email: event.target.value, isValidEmail: false })
            }else{
                this.setState({ escalation: event.target.value, isValidEscalation: false })
            }      
        } else {
            if(event.target.name == "email"){
                this.setState({ email: event.target.value, isValidEmail: true });
            }else {
                this.setState({ escalation: event.target.value, isValidEscalation: true });
            }
          
        }
    };
    onlyAlphabets = (e:any) => {
        var regex =  /^[a-zA-Z ]*$/
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
     }
    validatePhone = (event: { target: { name: any; value: any; }; }) => {
        let maxNum = 11;
        if (event.target.value.length > maxNum) {
        event.target.value = event.target.value.slice(0, maxNum);
        }
        let reg = /[^0-9]/;
        if (reg.test(event.target.value) == false) {
            if(event.target.name == "phone"){
                this.setState({ phone: event.target.value, isValidPhone: true })
            }else {
                this.setState({ ownerContact: event.target.value, isValidOwnerContact: true })
            }
       
        } else {
            if(event.target.name == "phone"){
                this.setState({ phone: event.target.value.replace(''), isValidPhone: false });
            }else {
                this.setState({ ownerContact: event.target.value.replace(''), isValidOwnerContact: false });
            }
       
        }
    };

    handleSaveBtnClick = (event: any) => {
        let { name, brand, type, email, escalation, company, phone, website, address, opening, closing, ownerContact, ownerName, status } = this.state;
        let data = {
            name: name,
            brand: brand,
            type: type,
            email: email,
            company: company,
            escalation: escalation,
            phone: phone,
            website: website,
            address: address,
            opening: opening,
            closing: closing,
            ownerContact: ownerContact,
            ownerName: ownerName,
            status: status,
        }
        console.log(data)
        let id = this.props.match.params.id;
        this.props.updateStore(this.id, data);
    }
    render() {
        console.log(this.props.store)
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        // const { isInserted, message, brands, channels, types } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }
        let { store, companies } = this.props;
        let storeTypes = ['Free standing', 'Drive Thru', 'Food Court', 'Inline', 'Kiosk'];
        return (
            <div className="page">
                <CheckChanges path="/edit-store" /> 
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Stores Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/stores" className="text-primary">Stores</Link></li>
                                <li className="breadcrumb-item active">Edit Store</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="form-horizontal">

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="name" type="text" defaultValue={store.name} name="name" required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Brand<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="brand" type="text" name="brand" defaultValue={store.brand} required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Store Type<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="type" defaultValue={store.type} className="form-control text-capitalize mt-2" required data-msg="Please select Store Type" onChange={this.handleInputChange}>
                                                                {storeTypes &&
                                                                    storeTypes.map((channel, index) => (
                                                                        <option key={index} value={channel}>{channel}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Company<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="company" value={this.state.company} className="form-control text-capitalize mt-2" required data-msg="Please select Store Type" onChange={this.handleInputChange}>
                                                                <option value="">Select Company</option>
                                                                {companies &&
                                                                    companies.map((company: any, index: any) => (
                                                                        <option key={index} value={company.id} {...store.id === company.id && { selected: true }}>{company.company_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Address<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="address" type="text" defaultValue={store.address} name="address" required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    {/* Email Info */}
                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Email Contact<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="email" type="text" name="email" defaultValue={store.email} required data-msg="Please enter Email Contact" className="input-material" onChange={this.validateEmail} />
                                                            {(this.state.isValidEmail == false && this.state.email !== "") &&
                                                            <small className="form-text text-danger">Invalid Email</small>}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Email Escalation</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="escalation" type="text" name="escalation" defaultValue={store.escalation} required data-msg="Please enter Email Escalation" className="input-material" onChange={this.validateEmail} />
                                                            {(this.state.isValidEscalation == false && this.state.escalation !== "") &&
                                                            <small className="form-text text-danger">Invalid Email</small>}
                                                        </div>
                                                    </div>

                                                    {/* Website nd Phone Info */}
                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Website</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="website" type="text" name="website" defaultValue={store.website} required data-msg="Please enter Website" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Phone<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="phone" type="text" name="phone" defaultValue={store.phone} required data-msg="Please enter Phone" className="input-material" onChange={this.validatePhone} />
                                                            {(this.state.isValidPhone == false && this.state.phone !== "") &&
                                                            <small className="form-text text-danger">Invalid Phone Number</small>}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Opening Time</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="opening" type="time" name="opening" defaultValue={store.opening} required data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Closing Time</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="closing" type="time" name="closing" defaultValue={store.closing} required data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Owner Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="ownerName" type="text" name="ownerName" defaultValue={store.ownerName}  onKeyDown={this.onlyAlphabets} required data-msg="Please enter Owner Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Owner Contact<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="ownerContact" type="text" name="ownerContact" defaultValue={store.ownerContact} required data-msg="Please enter Owner Contact" className="input-material" onChange={this.validatePhone} />
                                                            {(this.state.isValidOwnerContact == false && this.state.ownerContact !== "") &&
                                                            <small className="form-text text-danger">Invalid Phone Number</small>}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="status" value={this.state.status} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option value={config.ACTIVE}>Active</option>
                                                                <option value={config.INACTIVE}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isStoreReady()} onClick={this.handleSaveBtnClick}>Update Store</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        store: state.store.storeGet,
        companies: state.store.companyList,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {

        getStore: function (id: any) {
            dispatch(getStore(id))
        },
        companyList: function () {
            dispatch(companyList());
        },
        updateStore: function (id: any, data: any) {
            dispatch(updateStore(id, data))
        },
        logoutUser: function () {
            dispatch(logoutUser())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditStore);