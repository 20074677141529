import axios from 'axios'
import { BASE_URL } from './config/setting'

export default axios.create({
    baseURL: BASE_URL
    // baseURL: 'https://eathos-services.simplexts.net:3000'
    // baseURL: 'https://services.simplexts.com.au:3000'
    //  baseURL: 'https://servicestg.simplexts.com.au:3000'
    // baseURL: 'http://192.168.18.139:4000'
    //baseURL: 'http://192.168.18.121:4000'
})