import React, { Component } from 'react';
import { AddUserProps, AddUserState } from '../../interfaces/user'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import CheckChanges from '../confirmOnLeave';
import { Link, Redirect } from 'react-router-dom';
import { addUser, courseGroupList, groupList, logoutUser, roleList } from '../../redux/actions/user';
import config from '../config/setting'
import Select from 'react-select';

class AddUser extends Component<AddUserProps, AddUserState> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            role: "",
            email: "",
            phone: "",
            username: "",
            address: "",
            group: "",
            password: "",
            status: config.ACTIVE,
            isValidEmail: true,
            isValidPhone: true,
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.roleList();
        this.props.groupList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }

    handleGroupsInputChange = (e: any) => {
        console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ group: e.value });
        } else {
            this.setState({ group: "" });
        }
    };

    isUserReady = () => {
        const { name, role, email, phone, group, address, password, status, username } = this.state
        return (name !== "" && role !== "" && email !== "" && group !== "" && phone !== "" && password !== "" && status !== "" && address !== "" && username!="");
    }

    validateEmail = (event: { target: { name: any; value: any; }; }) => {
        var reg = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (reg.test(event.target.value) === false) {
          this.setState({ email: event.target.value, isValidEmail: false })
        } else {
          this.setState({ email: event.target.value, isValidEmail: true });
        }
    };
    validatePhone = (event: { target: { name: any; value: any; }; }) => {
        let maxNum = 11;
        if (event.target.value.length > maxNum) {
        event.target.value = event.target.value.slice(0, maxNum);
        }
        let reg = /[^0-9]/;
        if (reg.test(event.target.value) == false) {
        this.setState({ phone: event.target.value, isValidPhone: true })
        } else {
        this.setState({ phone: event.target.value.replace(''), isValidPhone: false });
        }
    };

    handleSaveBtnClick = (event: any) => {
        let { name, role, email, phone, group, address, password, status, username } = this.state;
        let data = { name: name, group: group, role: role, email: email, phone: phone, address: address, password: password, status: status,username: username, isActive: 1 };

        this.props.addUser(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        // const { isInserted, message, brands, channels, types } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }

        let { roles, groups } = this.props;
        console.log("groups", groups);

        // Groups Data
        let groupData: any = [];
        groups.forEach((element: any) => {
            var group = { value: element.id, label: element.name };
            groupData.push(group);        
        });

        return (
            <div className="page">
                <CheckChanges path="/add-user" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Users Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/users" className="text-primary">Users</Link></li>
                                <li className="breadcrumb-item active">Add User</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body pt-xl-0">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="card-header card-header-info d-flex align-items-center">
                                                    <h5>Personal Information</h5>
                                                </div>

                                                <div className="form-horizontal p-xl-4">

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="name" type="text" name="name" required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Role<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="role" className="form-control text-capitalize mt-2" required data-msg="Please select role" onChange={this.handleInputChange}>
                                                                <option>Select Role</option>
                                                                {
                                                                    roles.map((role1: any) => (
                                                                        <option key={role1.id} value={role1.id}>{role1.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Group<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                        {groups.length > 0 &&
                                                                    <Select
                                                                        closeMenuOnSelect={true}
                                                                        name="group"
                                                                        //isMulti
                                                                        //isClearable
                                                                        options={groupData}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        onChange={(e: any) => this.handleGroupsInputChange(e)}
                                                                    />
                                                                }
                                                            {/* <select name="group" className="form-control text-capitalize mt-2" required data-msg="Please select role" onChange={this.handleInputChange}>
                                                                <option>Select Group</option>
                                                                {
                                                                    groups.map((group: any) => (
                                                                        <option key={group.id} value={group.id}>{group.name}</option>
                                                                    ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Address<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="address" type="text" name="address" required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    {/* Email Info */}
                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Email<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="email" type="email" name="email" required data-msg="Please enter Email" className="input-material" onChange={this.validateEmail} />
                                                            {(this.state.isValidEmail == false && this.state.email !== "") &&
                                                            <small className="form-text text-danger">Invalid Email</small>}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Phone<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="phone" type="text" name="phone" required data-msg="Please enter Phone" className="input-material" onChange={this.validatePhone} />
                                                            {(this.state.isValidPhone == false && this.state.phone !== "") &&
                                                            <small className="form-text text-danger">Invalid Phone Number</small>}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Username<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="username" type="text" name="username" required data-msg="Please enter Username" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Password<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="password" type="password" name="password" required data-msg="Please enter Password" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card-header card-header-info d-flex align-items-center">
                                                    <h5>Status Information</h5>
                                                </div>

                                                <div className="form-horizontal p-xl-4">
                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="status" className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option value={config.ACTIVE}>Active</option>
                                                                <option value={config.INACTIVE}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isUserReady()} onClick={this.handleSaveBtnClick}>Create User</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        groups: state.user.groups,
        roles: state.user.roleList,
        courseGroups: state.user.courseGroupList,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        groupList: function () {
            dispatch(groupList())
        },
        roleList: function () {
            dispatch(roleList())
        },
        addUser: function (data: any) {
            dispatch(addUser(data));
        },
        courseGroupList: function () {
            dispatch(courseGroupList())
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);