var ip = window.location.hostname;
// console.log("ip",ip);
var protocol = window.location.protocol == "https:" ? 'https' : 'http';
// // console.log("protocol",protocol);
var baseURL = window.location.origin;
export const BASE_URL = `${protocol}://${ip}:3000/`;
export const currency = 'Rs'
export default class AppSettings {

    public static secretKey = "JunaidSheikhSimplexTechnologySolutions";
    public static API_ENDPOINT = `${protocol}://${ip}:4000/api/`;
    public static API_SOCKET = `${protocol}://${ip}:4000/updates`;
    // public static IMAGES_URL = `https://services.simplexts.com.au:3005/`;
    public static IMAGES_URL = `https://eathos-services.simplexts.net:3000/`;
    // public static IMAGES_URL = `http://192.168.18.139:4000/`;
    public static BASE_URL = `${protocol}://${ip}:3000/`;
    // public static IMAGES_URL = `${protocol}://${ip}:3000/`;
    public static FORM_UPLOADS_URL = `${protocol}://${ip}:4000/formuploads/`;
    public static MAX_IMAGE_SIZE = 5 * 1024 * 1024;
    public static DEFAULT_PROFILE_PICTURE = '../../assets/img/avatar-2.jpg';
    public static SURVEY_URL = `${baseURL}/#/survey`;
    public static FORMAT = 'MMM dd, yyy HH:mm';
    public static DATE_FORMATE_SHORT = 'MMM dd, yyy';
    public static TIME_FORMAT_SHORT = 'HH:mm';
    public static FILE_LIMIT_WARNING = '5mb file is allowed.';

    public static GoogleMapCountries = ["AE", "PK"];
    public static ADHOC = 'Adhoc';
    public static DAILY = 'Daily';
    public static WEEKLY = 'Weekly';
    public static MONTHLY = 'Monthly';

    public static ACTIVE = '1';
    public static INACTIVE = '0';



}
