import React, { Component } from 'react';
import { RequesterProps, RequesterState } from '../../interfaces/requester'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Select from 'react-select'
import config from '../config/setting'
import Loader from "react-loader-spinner";
import Footer from '../footer/footer'
import CheckChanges from '../confirmOnLeave';
import moment from 'moment'
import { Link, Redirect } from 'react-router-dom';
import { addTicket, statusList, priorityList, groupList } from '../../redux/actions/ticket';
import { logoutUser } from './../../redux/actions/user';
import { storesList } from './../../redux/actions/store';
import { toast } from 'react-toastify';

class Requester extends Component<RequesterProps, RequesterState> {
    constructor(props: any) {
        super(props);
        this.state = {
            subject: "",
            requester: "",
            description: "",
            requester_file: null,
            stores: "",
            priority:"1",
            netWork: false,
        };
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.storesList();
        this.props.priorityList();
        document.title = "EathosServices | Add Ticket"
    }

    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }
    handleItemsInputChange = (e: any) => {
        console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ stores: e.value });
        } else {
            this.setState({ stores: "" });
        }
    };
    handleSubjectsInputChange = (e: any) => {
        console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ subject: e.value });
        } else {
            this.setState({ subject: "" });
        }
    };
    maxSelectFile=(event:any)=>{
        let files = event.target.files // create file object
            if (files.length > 5) { 
               const msg = 'Only 5 files can be uploaded at a time'
               event.target.value = null // discard selected file
               toast.error(msg, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
               console.log(msg)
              return false;
          }
        return true;
    }

    checkFileSize=(event: any)=>{
        let files = event.target.files
        let size = 5242880 
        let err = ""; 
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
                err += 'file too large, please pick a smaller file upto 5mb\n';
            }
        };
        if (err !== '') {
            event.target.value = null
            toast.error(err, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
            console.log(err)
            return false
        }
        return true;
    }
    fileSelectedHandler = (e: any) => {
        let files = e.target.files;
        if (this.maxSelectFile(e) && this.checkFileSize(e)) {
            this.setState({ requester_file: files })
        }  
    }
    isTicketReady = () => {
        const { subject, priority, description } = this.state
        return (priority !== "" && subject !== "" && description!== "");
    }

    handleSaveBtnClick = (event: any) => {
        const { subject, description, stores, requester_file, priority } = this.state

        // let ticketDate = moment().utc(false).format('YYYY-MM-DD HH:mm:ss')
        this.setState({netWork: true})
        const data: any = new FormData()
        // if (requester_file) {
        //     data.append('file', requester_file[0]);
        // }
        if (requester_file) {
            for (var x = 0; x < requester_file.length; x++) {
                data.append('files', requester_file[x])
            }
        }
        data.append('requester_id', sessionStorage.userId);
        data.append('requester_email', sessionStorage.email);
        data.append('subject', subject)
        data.append('ticket_priority_code', priority)
        data.append('description', description)
        if(stores!=""){data.append('store', stores)}
        data.append('ticket_status_code', 1)
        // data.append('recieved_time', ticketDate)
        data.append('isActive', 1)

        console.log(data)
        setTimeout(() => {
            this.setState({netWork: false})
        }, 3000);
        this.props.addTicket(data);
    }

    render() {
        if (sessionStorage.token) {
            console.log("Token", sessionStorage.userId);
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, priority, isLoad } = this.props;
        if (isInserted) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        const { stores } = this.props;
        let subjects:any = [
            {
                id: 1,
                subject: "test now"
            },
            {
                id: 2,
                subject: "second subject"
            },
            {
                id: 3,
                subject: "third subject"
            },
            {
                id: 4,
                subject: "test subject"
            },
            {
                id: 5,
                subject: "whatever"
            }
        ]
        let subjectData: any = [];
        subjects.forEach((element: any) => {
                var subject = { value: element.id, label: element.subject };
                subjectData.push(subject);         
        });
        // stores
        let storeData: any = [];
        stores.forEach((element: any) => {
                var store = { value: element.id, label: element.name };
                storeData.push(store);         
        });
        console.log("selectedOption", storeData);

        return (
            <div className="page">
                <CheckChanges path="/requester" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Ticket Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/tickets" className="text-primary">Tickets</Link></li>
                                <li className="breadcrumb-item active">Add Ticket</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="form-horizontal">

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Subject<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            {/* <Select
                                                                //value={storeData}
                                                                closeMenuOnSelect={true}
                                                                name="subject"
                                                                //isMulti
                                                                isClearable
                                                                options={subjectData}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={(e: any) => this.handleSubjectsInputChange(e)}
                                                            /> */}
                                                            <input id="subject" type="text" name="subject" required data-msg="Please enter Ticket Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                            <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Ticket Priority<span className="text-danger">*</span></label>
                                                            <div className="col-md-9 col-sm-9">
                                                                <select name="priority" value={this.state.priority} className="form-control text-capitalize mt-2" required data-msg="Please select priority" onChange={this.handleInputChange}>
                                                                    {priority &&
                                                                        priority.map((p, index) => (
                                                                            <option key={index} value={p.ticket_priority_code}>{p.ticket_priority_description}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                    </div>

                                                    <div className="row form-group">
                                                            <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Stores</label>
                                                            <div className="col-md-9 col-sm-9">
                                                                {stores.length > 0 &&
                                                                    <Select
                                                                        //value={storeData}
                                                                        closeMenuOnSelect={true}
                                                                        name="stores"
                                                                        //isMulti
                                                                        isClearable
                                                                        options={storeData}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        onChange={(e: any) => this.handleItemsInputChange(e)}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Description<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <textarea name="description" required data-msg="Please enter Issue Description" className="form-control rounded-0" onChange={this.handleInputChange} id="exampleFormControlTextarea1" rows={6}></textarea>
                                                        </div>
                                                    </div>

                                                    

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Select Attachment</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="files" type="file" name="files" className="btn btn-primary form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group float-right mt-3">
                                                    {isLoad ? <Loader style={{ display: "block" }} type="ThreeDots" color="#00BFFF" height={50} width={50} /> :
                                                    (this.state.netWork == false ? 
                                                    <button className='btn btn-primary' disabled={!this.isTicketReady()} onClick={this.handleSaveBtnClick}>Create Ticket</button>                                                    
                                                    :
                                                    <button className='btn btn-primary' disabled={true} onClick={this.handleSaveBtnClick}>Create Ticket</button>)
                                                    }
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        priority: state.ticket.priority,
        status: state.ticket.status,
        stores: state.store.storeList,
        groups: state.ticket.groups,
        message: state.ticket.message,
        isInserted: state.ticket.isInserted,
        isLoad: state.ticket.isLoad
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        storesList: function () {
            dispatch(storesList())
        },
        priorityList: function () {
            dispatch(priorityList());
        },
        statusList: function () {
            dispatch(statusList());
        },
        groupList: function () {
            dispatch(groupList());
        },
        addTicket: function (data: any) {
            dispatch(addTicket(data));
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Requester);