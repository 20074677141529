import {
  GROUP_LIST, GROUP_GET, USR_LIST, GROUP_BY_STORES_GET
} from '../actions/types';
const initialState: any = {
  groupList: [] || '',
  groupGet: {},
  roles: [] || "",
  storesByGroupGet: [] || ''

};
const GroupReducer = (state = initialState, action: any) => {
  // console.log("action.type",action.y)
  switch (action.type) {
    case GROUP_LIST:
      return {
        ...state,
        groupList: action.payload
      };

    case USR_LIST:
      return {
        ...state,
        roles: action.payload
      };

    case GROUP_GET:
      return {
        ...state,
        groupGet: action.payload
      };

    case GROUP_BY_STORES_GET:
      return {
        ...state,
        storesByGroupGet: action.payload
      };

    default:
      return state;
  }
};
export default GroupReducer;