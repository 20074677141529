import {
    COMPANY_LIST, GET_COMPANY
} from '../actions/types';
const initialState: any = {
    companies: [] || '',
    company: {}

};
const CompanyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case COMPANY_LIST:
            return {
                ...state,
                companies: action.payload
            };

        case GET_COMPANY:
            return {
                ...state,
                company: action.payload
            };
        default:
            return state;
    }
};
export default CompanyReducer;