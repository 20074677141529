import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Chart from 'chart.js'
import config from '../config/setting'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { categoryList, delCategory, getSubCategoryByCatID } from './../../redux/actions/category';
import { logoutUser } from './../../redux/actions/user';
import { subCategoryList } from './../../redux/actions/sub-category';

function viewFormatter(cell: any, row: any) {
    return (
        <div>
            <a href="#" title="View Learner" className="btn btn-outline-primary"><i className="fa fa-eye"></i></a>
        </div>
    );
}

class ActionFormatter extends Component<{ row: any, data: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        console.log("id", this.props);

        this.props.data.delCategory(id);
    };
    render() {
        const { row } = this.props;
        return (
            <div>
                <a href="#" title="Delete Category" data-toggle="modal" data-target={`#deleteCat${row.id}`} className="btn btn-outline-danger mx-2"><i className="fa fa-trash"></i></a>
                <Link to={`/edit-category/${row.id}`} title="Edit Category" className="btn btn-outline-primary"><i className="fa fa-edit"></i></Link>
                {/* <Link title="Edit Store" className="btn btn-outline-primary" to={`/edit-store/${row.store_id}`}><i className="fa fa-edit"></i></Link> */}
                {/* <!-- B/U Modal--> */}
                <div id={`deleteCat${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Category</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to Delete this Category?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button type="button" data-dismiss="modal" className="btn btn-primary" onClick={() => { this.handleDelete(row.id) }}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function actionFormatter(cell: any, row: any, props: any) {
    return (
        // <ActionFormatter row={row} blockunblock={props.blockunblockstore} />
        <ActionFormatter row={row} data={props} />
    );
}

class SubCategories extends Component<{ row: any, data: any }, {[x:number]: any}> {

    constructor(readonly props: any) {
        super(props);
        // this.state = {
        // }
    }

    getSubCategories = (id: any) => {
        this.props.data.getSubCategoryByCatID(id);
    }

    render() {
        const { row, data } = this.props;
        console.log("row id", row.id);
        
            // console.log("Row", data.sub_category);
            return (
            <div>
                <div>
                        <button data-toggle="modal" data-target={`#subCategories${row.id}`} onClick={()=>{this.getSubCategories(row.id)}} className="btn btn-outline-primary btn-sm" title="Sub Categories">Sub Categories</button>
                        <div title="Sub Categories" id={`subCategories${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                            <div role="document" className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 id="exampleModalLabel" className="modal-title">Sub Categories</h4>
                                        <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                    </div>
                                    <div className="modal-body">
                                        {/* <h5>Re-Assign Reasons</h5> */}
                                        { data.sub_category.map((element:any,index:any) => {
                                            return <p className="text-dark" style={{ overflow: "hidden" }}>{element.name} <hr /></p>    
                                        })}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
function subCategories(cell: any, row: any, props: any) {
    return (
        <SubCategories row={row} data={props} />
    );
}

class Category extends Component<{ categoryList: () => {}, getSubCategoryByCatID: (id:any) => {}, delCategory: () => {}, sub_category: any[] }, { showDiv: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = { showDiv: '' }
    }
    componentDidMount() {
        this.props.categoryList();
        // this.props.subCategoryList();
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }

        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-category" /> },
            noDataText: 'Category Not Found'
        };

        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Category Management</h4>
                                </div>
                            </div>
                        </header>

                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-12">
                                        <div className="card">
                                            <div className="card-body text-capitalize">
                                                <BootstrapTable version='4' data={this.props.catList} search={true} pagination={this.props.catList.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='subCategories.csv' hover>
                                                    <TableHeaderColumn dataField='id' csvHeader='Sr. #' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='sub_cat_name' formatExtraData={this.props} dataFormat={subCategories} csvHeader='subCategories' width='100' columnTitle>Sub Categories</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='cat_name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='description' csvHeader='Description' width='100' columnTitle>Description</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='status' csvHeader='Status' width='100' formatExtraData={this.props} dataFormat={statusFormatter} columnTitle>Status</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='action' width='170' formatExtraData={this.props} dataFormat={actionFormatter} export={false}>Actions</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
};

function statusFormatter(cell: any) {
    // console.log("cell",cell);
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 1 ? "Active" : "Inactive"}</span>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    console.log("state", state);
    return {
        catList: state.category.catList,
        sub_category: state.category.sub_category 
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        categoryList: function () {
            dispatch(categoryList())
        },
        subCategoryList: function () {
            dispatch(subCategoryList())
        },
        getSubCategoryByCatID: function (id: number) {
            dispatch(getSubCategoryByCatID(id))
        },
        delCategory: function (id: any) {
            dispatch(delCategory(id))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Category);