import { GROUP_LIST, USR_LIST, GROUP_GET, STORE_LIST, GROUP_BY_STORES_GET } from './types'
import Api from '../../components/Api'
import { Redirect } from 'react-router-dom';
import React from 'react';


export const storesList = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/group/stores_by_company/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("data", response.data.successResponse)
                    dispatch({
                        type: STORE_LIST,
                        payload: response.data.successResponse
                    })

                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const rolesList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/group/getRoles',
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                console.log("response storesList", response);
                dispatch({
                    type: USR_LIST,
                    payload: response.data.data
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const groupList = () => {
    return function (dispatch: any) {
        // console.log("JAWAD SHAH");

        let token: any = sessionStorage.getItem('token');
        Api.get('/group/getAllGroups',
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                // console.log("response",response);
                dispatch({
                    type: GROUP_LIST,
                    payload: response.data.data
                })

            }).catch(err => {
                console.log("err", err);
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getStoreByGroup = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        // console.log("id",id);
        Api.get(`/group/getGroupStores/${id}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                //   console.log("response group",response)
                dispatch({
                    type: GROUP_BY_STORES_GET,
                    payload: response.data.data
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const addGroupStore = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        console.log("data Actions", data);
        Api.post('/group/add', data,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                console.log("response", response);
                window.location.href = "/groups";

            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_DUP_ENTRY") {
                            error = "Group Name Already Exist"
                            alert(error);
                            //toast.error(error);
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getGroup = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/group/get/${id}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                dispatch(storesList(response.data.data.company.id))
                dispatch({
                    type: GROUP_GET,
                    payload: response.data.data
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateGroup = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/group/update/${id}`, data,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                console.log("response", response);
                window.location.href = "/stores";

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deleteGroup = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/group/delete/${id}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                console.log("response", response);
                window.location.href = "/groups";
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}