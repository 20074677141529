import React, { Component } from 'react';
import { EditProfileProps, EditProfileState } from '../../interfaces/user'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { red } from '@material-ui/core/colors';
import { getUser, getUserProfile, logoutUser, updatePassword, updateProfile } from '../../redux/actions/user';
import config from '../config/setting'

class Profile extends Component<EditProfileProps, EditProfileState> {
  id:any;  
  constructor(readonly props: any) {
        super(props);
        this.id = this.props.match.params.id; 
        this.state = {
          name: "",
          email: "",
          phone: "",
          address: "",
          designation: "",
          profileImage: "",
          changePassMsg:"",
          newPass:"",
          confirmPass:"",
          isValidPhone: true
      }
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
      this.props.getUserProfile(this.id);
      document.title = "Simplex Services | Profile"
  }
  componentWillReceiveProps(nextProps: any) {
      this.setState({
        name: nextProps.user.name,
        email: nextProps.user.email,
        phone: nextProps.user.phone,
        address: nextProps.user.address,
        designation: nextProps.user.designation,
        profileImage: nextProps.user.profileImage,
        });
  }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
    console.log("event",event);
    if(event.target.value[0] == " "){
        event.target.value = event.target.value.replace(/\s/g, "");
    }else{
      this.setState({
          [event.target.name]: event.target.value,
      });
    }
    }
  handleSubmit = (event: any) => {
      let { name, email, phone, address, designation, profileImage } = this.state;

      const data = new FormData();
      data.append('file', profileImage[0]);
      data.append('name', name);
      sessionStorage.setItem('name', name);
      data.append('designation', designation);
      data.append('email', email);
      data.append('phone', phone);
      data.append('address', address);
      
      this.props.updateProfile(this.id,data);
  }
  FileSelectedHandler = (e: any) => {
    this.setState({ profileImage: e.target.files })
}
  isFormValid = () => {
      const { name, email, phone,designation, address } = this.state
      return (name!== "" && email!== "" && phone!== "" && designation!== "" && address!== "");
  }
  validatePhone = (event: { target: { name: any; value: any; }; }) => {
    let maxNum = 11;
    if (event.target.value.length > maxNum) {
    event.target.value = event.target.value.slice(0, maxNum);
    }
    let reg = /[^0-9]/;
    if (reg.test(event.target.value) == false) {
    this.setState({ phone: event.target.value, isValidPhone: true })
    } else {
    this.setState({ phone: event.target.value.replace(''), isValidPhone: false });
    }
    };
  isPassFormValid = () => {
      const { newPass, confirmPass } = this.state
      return (newPass!== "" && confirmPass!== "");
  }
  changedPassword = (event: any) => {
      const { newPass, confirmPass } = this.state
      if (newPass !== confirmPass) {
          this.setState({ changePassMsg: "Passwords do not match" })
          event.preventDefault()
      } else {
        let data = {
          password: newPass
      }
          this.props.changePassword(this.id, data);
      }
  }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message } = this.props;
        let {user} = this.props;
        console.log("user",user);
        
        const { changePassMsg } = this.state
        if (isInserted) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
          <div className="page">
              <Topbar />
              <div className="page-content d-flex align-items-stretch">
                  <Sidebar />
                  <div className="content-inner">
                      {/* <!-- Page Header--> */}
                      <header className="page-header py-0">
                          <div className="container-fluid">
                              <div className="d-flex justify-content-between py-3">
                                  <h4 className="mt-2">Profile Management</h4>
                              </div>
                          </div>
                      </header>
                      <section className="forms">
                          <div className="container-fluid">
                              <div className="row">
                                  <div className="col-lg-7">
                                      <div className="card">
                                          <div className="card-header d-flex align-items-center">
                                              <h3 className="h4">Personal Information</h3>
                                          </div>
                                          <div className="card-body">
                                              {true &&
                                                  <strong><p className="text-center font-weight-light mb-4" style={{ color: 'green' }}>
                                                      {message}
                                                  </p></strong>
                                              }
                                              < div>

                                              <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Profile Picture</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="file" type="file" name="file" className="form-control-file" onChange={this.FileSelectedHandler} />
                                                            {user.profileImage && <img className="img-visa-passport" src={config.IMAGES_URL + user.profileImage} alt="Profile Image ..."/>}
                                                        </div>
                                              </div>


                                                  <div className="row">
                                                      <div className="col">
                                                          <div className="form-group">
                                                              <label className="form-control-label">Name</label>
                                                              {user &&
                                                                <input type="text" name="name" defaultValue={user.name} required data-msg="Please enter Name" className="input-material" onChange={this.handleInputChange} />
                                                              }
                                                          </div>
                                                      </div>
                                                     
                                                  </div>
                                                  <div className="row">
                                                  {/* <div className="col">
                                                          <div className="form-group">
                                                              <label className="form-control-label">Email</label>
                                                              {user &&
                                                                <input type="text" name="email" defaultValue={user.email} required data-msg="Please enter Email" className="input-material" onChange={this.handleInputChange} />
                                                              }
                                                          </div>
                                                      </div> */}
                                                  </div>
                                                  <div className="row">
                                                  <div className="col">
                                                          <div className="form-group">
                                                              <label className="form-control-label">Phone</label>
                                                              {user &&
                                                                <input type="phone" name="phone" defaultValue={user.phone} required data-msg="Please enter Phone" className="input-material" onChange={this.validatePhone} />
                                                              }
                                                              {(this.state.isValidPhone == false && this.state.phone !== "") &&
                                                            <small className="form-text text-danger">Invalid Phone Number</small>}
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="row">
                                                      
                                                      <div className="col">
                                                          <div className="form-group">
                                                              <label className="form-control-label">Designation</label>
                                                              {user &&
                                                                <input type="text" name="designation" defaultValue={user.designation} required data-msg="Please enter Designation" className="input-material" onChange={this.handleInputChange} />
                                                              }
                                                          </div>
                                                      </div>
                                                  </div>
                                                 
                                                  <div className="row">
                                                      <div className="col">
                                                          <div className="form-group">
                                                              <label className="form-control-label">Address</label>
                                                              {user &&
                                                                <input type="text" name="address" defaultValue={user.address} required data-msg="Please enter Address" className="input-material" onChange={this.handleInputChange} />
                                                              }
                                                          </div>
                                                      </div>
                                                  </div>
                                                  
                                                  
                                                  <div className="form-group float-right">
                                                      <button onClick={this.handleSubmit} disabled={!this.isFormValid()} className="btn btn-primary">Update Profile</button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-5">
                                      <div className="card">
                                          <div className="card-header d-flex align-items-center">
                                              <h3 className="h4">Update Password</h3>
                                          </div>
                                          <div className="card-body">
                                              {changePassMsg!== "" && <strong><p className="text-center font-weight-light mb-4" style={{ color: 'red' }}>
                                                  {changePassMsg}
                                              </p></strong>}
                                              <div>
                                                  <div className="row">
                                                      <div className="col">
                                                          <div className="form-group">
                                                              <label className="form-control-label">New Password</label>
                                                              <input type="password" name="newPass" required data-msg="Please enter new password" className="input-material" onChange={this.handleInputChange} />
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="row">
                                                      <div className="col">
                                                          <div className="form-group">
                                                              <label className="form-control-label">Confirm Password</label>
                                                              <input type="password" name="confirmPass" required data-msg="Please enter confirm password" className="input-material" onChange={this.handleInputChange} />
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="form-group float-right">
                                                      <button onClick={this.changedPassword} disabled={!this.isPassFormValid()} className="btn btn-primary">Update Password</button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>
                      <Footer />
                  </div>

              </div>
          </div >
      );
    }
}

const mapStateToProps = (state: any) => {
    return {
      user: state.user.userGet,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
      getUserProfile: function (id:any) {
        dispatch(getUserProfile(id));
        },
      updateProfile: function (id:any, data: any) {
        dispatch(updateProfile(id, data));
      },
      changePassword: function (id:any, data: any) {
        dispatch(updatePassword(id, data));
      },
      logoutUser: function () {
        dispatch(logoutUser());
      },
    
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);