import {
    SUB_CAT_LIST, GET_SUB_CAT, CATE_LIST
} from '../actions/types';
const initialState: any = {
    subCatList: [] || '',
    subCategory: {},
    catList: [] || ""

};
const SubCategoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SUB_CAT_LIST:
            return {
                ...state,
                subCatList: action.payload
            };
        case CATE_LIST:
            return {
                ...state,
                catList: action.payload
            };

        case GET_SUB_CAT:
            return {
                ...state,
                subCategory: action.payload
            };
        default:
            return state;
    }
};
export default SubCategoryReducer;