import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
// import jwt from 'jsonwebtoken'
// import Chart from 'chart.js'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import jwt from 'jsonwebtoken'
import config from '../config/setting'
// import { connect } from 'react-redux'
// import { Line } from '@reactchartjs/react-chart.js'
// import { Bar  } from '@reactchartjs/react-chart.js'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { deleteStore, storesList, companyList, getStorsByCompany } from '../../redux/actions/store';
import { logoutUser } from '../../redux/actions/user';


function viewFormatter(cell: any, row: any) {
    return (
        <div>
            <a href="#" title="View Learner" className="btn btn-outline-primary"><i className="fa fa-eye"></i></a>
        </div>
    );
}

class ActionFormatter extends Component<{ row: any, data: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.data.deleteStore(id);
    };
    render() {
        const { row } = this.props;
        console.log("row", row)
        return (
            <div>
                <a href="#" title="Delete Store" data-toggle="modal" data-target={`#deleteStore${row.id}`} className="btn btn-outline-danger mx-2"><i className="fa fa-trash"></i></a>
                <Link to={`/edit-store/${row.id}`} title="Edit Store" className="btn btn-outline-primary"><i className="fa fa-edit"></i></Link>
                {/* <Link title="Edit Store" className="btn btn-outline-primary" to={`/edit-store/${row.store_id}`}><i className="fa fa-edit"></i></Link> */}
                {/* <!-- B/U Modal--> */}
                <div id={`deleteStore${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Store</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to Delete this store?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button type="button" data-dismiss="modal" className="btn btn-primary" onClick={() => { this.handleDelete(row.id) }}>Confirm</button>
                                {/* <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function actionFormatter(cell: any, row: any, props: any) {
    return (
        // <ActionFormatter row={row} blockunblock={props.blockunblockstore} />
        <ActionFormatter row={row} data={props} />
    );
}

function statusFormatter(cell: any) {
    // console.log("cell",cell);
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 1 ? "Active" : "Inactive"}</span>
        </div>
    )
}


class Store extends Component<{ stores: any[], store_count: number, companies: any[], logoutUser: () => {}, companyList: () => {}, getStorsByCompany: (id: any) => {}, deleteStore: () => {} }, { showDiv: any, company: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = { showDiv: '', company: "" }
    }
    componentDidMount() {
        this.props.storesList();
        this.props.companyList()
    }

    handleStoresInputChange = (e: any) => {
        console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ company: e.value });
        } else {
            this.setState({ company: "" });
        }
        if (e && e.value > 0) {
            this.props.getStorsByCompany(e.value);
        }

    };
    render() {
        console.log(this.props.stores);
        let roleId: any = ""

        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-store" /> },
            noDataText: 'Store Not Found'
        };

        const { companies, stores, store_count, storesByCompany } = this.props
        console.log("company", companies, storesByCompany);

        // let companyData: any = [];
        // companies.forEach((element: any) => {
        //     var company = { value: element.id, label: `${element.company_name} (${element.store.length} stores)` };
        //     // var company = { value: element.id, label: element.company_name };
        //     companyData.push(company);
        // });

        // console.log("companyData: ", companyData);

        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Stores Management</h4>
                                </div>
                            </div>
                        </header>

                        {/* Counters Section */}
                        {/* <section className="dashboard-counts py-2">
                            <div className="container-fluid">
                                <div className="float-right col-md-6">
                                    <Select
                                        name="company"
                                        closeMenuOnSelect={false}
                                        isClearable
                                        options={companyData}
                                        className="text-capitalize select mt-2"
                                        classNamePrefix="select"
                                        onChange={(e) => this.handleStoresInputChange(e)}
                                    />
                                </div>
                            </div>
                        </section> */}
                        {/* End Counters Section */}


                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-12">
                                        <div className="card">
                                            <div className="card-body text-capitalize">
                                                <BootstrapTable version='4' data={storesByCompany.length > 0 ? storesByCompany : stores} search={true} pagination={this.props.stores.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='stores.csv' hover>
                                                    <TableHeaderColumn dataField='id' csvHeader='Sr. #' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='brand' csvHeader='Email' width='100' columnTitle>Brand</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='company_name' csvHeader='Company' width='100' columnTitle>Company</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='type' csvHeader='Phone' width='100' columnTitle>Type</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='address' csvHeader='Address' width='100' columnTitle>Address</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='opening' csvHeader='Phone' width='100' columnTitle>Opening</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='closing' csvHeader='Address' width='100' columnTitle>Closing</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='status' csvHeader='Status' formatExtraData={this.props} dataFormat={statusFormatter} width='100' columnTitle>Status</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='action' width='120' formatExtraData={this.props} dataFormat={actionFormatter} export={false}>Actions</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state: any) => {
    return {
        stores: state.store.storeList,
        companies: state.store.companyList,
        storesByCompany: state.store.storesByCompany,
        store_count: state.store.store_count
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {

        storesList: function () {
            dispatch(storesList())
        },
        getStorsByCompany: function (id: any) {
            dispatch(getStorsByCompany(id))
        },
        companyList: function () {
            dispatch(companyList());
        },
        deleteStore: function (id: any) {
            dispatch(deleteStore(id))
        },
        logoutUser: function () {
            dispatch(logoutUser())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Store);