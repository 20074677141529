import React, { Component } from 'react';
import { EditGroupProps, EditGroupState } from '../../interfaces/group'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';
import CheckChanges from '../confirmOnLeave';
import Select from 'react-select'
import config from '../config/setting';
import { storesList, getGroup, updateGroup, getStoreByGroup } from '../../redux/actions/groupStore';
import { companyList } from '../../redux/actions/company';
import ActionThumbsUpDown from 'material-ui/svg-icons/action/thumbs-up-down';
import { logoutUser } from '../../redux/actions/user';

class EditGroup extends Component<EditGroupProps, EditGroupState> {
    id: any;
    constructor(props: any) {
        super(props);
        this.id = this.props.match.params.id;
        this.state = {
            name: "",
            description: "",
            store: [],
            territary: '0',
            status: '1',
            user: "",
            company: ""
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.getGroup(this.id);
        this.props.getStoreByGroup(this.id);

        //this.props.storesList(comp);
        // this.props.brandsList();
        // this.props.channelsList();
        // this.props.storeTypeList();
        // this.props.areasList();
        // this.props.countryList();
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        console.log("nextProps", nextProps.group);
        console.log("groupStores!!!!!", nextProps.groupStores);

        var storeArr = [];
        for (let i = 0; i < nextProps.groupStores.length; i++) {
            const element = nextProps.groupStores[i];
            storeArr.push(element.id);
        }

        console.log("storeArr: ", storeArr);

        this.setState({
            name: nextProps.group.name,
            description: nextProps.group.description,
            store: storeArr,
            territary: nextProps.group.territary,
            status: nextProps.group.status,
            company: nextProps.group.company && nextProps.group.company.id
        });

    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }

    handleItemsInputChange = (e: any) => {
        // console.log("e", e);
        // let array: any = [];
        // e &&
        //     e.map((obj: any) => {
        //         array.push(obj.value)
        //     })
        // console.log("array", array);
        if (e && e.length > 0) {
            this.setState({ store: e })
        } else {
            this.setState({ store: [] })
        }
    };

    handleCompanyInputChange = (e: any) => {
        console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ company: e.value });
        } else {
            this.setState({ company: "" });
        }

        this.props.storesList(e.value);
    };

    isGroupReady = () => {
        // console.log("this.state",this.state);
        const { name, store, territary, company, status, description } = this.state
        return (name != "" && store.length > 0 && territary !== "" && status !== "" && company !== "" && description !== "");
    }

    handleSaveBtnClick = (event: any) => {
        let { name, description, store, territary, status } = this.state;

        let data = {
            name: name,
            description: description,
            store: store,
            territary: territary,
            status: status,
        }
        console.log("data!!!@", data);

        this.props.updateGroup(this.id, data);
    }
    render() {
        // console.log("this.props.group",this.props)
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { group, companies } = this.props;
        let { stores } = this.props;
        let { groupStores } = this.props;
        console.log("group", group.default);

        let companyData: any = [];
        companies.forEach((element: any) => {
            var company = { 'label': element.company_name, value: element.id };
            companyData.push(company);
        });

        let storeData: any = [];
        stores.forEach((element: { name: any; id: any; }) => {
            var store = { value: element.id, label: element.name };
            storeData.push(store);
        });

        console.log("company id", group.company && group.company.id)

        let selectedCompany: any = [];
        if (group.company != undefined) {
            let companyS = { 'label': group.company.company_name, value: group.company.id }
            selectedCompany.push(companyS);
        }

        let selectedStores: any = [];
        if (groupStores != undefined) {
            for (let i = 0; i < groupStores.length; i++) {
                const element = groupStores[i].store;
                var storeS = { value: element.id, label: element.name };
                selectedStores.push(storeS);
            }
        }
        
        // groupStores.forEach((element: { name: any; id: any; }) => {
        //     var storeS = {'label': element.name, value:element.id};
        //     selectedStores.push(storeS);
        // });
        // console.log("selectedStores",selectedStores);

        let msg;
        let messcolor;
        const { message } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }
        return (
            <div className="page">
                <CheckChanges path="/edit-group" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Groups Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/groups" className="text-primary">Groups</Link></li>
                                <li className="breadcrumb-item active">Edit Group</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="form-horizontal">

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="name" type="text" name="name" defaultValue={group.name} required data-msg="Please enter Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>


                                                    {/* <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Company<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            {selectedCompany.length > 0 && <Select
                                                                defaultValue={selectedCompany}
                                                                //isMulti
                                                                closeMenuOnSelect={false}
                                                                name="company"
                                                                //isClearable
                                                                options={companies.length > 0 && companyData}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={e => this.handleCompanyInputChange(e)}
                                                            />}
                                                        </div>
                                                    </div> */}

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Stores<span className="text-danger">*</span></label>
                                                        {/* <MultipleSelect name="store" setStore={this.setStore(id)} optionsMultiSelect={storeData}/> */}
                                                        <div className="col-md-9 col-sm-9">
                                                            {selectedStores.length > 0 && <Select
                                                                defaultValue={selectedStores}
                                                                isMulti
                                                                //isClearable
                                                                closeMenuOnSelect={false}
                                                                name="store"
                                                                options={stores.length > 0 && storeData}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                //onClick={this.getStores}
                                                                onChange={e => this.handleItemsInputChange(e)}
                                                            />}
                                                        </div>

                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Description<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <textarea id="description" name="description" defaultValue={group.description} required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Territary<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="territary" defaultValue={group.territary} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option {...group.territary === config.ACTIVE && { selected: true }} value={config.ACTIVE}>Enabled</option>
                                                                <option {...group.territary === config.INACTIVE && { selected: true }} value={config.INACTIVE}>Disabled</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="status" defaultValue={group.status} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option {...group.status === config.ACTIVE && { selected: true }} value={config.ACTIVE}>Active</option>
                                                                <option {...group.status === config.INACTIVE && { selected: true }} value={config.INACTIVE}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group float-right mt-3">
                                                    {group.default == 0 && <button className='btn btn-primary' disabled={!this.isGroupReady()} onClick={this.handleSaveBtnClick}>Update Group</button>}
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    // console.log("state",state);
    return {
        group: state.group.groupGet,
        groupStores: state.group.storesByGroupGet,
        stores: state.store.storeList,
        companies: state.company.companies
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getStoreByGroup: function (id: any) {
            dispatch(getStoreByGroup(id))
        },
        getGroup: function (id: any) {
            dispatch(getGroup(id))
        },
        updateGroup: function (id: any, data: any) {
            dispatch(updateGroup(id, data))
        },
        companyList: function () {
            dispatch(companyList())
        },
        storesList: function (id: any) {
            dispatch(storesList(id))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);