import React, { Component } from 'react';
import { AddGroupProps, AddGroupState } from '../../interfaces/group'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';
import MultipleSelect from '../React-Elements/MultiSelect/MultiSelect';
import { storesList, addGroupStore } from '../../redux/actions/groupStore';
import Select from 'react-select'
import CheckChanges from '../confirmOnLeave';
import config from '../config/setting';
import company from '../company/company';
import { companyList } from '../../redux/actions/company';
import { logoutUser } from '../../redux/actions/user';


class AddGroup extends Component<AddGroupProps, AddGroupState> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            description: "",
            store: [],
            company: [],
            territary: '0',
            user: "",
            status: '1',
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        // this.props.brandsList();
        // this.props.channelsList();
        // this.props.storeTypeList();
        // this.props.areasList();
        // this.props.countryList();
        //this.props.usersList();
        //this.props.storesList();
        this.props.companyList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }

    handleItemsInputChange = (e: any) => {
        console.log("e", e);
        let array: any = [];
        e &&
            e.map((obj: any) => {
                array.push(obj.value)
            })
        this.setState({ store: array })
    };

    handleCompanyInputChange = (e: any) => {
        console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ company: e.value });
        } else {
            this.setState({ company: " " });
        }

        this.props.storesList(e.value);
    };

    isGroupReady = () => {
        console.log("this.state", this.state);
        const { name, store, territary, status, company, description } = this.state
        return (name !== "" && store.length > 0 && territary !== "" && company !== "" && description !== "" && status !== "");
    }

    handleSaveBtnClick = (event: any) => {
        let { name, description, store, user, company, territary, status } = this.state;

        let data = {
            name: name,
            description: description,
            store: store,
            territary: territary,
            status: status,
            user_id: user,
            company: company,
            isActive: config.ACTIVE
        }
        console.log("data", data);
        this.props.addGroup(data);
    }

    setStore = (storeId: any) => {
        this.setState({ store: storeId })
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, companies, channels, types, users } = this.props;
        const stores = this.props.stores;

        let storeData: any = [];
        this.props.stores.forEach(element => {
            var store = { 'label': element.name, value: element.id };
            storeData.push(store);
        });

        let companyData: any = [];
        companies.forEach((element: any) => {
            var company = { 'label': element.company_name, value: element.id };
            companyData.push(company);
        });

        console.log("this.props companies", companies);
        if (isInserted) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }

        // let storeData:any = [
        //     { label: "Store A", value: "a" },
        //     { label: "Store B", value: "b" },
        //     { label: "Store C", value: "c" },
        //     { label: "Store D", value: "d" },
        //   ];

        return (
            <div className="page">
                <CheckChanges path="/add-group" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Groups Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/groups" className="text-primary">Groups</Link></li>
                                <li className="breadcrumb-item active">Add Group</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="form-horizontal">

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="name" type="text" name="name" required data-msg="Please enter Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Company<span className="text-danger">*</span></label>
                                                        {/* <MultipleSelect name="store" setStore={this.setStore(id)} optionsMultiSelect={storeData}/> */}
                                                        <div className="col-md-9 col-sm-9">
                                                            <Select
                                                                //defaultValue={}
                                                                //isMulti
                                                                closeMenuOnSelect={false}
                                                                name="company"
                                                                options={companyData}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={e => this.handleCompanyInputChange(e)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Stores<span className="text-danger">*</span></label>
                                                        {/* <MultipleSelect name="store" setStore={this.setStore(id)} optionsMultiSelect={storeData}/> */}
                                                        <div className="col-md-9 col-sm-9">
                                                            <Select
                                                                // defaultValue={[colourOptions[2], colourOptions[3]]}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                name="store"
                                                                options={storeData}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={e => this.handleItemsInputChange(e)}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">User<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="user" className="form-control text-capitalize mt-2" required data-msg="Please select role" onChange={this.handleInputChange}>
                                                                <option value="">Select User</option>
                                                                {(users && users.length > 0) &&
                                                                    users.map((user: any, index: any) => (
                                                                        // <option key={index} value={role.id}>{role.name}</option>
                                                                        <option key={index} value={user.id} >{user.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div> */}

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Description<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <textarea id="description" name="description" required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Territary<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="territary" className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option value={config.INACTIVE}>Disabled</option>
                                                                <option value={config.ACTIVE}>Enabled</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="status" className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option value={config.ACTIVE}>Active</option>
                                                                <option value={config.INACTIVE}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isGroupReady()} onClick={this.handleSaveBtnClick}>Add Group</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    // console.log("state",state);
    return {
        stores: state.store.storeList,
        companies: state.company.companies
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        companyList: function () {
            dispatch(companyList())
        },
        storesList: function (id: any) {
            dispatch(storesList(id))
        },

        addGroup: function (data: any) {
            dispatch(addGroupStore(data));
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
        // deleteStore: function (id:any) {
        //     dispatch(deleteStore(id))
        // }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);