import React, { Component } from 'react';
import { UpdateCompanyProps, UpdateCompanyState } from '../../interfaces/company'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';

export default class AddCompany extends Component<{}, {}> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            address: "",
            phone: "",
            logo: ""
        }
        //this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        // this.props.brandsList();
        // this.props.channelsList();
        // this.props.storeTypeList();
        // this.props.areasList();
        // this.props.countryList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    // isCompanyReady = () => {
    //     const { name, address, phone, logo } = this.state
    //     return (name !== "" && address !== "" && phone !== "" && logo !== "");
    // }
    handleMapData = (obj: any) => {    //to Get the values from the map component and set in state
        this.setState({
            address: obj.address,
            logo: obj.logo,
            phone: obj.phone,
            name: obj.name
        })
    }
    // handleSaveBtnClick = (event: any) => {
    //     let { name, address, phone, logo } = this.state;
    //     let data = {
    //         name: name,
    //         address: address,
    //         logo: logo,
    //         phone: phone,
    //         is_active: 1
    //     }
    //     // this.props.addStore(data);
    // }
    render() {
        // if (sessionStorage.token) {
        //     jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        //         if (err) {
        //             this.props.logoutUser();
        //         }
        //     });
        // } else {
        //     return <Redirect to="/" />
        // }
        let msg;
        let messcolor;
        // const { isInserted, message, brands, channels, types } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Messages Management</h4>
                                </div>
                            </div>
                        </header>

                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/messages" className="text-primary">Messages</Link></li>
                                <li className="breadcrumb-item active">View Messages</li>
                            </ul>
                        </div>

                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label font-weight-bold">Subject</label>
                                                            <p>Message from SimpleX</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label font-weight-bold">Body</label>
                                                            <p>SimpleX message body</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}