// import React, { Component } from 'react';
// import { Link, Redirect } from 'react-router-dom'
// import jwt from 'jsonwebtoken'
// import Chart from 'chart.js'
// import Topbar from '../topbar/topbar'
// import Sidebar from '../sidebar/sidebar'
// import Footer from '../footer/footer'
// import { connect } from 'react-redux'
// import { Line } from '@reactchartjs/react-chart.js'
// import { Bar  } from '@reactchartjs/react-chart.js'
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';


// function viewFormatter(cell: any, row: any) {
//     return (
//         <div>
//             <a href="#" title="View Learner" className="btn btn-outline-primary"><i className="fa fa-eye"></i></a>
//         </div>
//     );
// }

// export default class Store extends Component<{},{showDiv:any}> {
//     constructor(readonly props: any) {
//         super(props);
//         this.state = {showDiv:''}
//     }

//     render() {
//         let roleId: any = ""

//         const learnerData: any =[
//             {
//             'id': '1',
//             'name': 'Junaid Sheikh',
//             'email': 'sheikh@simplexts.net',
//             'phone': '+92323000000000',
//             'address': 'PIA Society, Lahore'
//         },{
//             'id': '2',
//             'name': 'Farooq',
//             'email': 'farooq@simplexts.net',
//             'phone': '+9232311111111',
//             'address': 'Faisal Town, Lahore'
//         },{
//             'id': '3',
//             'name': 'Mahad Butt',
//             'email': 'mahad@simplexts.net',
//             'phone': '+923231231231',
//             'address': 'Wapda Town, Lahore'
//         },
//     ]

//         const options: any = {
//             // sizePerPageList: [5,10,15],
//             sizePerPage: 10, // which size per page you want to locate as default
//             page: 1,
//             pageStartIndex: 1, // where to start counting the pages
//             paginationSize: 3,  // the pagination bar size.
//             hideSizePerPage: true, //You can hide the dropdown for sizePerPage
//             insertModal: () => { return <Redirect to="/add-store" /> },
//             noDataText: 'Store Not Found'
//         };

//         return (
//           <div className="page">
//             <Topbar />
//             <div className="page-content d-flex align-items-stretch">
//               <Sidebar />
//               <div className="content-inner">
//                 {/* <!-- Page Header--> */}
//                 <header className="page-header py-0">
//                   <div className="container-fluid">
//                     <div className="d-flex justify-content-between py-3">
//                       <h4 className="mt-2">Stores Management</h4>
//                     </div>
//                   </div>
//                 </header>

//                 {/*  Breadcrumb */}
//                 <div className="breadcrumb-holder container-fluid">
//                     <ul className="breadcrumb">
//                         <li className="breadcrumb-item"><Link to="/stores" className="text-primary">Stores</Link></li>
//                         <li className="breadcrumb-item active">Add Store</li>
//                     </ul>
//                 </div>

//                    <section className="tables">
//                     <div className="container-fluid">
//                         <div className="row">
//                             <div className="col-lg-12 col-12">
//                                 <div className="card">
//                                     <div className="card-body text-capitalize">
//                                         <BootstrapTable  version='4' data={learnerData} search={true} pagination={learnerData.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='stores.csv' hover>
//                                             <TableHeaderColumn dataField='id' csvHeader='Sr. #' width='70' dataSort={true} isKey>#</TableHeaderColumn>
//                                             <TableHeaderColumn dataField='name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
//                                             <TableHeaderColumn dataField='email' csvHeader='Email' width='100' columnTitle>Email</TableHeaderColumn>
//                                             <TableHeaderColumn dataField='phone' csvHeader='Phone' width='100' columnTitle>Phone</TableHeaderColumn>
//                                             <TableHeaderColumn dataField='address' csvHeader='Address' width='100' columnTitle>Address</TableHeaderColumn>
//                                             <TableHeaderColumn dataField='action' width='70' dataFormat={viewFormatter} export={false}>Action</TableHeaderColumn>
//                                         </BootstrapTable>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>

//                 <Footer />
//               </div>
//             </div>
//           </div>
//         );
//     }
// };



import React, { Component } from 'react';
import { UpdateCompanyProps, UpdateCompanyState } from '../../interfaces/company'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';

export default class AddCompany extends Component<UpdateCompanyProps, UpdateCompanyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            company_name: "",
            address: "",
            phone: "",
            status: ""
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        // this.props.brandsList();
        // this.props.channelsList();
        // this.props.storeTypeList();
        // this.props.areasList();
        // this.props.countryList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isCompanyReady = () => {
        const { company_name, address, phone } = this.state
        return (company_name !== "" && address !== "" && phone !== "");
    }
    handleMapData = (obj: any) => {    //to Get the values from the map component and set in state
        this.setState({
            address: obj.address,
            phone: obj.phone,
            //name: obj.company_name
        })
    }
    handleSaveBtnClick = (event: any) => {
        let { company_name, address, phone } = this.state;
        let data = {
            name: company_name,
            address: address,
            // logo: logo,
            phone: phone,
            is_active: 1
        }
        // this.props.addStore(data);
    }
    render() {
        // if (sessionStorage.token) {
        //     jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        //         if (err) {
        //             this.props.logoutUser();
        //         }
        //     });
        // } else {
        //     return <Redirect to="/" />
        // }
        let msg;
        let messcolor;
        // const { isInserted, message, brands, channels, types } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Messages Management</h4>
                                </div>
                            </div>
                        </header>

                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/messages" className="text-primary">Messages</Link></li>
                                <li className="breadcrumb-item active">Add Message</li>
                            </ul>
                        </div>

                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="form-horizontal">
                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Recipents<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="recipent" className="form-control text-capitalize" required data-msg="Please select brand" onChange={this.handleInputChange}>
                                                                <option value="">Select Recipent</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Subject<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="subject" type="text" name="subject" required data-msg="Please enter Subject" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Description<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="description" type="text" name="description" required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group float-right mt-3">
                                                        <button className='btn btn-primary' disabled={!this.isCompanyReady()} onClick={this.handleSaveBtnClick}>Create Message</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}