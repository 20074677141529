import React, { Component } from 'react';
import { UpdateCompanyProps, UpdateCompanyState } from '../../interfaces/company'
import jwt from 'jsonwebtoken'
import config from '../config/setting'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import { connect } from 'react-redux'
import Footer from '../footer/footer'
import CheckChanges from '../confirmOnLeave';
import { Link, Redirect } from 'react-router-dom';
import { logoutUser } from './../../redux/actions/user';
import { companyList, editCompany, getCompany } from '../../redux/actions/company';

class EditCompany extends Component<UpdateCompanyProps, UpdateCompanyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            company_name: "",
            address: "",
            phone: "",
            email: "",
            status: "1",
            isValidEmail: true,
            isValidPhone: true,
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.companyList();
        //let id = this.props.match.params.id;
        //this.props.getCompany(id);
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        console.log("nextProps",nextProps);
        
        this.setState({
            company_name: nextProps.companies[0].company_name,
            address: nextProps.companies[0].address,
            phone: nextProps.companies[0].phone,
            email: nextProps.companies[0].email,
            // status: nextProps.companies[0].status,
        });
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }
    isCompanyReady = () => {
        const { company_name, address, phone, email } = this.state
        return (company_name !== "" && address !== "" && phone !== "" && email !== "");
    }

    validateEmail = (event: { target: { name: any; value: any; }; }) => {
        var reg = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (reg.test(event.target.value) === false) {
          this.setState({ email: event.target.value, isValidEmail: false })
        } else {
          this.setState({ email: event.target.value, isValidEmail: true });
        }
        };
    validatePhone = (event: { target: { name: any; value: any; }; }) => {
        let maxNum = 11;
        if (event.target.value.length > maxNum) {
        event.target.value = event.target.value.slice(0, maxNum);
        }
        let reg = /[^0-9]/;
        if (reg.test(event.target.value) == false) {
        this.setState({ phone: event.target.value, isValidPhone: true })
        } else {
        this.setState({ phone: event.target.value.replace(''), isValidPhone: false });
        }
    };

    handleSaveBtnClick = (event: any) => {
        let { company_name, email, address, phone, status } = this.state;
        let id = this.props.match.params.id;
        let data = {
            company_name: company_name,
            address: address,
            phone: phone,
            email: email,
            status: status,
            isActive: config.ACTIVE
        }
        this.props.editCompany(id, data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        // const { isInserted, message, brands, channels, types } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }
        const { companies } = this.props;
        console.log("companies", companies);

        let company = companies[0];
        console.log("company", company);
        
        // let name = company && company.company_name;
        // console.log("Name", name);
        
        return (
            <div className="page">
                <CheckChanges path="/edit-company" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Company Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/companies" className="text-primary">Companies</Link></li>
                                <li className="breadcrumb-item active">Edit Company</li>
                            </ul>
                        </div> */}
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="form-horizontal">
                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="company_name" type="text" defaultValue={company && company.company_name} name="company_name" required data-msg="Please enter Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Email<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="email" type="email" name="email" defaultValue={company && company.email} required data-msg="Please enter Email" className="input-material" onChange={this.validateEmail} />
                                                            {(this.state.isValidEmail == false && this.state.email !== "") &&
                                                            <small className="form-text text-danger">Invalid Email</small>} 
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Address<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="address" type="text" name="address" defaultValue={company && company.address} required data-msg="Please enter Address" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Contact<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="phone" type="text" name="phone" defaultValue={company && company.phone} required data-msg="Please enter Phone" className="input-material" onChange={this.validatePhone} />
                                                            {(this.state.isValidPhone == false && this.state.phone !== "") &&
                                                            <small className="form-text text-danger">Invalid Phone Number</small>}
                                                        </div>
                                                    </div>

                                                    {/* <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="status" value={this.state.status} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option {...company && company.status === config.ACTIVE && { selected: true }} value={config.ACTIVE}>Active</option>
                                                                <option {...company && company.status === config.INACTIVE && { selected: true }} value={config.INACTIVE}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div> */}

                                                </div>

                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isCompanyReady()} onClick={this.handleSaveBtnClick}>Update Company</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    console.log("state", state);
    return {
        company: state.company.company,
        companies: state.company.companies
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        companyList: function () {
            dispatch(companyList())
        },
        editCompany: function (id: any, data: any) {
            dispatch(editCompany(id, data))
        },
        getCompany: function (id: any) {
            dispatch(getCompany(id))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);