import {
    UG_LIST, GET_UG, USR_LIST, ROLES_LIST
} from '../actions/types';
const initialState: any = {
    usergroup_list: [] || '',
    usergroup: {},
    userList: [] || "",
    roles: [] || ""

};
const UserGroupReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UG_LIST:
            return {
                ...state,
                usergroup_list: action.payload
            };
        case USR_LIST:
            return {
                ...state,
                userList: action.payload
            };
        case ROLES_LIST:
            return {
                ...state,
                roles: action.payload
            };
        case GET_UG:
            return {
                ...state,
                usergroup: action.payload
            };
        default:
            return state;
    }
};
export default UserGroupReducer;