import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { logoutUser } from './../../redux/actions/user';
import { connect } from 'react-redux';
import { deleteTicket, getTicket } from './../../redux/actions/ticket';
import { BASE_URL } from "../config/setting";

import ReactTimeAgo from 'react-time-ago'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
//import ru from 'javascript-time-ago/locale/ru'
TimeAgo.addLocale(en)

function viewFormatter(cell: any, row: any) {
    return (
        <div>
            <a href="#" title="View Learner" className="btn btn-outline-primary"><i className="fa fa-eye"></i></a>
        </div>
    );
}

class ActionFormatter extends Component<{ row: any, data: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.data.deleteTicket(id);
    };
    render() {
        const { row } = this.props;
        console.log("row", row)
        return (
            <div>
                <Link to="#" title="Delete Ticket" data-toggle="modal" data-target={`#deleteTicket${row.id}`} className="btn btn-outline-danger mx-2"><i className="fa fa-trash"></i></Link>
                <Link to={`/edit-ticket/${row.id}`} title="Edit Ticket" className="btn btn-outline-primary"><i className="fa fa-edit"></i></Link>
                {/* <Link title="Edit Store" className="btn btn-outline-primary" to={`/edit-store/${row.store_id}`}><i className="fa fa-edit"></i></Link> */}
                {/* <!-- B/U Modal--> */}
                <div id={`deleteTicket${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Ticket</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to Delete this ticket?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button type="button" data-dismiss="modal" className="btn btn-primary" onClick={() => { this.handleDelete(row.id) }}>Confirm</button>
                                {/* <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function actionFormatter(cell: any, row: any, props: any) {

    return (
        // <ActionFormatter row={row} blockunblock={props.blockunblockstore} />
        <ActionFormatter row={row} data={props} />
    );
}

class TicketDetails extends Component<{ ticket: any, deleteTicket: () => {}, logoutUser: () => {} }, { showDiv: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = { showDiv: '' }
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getTicket(id);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        console.log(this.props.ticket);

        const { ticket } = this.props;

        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Ticket Management</h4>
                                    <Link className="btn btn-primary" to="/add-ticket"><i className="fa fa-plus"></i> &nbsp; Add Ticket</Link>
                                </div>

                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/tickets" className="text-primary">Tickets</Link></li>
                                <li className="breadcrumb-item active">Ticket Details</li>
                            </ul>
                        </div>

                        <section className="tables">
                            <div className="container-fluid">
                                <div className="ontainer">
                                    <div className="card">
                                        <div className="card-header">
                                            <span className="h1 text-muted">Ticket Details</span>
                                        </div>
                                        <div className="card-body">
                                            <div className="container">
                                                <div className="text-muted"><span style={{ fontSize: 20, fontWeight: "inherit", marginBottom: 20 }}>{ticket.subject}</span>  &nbsp; <small>(# {ticket.id})</small></div>
                                                <div className="feed d-flex justify-content-between">
                                                    <div className="feed-body d-flex justify-content-between">
                                                        <a href="#" className="feed-profile">
                                                            <img src={`${BASE_URL}${ticket.image}`} alt="..." width="50" height="50" className="img-fluid rounded-circle" />
                                                        </a>
                                                        <div className="content" style={{ marginLeft: 20, marginTop: 10 }}>
                                                            <h5 className="text-muted">{ticket.requester}</h5>
                                                            <small className="text-muted"><b>{ticket.ticket_status_description}</b> | &nbsp; <ReactTimeAgo date={ticket.createdAt || !NaN} locale="en-US" /></small>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ marginTop: 20, marginBottom: 20, minHeight: 20, backgroundColor: "#f8f9fa" }}>
                                                    <textarea className="text-dark" disabled value={ticket.description} />
                                                </div>
                                                <div className="card-footer">
                                                    <small className="text-muted">
                                                        <b>Priority Level</b> &nbsp; | &nbsp; {ticket.ticket_priority_description}
                                                    </small>
                                                    <Link className="float-right btn btn-outline-info" style={{ marginTop: -6 }} to={`/edit-ticket/${ticket.id}`}>Edit Ticket</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state: any) => {
    return {
        ticket: state.ticket.ticket,
        isLoad: state.ticket.isLoad
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getTicket: function (id: any) {
            dispatch(getTicket(id))
        },
        deleteTicket: function (id: any) {
            dispatch(deleteTicket(id))
        },
        logoutUser: function () {
            dispatch(logoutUser);
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TicketDetails);