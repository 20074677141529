import {
    PRIORITIES_LIST, GET_PRIORITY
} from '../actions/types';
const initialState: any = {
    priorities: [] || '',
    priority: {}

};
const PriorityReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PRIORITIES_LIST:
            return {
                ...state,
                priorities: action.payload
            };

        case GET_PRIORITY:
            return {
                ...state,
                priority: action.payload
            };
        default:
            return state;
    }
};
export default PriorityReducer;