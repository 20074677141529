import React, { Component } from 'react';
import { EditCategoryProps, EditCategoryState } from '../../interfaces/category'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';
import config from '../config/setting'
import CheckChanges from '../confirmOnLeave';
import Select from 'react-select'
import { addCategory, getCategory } from '../../redux/actions/category';
import { logoutUser } from './../../redux/actions/user';
import { editCategory, getSubCategoryByCatID } from './../../redux/actions/category';

class EditCategory extends Component<EditCategoryProps, EditCategoryState> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            description: "",
            subCategory: [{name: ""}],
            status: "1",
            //isActive: "1"
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getCategory(id);
        this.props.getSubCategoryByCatID(id);

    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        // console.log("nextProps", nextProps.sub_category);
        var sub_cats = nextProps && nextProps.sub_category;
        if (sub_cats.length > 0) {
            var list: any = [];
            sub_cats.forEach((el: any) => {
                const newlist: any = { name: el.name }
                list.push(newlist);
                this.setState({ subCategory: list });
            });
        }
        this.setState({
            name: nextProps.category.cat_name,
            description: nextProps.category.description,
            status: nextProps.category.status,
        });
    }

    isCatReady = () => {
        const { name, description } = this.state
        return (name !== "" && description !== "");
    }

    handleSubCategoryInputChange = (e: any, index: any) => {
        if (e.target) {
            const { name, value } = e.target;
            const list: any = this.state.subCategory;
            list[index][name] = value;
            this.setState({ subCategory: list });
        }
    };

    handleAddSubCategory = (i: any) => {
        const list: any = this.state.subCategory;
        if (list[i]["name"] == "") {
            alert("Please first fill in current fields")
        } else if (i == '4') {
            alert("Sorry you can add only 5 sub categories")
        } else {
            const newlist: any = { name: "" }
            const subCate: any = this.state.subCategory.concat(newlist)
            this.setState({ subCategory: subCate });
        }
    };

    handleRemoveSubCategory = (index: any) => {
        const list = this.state.subCategory;
        list.splice(index, 1);
        this.setState({ subCategory: list });
    };

    handleSaveBtnClick = (event: any) => {
        let { name, description, status, subCategory } = this.state;
        let id = this.props.match.params.id;

        let data = {
            cat_name: name,
            description: description,
            status: status,
            isActive: config.ACTIVE,
            // for sub category
            subCategory: JSON.stringify(subCategory)
        }

        console.log("data", data);
        this.props.editCategory(id, data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { category, sub_category } = this.props;
        const { subCategory } =  this.state;
        console.log("this.props", this.props);

        return (
            <div className="page">
                <CheckChanges path="edit-category" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Category Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/categories" className="text-primary">Category</Link></li>
                                <li className="breadcrumb-item active">Edit Category</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body pt-xl-0">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="form-horizontal p-xl-4">

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="name" type="text" name="name" defaultValue={category.cat_name} required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Description<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <textarea id="description" defaultValue={category.description} name="description" style={{ border: "1px solid #20A5D6" }} required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} ></textarea>
                                                        </div>
                                                    </div>

                                                    {subCategory.map((x: any, i: any) => {
                                                        return (
                                                            <div key={i}>
                                                                <div className="row form-group">
                                                                    <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Sub Category<span className="text-danger">*</span></label>
                                                                    <div className="col-md-7 col-sm-7">
                                                                        <input id="name" type="text" name="name" value={x.name} required placeholder="Sub Category Name"  className="input-material" onChange={e => this.handleSubCategoryInputChange(e, i)} />
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-2 mt-3">
                                                                    {subCategory.length !== 1 &&
                                                                        <button className="btn btn-sm btn-outline-danger"
                                                                            onClick={() => this.handleRemoveSubCategory(i)}><i className="fa fa-trash"></i></button>}
                                                                    {subCategory.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddSubCategory(i)}><i className="fa fa-plus"></i></button>}    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="status" defaultValue={category.status} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option {...category.status == config.ACTIVE && { selected: true }} value={config.ACTIVE}>Active</option>
                                                                <option {...category.status == config.INACTIVE && { selected: true }} value={config.INACTIVE}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isCatReady()} onClick={this.handleSaveBtnClick}>Edit Category</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    console.log("state", state);
    return {
        category: state.category.category,
        sub_category: state.category.sub_category
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {

        editCategory: function (id: any, data: any) {
            dispatch(editCategory(id, data));
        },
        getCategory: function (id: number) {
            dispatch(getCategory(id))
        },
        getSubCategoryByCatID: function (id: number) {
            dispatch(getSubCategoryByCatID(id))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);