// STORES
export const STORE_LIST = "STORE_LIST";
export const STORE_GET = "STORE_GET";
export const STORE_GET_BY_COMPANY = "STORE_GET_BY_COMPANY"

// STORE GROUPS
export const GROUP_LIST = "GROUP_LIST";
export const GROUP_PARENT_LIST = "GROUP_PARENT_LIST";
export const GROUP_GET = "GROUP_GET";
export const GROUP_BY_STORES_GET = "GROUP_STORES_GET";
export const GROUP_BY_ZONES_GET = "GROUP_BY_ZONES_GET";

// Ticket
export const TICKET_LIST = "TICKET_LIST";
export const TICKET_GET = "STORE_GET";
export const ADD_TICKET = "ADD_TICKET";
export const PRIORITY_LIST = "PRIORITY_LIST"
export const STATUS_LIST = "STATUS_LIST";
export const GROUPS_LIST = "GROUPS_LIST"
export const TYPE_LIST = "TYPE_LIST"
export const ENGINEERS_LIST = "ENGINEERS_LIST"
export const ENGINEER_TICKET_LIST = "ENGINEER_TICKET_LIST";
export const REQUESTER_TICKET_LIST = "REQUESTER_TICKET_LIST";
export const EXISTING_TICKET = "EXISTING_TICKET";
export const TICKET_COUNTS = "TICKET_COUNTS";
export const SUPERVISOR_TICKET_LIST = "SUPERVISOR_TICKET_LIST";
export const TICKET_BY_COMPANY = "TICKET_BY_COMPANY";
export const FEEDBACK_LIST = "FEEDBACK_LIST";
export const TICKET_DISCUSSION_LIST_BY_TICKET = "TICKET_DISCUSSION_LIST_BY_TICKET";
export const TICKET_COUNTS_ENG = "TICKET_COUNTS_ENG";
export const ENGINEER_ADMIN_TICKETS = "ENGINEER_ADMIN_TICKETS";
export const FEEDBACK_ENGINEERS_ADMIN = "FEEDBACK_ENGINEERS_ADMIN";
export const FILTERED_TICKETS = "FILTERED_TICKETS";
export const TIMER_STATUS = "TIMER_STATUS"
export const BAR_CHART_DATA = "BAR_CHART_DATA"
export const TOP_CUSTOMERS = "TOP_CUSTOMERS"
export const TOP_CATEGORIES = "TOP_CATEGORIES"
export const BAR_CHART_DATA_ENG = "BAR_CHART_DATA_ENG"
export const TICKET_BY_PERIORITY_ENG = "TICKET_BY_PERIORITY_ENG"
export const TICKET_UPDATE_BY_ENG = "TICKET_UPDATE_BY_ENG"
export const TICKET_UPDATE_BY_SUP = "TICKET_UPDATE_BY_SUP"
export const SEND_REPLY = "SEND_REPLY"
export const PAUSED_TICKETS = "PAUSED_TICKETS"

// Reports
export const TICKET_REPORT = "TICKET_REPORT";
export const RE_OPENED_TICKET_REPORT = "RE_OPENED_TICKET_REPORT";
export const RESOLVED_SLA_TICKET_REPORT = "RESOLVED_SLA_TICKET_REPORT";
export const OPENED_CLOSED_TICKET_REPORT = "OPENED_CLOSED_TICKET_REPORT";
export const OPENED_SINCE_DAYS_TICKET_REPORT = "OPENED_SINCE_DAYS_TICKET_REPORT";
export const TICKET_BY_CATEGORY_REPORT = "TICKET_BY_CATEGORY_REPORT";
export const TICKET_BY_PERIORITY_REPORT = "TICKET_BY_PERIORITY_REPORT";

export const ENGINEERS_REPORT = "ENGINEERS_REPORT";

export const ERROR = "ERROR";

export const RE_ASSIGN_DATA = "RE_ASSIGN_DATA";



// Category
export const CAT_LIST = "CAT_LIST"
export const GET_CAT = "GET_CAT"
export const GET_SUBCAT_BY_CAT_ID = "GET_SUBCAT_BY_CAT_ID"



// Sub Category
export const SUB_CAT_LIST = "SUB_CAT_LIST"
export const GET_SUB_CAT = "GET_SUB_CAT"
export const CATE_LIST = "CATE_LIST"

// User Groups

export const UG_LIST = "UG_LIST"
export const GET_UG = "GET_UG"
export const USR_LIST = "USR_LIST"
export const ROLES_LIST = "ROLES_LIST"
// export const CATE_LIST = "CATE_LIST"

// Priority
export const PRIORITIES_LIST = "PRIORITIES_LIST"
export const GET_PRIORITY = "GET_PRIORITY"

// Company

export const COMPANY_LIST = "COMPANY_LIST"
export const GET_COMPANY = "GET_COMPANY"



// ROLES
export const ROLE_LIST = "ROLE_LIST";

//USERS
export const USER_LIST = "USER_LIST";
export const USER_GET = "USER_GET";
export const USERS_BY_COURSE_GROUPS = "USERS_BY_COURSE_GROUPS";
export const USER_ADD = "USER_ADD";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASS = "FORGOT_PASS";
export const ENGINEER_LIST = "ENGINEER_LIST";
export const ENGINEER_ADMINS = "ENGINEER_ADMINS";



//ZONES
export const ZONE_LIST = "ZONE_LIST";
export const ZONE_GET = "ZONE_GET";
export const ZONE_ADD = "ZONE_ADD";

//MODULES
export const MODULE_LIST = "MODULE_LIST";
export const MODULE_GET = "MODULE_GET";
export const MODULE_ADD = "MODULE_ADD";
export const MODULE_INPUT = "MODULE_INPUT";

//COURSES
export const COURSE_LIST = "COURSE_LIST";
export const COURSE_GET = "COURSE_GET";
export const COURSE_ADD = "COURSE_ADD";
export const COURSE_INPUT = "COURSE_INPUT";
export const COURSE_IMAGE = "COURSE_IMAGE";
export const COURSE_MODULE = "COURSE_MODULE";
export const COURSES_MODULE_GET = "COURSES_MODULE_GET";
export const COURSES_MODULE_GET_ID = "COURSES_MODULE_GET_ID";
export const COURSE_GROUP_LIST = "COURSE_GROUP_LIST";
export const GET_COURSE_BY_MODULE = "GET_COURSE_BY_MODULE";
export const CHECK_MODULE_PASS = "CHECK_MODULE_PASS";

//ASSESMENT MODULES
export const ASSESMENT_MODULE_LIST = "ASSESMENT_MODULE_LIST";
export const ASSESMENT_MODULE_GET = "ASSESMENT_MODULE_GET";
export const ASSESMENT_MODULE_ADD = "ASSESMENT_MODULE_ADD";

//ASSESMENT COURSES
export const ASSESMENT_COURSE_LIST = "ASSESMENT_COURSE_LIST";
export const ASSESMENT_COURSE_GET = "ASSESMENT_COURSE_GET";
export const ASSESMENT_COURSE_ADD = "ASSESMENT_COURSE_ADD";

//LEARNER
export const LEARNER_COURSE_LIST = "LEARNER_COURSE_LIST";
export const ADD_LEARNER_MODULE_ASSESMENT = "ADD_LEARNER_MODULE_ASSESMENT";