import React, { Component } from 'react';
import { AddStoreProps, AddStoreState } from '../../interfaces/store'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';


class DoughnutChart extends Component<{},{}> {
    chart: any;
	constructor(readonly props: any) {
		super(props);
	}
	
	render() {
        let roleId: any = ""
		let data:any = {
            datasets: [{
                data: [120, 46],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    // 'rgba(255,99,132,1)',
                    // 'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }],
            // datasets: [{
            //     data: [10],
            //     fill: false,
            //     backgroundColor: '#007bff',
            //     borderColor: '#007bff',
            // },
            // {
            //     data: [30],
            //     fill: false,
            //     backgroundColor: '#e83e8c',
            //     borderColor: '#e83e8c',
            // },
            // {
            //     data: [50],
            //     fill: false,
            //     backgroundColor: '#fd7e14',
            //     borderColor: '#fd7e14',
            // }],
        
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['Assigned Courses','Passed Courses']
        };
          
        //   let options: any = {
        //     display:true,
        //     scales: {
        //       yAxes: [
        //         {
        //           ticks: {
        //             beginAtZero: true,
        //           },
        //         },
        //       ],
        //     },
        //   }
		return (
      <div></div>
            // <Doughnut data={data} type='doughnut' />
		);
	}
}

function viewFormatter(cell: any, row: any) {
    return (
        <div>
            <Link to="/view-certificate" target="_blank" title="View Certificate" className="btn btn-outline-primary"><i className="fa fa-eye"></i></Link>
        </div>
    );
}

export default class ActionFormatter extends Component<{ row: any, data: any }, { [x: number]: any, activeTab: any, date: any, time: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            activeTab: 0,
            date: "",
            time: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    componentDidMount() {
        // this.props.brandsList();
        // this.props.channelsList();
        // this.props.storeTypeList();
        // this.props.areasList();
        // this.props.countryList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        let roleId: any = "1"
        // if (sessionStorage.token) {
        //     jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        //         if (err) {
        //             this.props.logoutUser();
        //         }
        //     });
        // } else {
        //     return <Redirect to="/" />
        // }
        let msg;
        let messcolor;
        const { isInserted, message, brands, channels, types } = this.props;
        if (isInserted) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }

        const courseData: any =[
            {
            'id': '1',
            'name': 'Course A',
            'progress': '30%',
            'enrolled': '20-Jun-2020',
            'completion': '01-Sep-2020'
        }]
    const assesmentsData: any =[
        {
        'id': '1',
        'name': 'Cooking',
        'totalScore': '210',
        'earned': '86',
        }]
    const certificateData: any =[
        {
        'id': '1',
        'name': 'Cooking',
        'issueDate': '20-Jul-2020',
        'expiryDate': '20-Dec-2020',
        }]
    const badgesData: any =[
        {
        'id': '1',
        'name': 'Cooking',
        'badge' : 'Gold',
        'image': '/assets/img/img/certificate.png',
        },
        {
        'id': '2',
        'name': 'Accounts',
        'badge' : 'Silver',
        'image': '/assets/img/img/certificate.png',
        },
        {
        'id': '3',
        'name': 'Cleaning',
        'badge' : 'Bronze',
        'image': '/assets/img/img/certificate.png',
        }
    ]

        const optionsTable: any = {
            sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            alwaysShowAllBtns: true,
            noDataText: 'Learner Not Found'
        };

        return (
          <div className="page">
            <Topbar />
            <div className="page-content d-flex align-items-stretch">
              <Sidebar />
              <div className="content-inner">
                {/* <!-- Page Header--> */}
                <header className="page-header pb-1">
                  <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4>Learner Details</h4>
                    </div>
                  </div>
                </header>
                {/*  Breadcrumb */}
                {/* <div className="breadcrumb-holder container-fluid">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">Junaid Sheikh</li>
                  </ul>
                </div> */}

                <section className="dashboard-counts no-padding-bottom">
                  <div className="container-fluid">
                    <div className="row bg-white has-shadow">
                      {/* <!-- Item --> */}
                      <div className="col-xl-4 col-sm-12">
                        <div className="item d-flex align-items-center">
                            <div className="icon bg-violet">
                              <i className="fa fa-users"></i>
                            </div>
                          <div className="title">
                            <b>Junaid Sheikh</b>
                            <div className="progress">
                              <div
                                role="progressbar"
                                style={{ width: `${90 / 100}%`, height: "4px" }}
                                className="progress-bar bg-violet"
                              ></div>{" "}
                              {/*ariaValuenow="25" ariaValuemin="0" ariaValuemax="100"*/}
                            </div>
                          </div>
                        </div>
                      </div>
                     
                      <div className="col-xl-4 col-sm-12">
                        <div className="item d-flex align-items-center">
                              <div className="icon bg-orange">
                                <i className="fa fa-user"></i>
                              </div>
                              <div className="title">
                            <b>Cheff</b>
                            <div className="progress">
                              <div
                                role="progressbar"
                                style={{ width: `${56 / 100}%`, height: "4px" }}
                                className="progress-bar bg-green"
                              ></div>{" "}
                              {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Item --> */}
                      <div className="col-xl-4 col-sm-12">
                        <div className="item d-flex align-items-center">
                              <div className="icon bg-green">
                                <i className="fa fa-phone"></i>
                              </div>
                          <div className="title">
                            <b>+9232300000000</b>
                            <div className="progress">
                              <div
                                role="progressbar"
                                style={{ width: `${56 / 100}%`, height: "4px" }}
                                className="progress-bar bg-green"
                              ></div>{" "}
                              {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="forms">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body p-0 mb-5">
                            <Tabs
                              onSelect={(index) =>
                                this.setState({
                                  activeTab: index,
                                  date: "",
                                  time: "",
                                })
                              }
                            >
                              <TabList
                                style={{
                                  background: "#20a5d6",
                                  color: "#FFF",
                                  padding: "10px",
                                }}
                              >
                                <Tab>Overview</Tab>
                                <Tab>Courses</Tab>
                                <Tab>Certificates</Tab>
                                <Tab>Badges</Tab>
                                <Tab>Assesments</Tab>
                              </TabList>

                              <TabPanel style={{ marginTop: '2em' }}>
                                <div className="container-fluid">
                                  <div className="row">
                                    {/* <!-- Bar Chart --> */}
                                    <div className="chart col-lg-6 col-6">
                                      <div className="line-chart bg-white d-flex align-items-center justify-content-center has-shadow">
                                        <DoughnutChart />
                                      </div>
                                    </div>
                                    <div className="chart col-lg-6 col-6">
                                    <div className="statistic d-flex align-items-center bg-white has-shadow">
                                        <div className="icon bg-red"><i className="fa fa-tasks"></i></div>
                                        <div className="text"><strong>13</strong><br></br><small>Certificates</small></div>
                                    </div>
                                    <div className="statistic d-flex align-items-center bg-white has-shadow">
                                        <div className="icon bg-green"><i className="fa fa-calendar-o"></i></div>
                                        <div className="text"><strong>2</strong><br></br><small>Badges</small></div>
                                    </div>
                                    <div className="statistic d-flex align-items-center bg-white has-shadow">
                                        <div className="icon bg-orange"><i className="fa fa-paper-plane-o"></i></div>
                                        <div className="text"><strong>38</strong><br></br><small>Assesments</small></div>
                                    </div>
                                    </div>
                                    {/* <div className="chart col-lg-3 col-3"></div> */}


                                  </div>
                                </div>
                              </TabPanel>
                              <TabPanel style={{ marginTop: '2em' }}>
                                <div className="container-fluid">
                                    <div className="row">
                                    {/* <!-- Bar Chart --> */}
                                    <div className="col-3 col-md-3">
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <div className="icon bg-red"><i className="fa fa-tasks"></i></div>
                                            <div className="text"><strong>15</strong><br></br><small>Assigned</small></div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-md-3">
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <div className="icon bg-green"><i className="fa fa-calendar-o"></i></div>
                                            <div className="text"><strong>2</strong><br></br><small>Passed</small></div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-md-3">
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <div className="icon bg-violet"><i className="fa fa-paper-plane-o"></i></div>
                                            <div className="text"><strong>38</strong><br></br><small>Pending</small></div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-md-3">
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <div className="icon bg-orange"><i className="fa fa-dashboard"></i></div>
                                            <div className="text"><strong>38</strong><br></br><small>In Progress</small></div>
                                        </div>
                                    </div>
                                   
                                    
                                    
                                    
                                  </div>
                                    
                                    <span className="mt-5 w-100 border-bottom-2"></span>
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                    <div className="card-header">
                                        <strong>All Courses</strong>
                                    </div>
                                        <div className="card">
                                            <div className="card-body text-capitalize">
                                                <BootstrapTable  version='4' data={courseData} search={true} pagination={courseData.length > 10 && true} options={optionsTable} exportCSV={true} csvFileName='courses.csv' hover>
                                                    <TableHeaderColumn dataField='id' csvHeader='Sr. #' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='progress' csvHeader='Progress' width='100' columnTitle>Progress</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='enrolled' csvHeader='Enrolled On' width='100' columnTitle>Enrolled On</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='completion' csvHeader='Completion Date' width='100' columnTitle>Completion Date</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                
                                </div>
                              </TabPanel>
                              <TabPanel style={{ marginTop: '2em' }}>
                                <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-lg-12 col-12">
                                        <div className="card-header">
                                            <strong>All Certificates</strong>
                                        </div>
                                            <div className="card">
                                                <div className="card-body text-capitalize">
                                                    <BootstrapTable  version='4' data={certificateData} search={true} pagination={certificateData.length > 10 && true} options={optionsTable} exportCSV={true} csvFileName='certificates.csv' hover>
                                                        <TableHeaderColumn dataField='id' csvHeader='Sr. #' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='issueDate' csvHeader='Progress' width='100' columnTitle>Issue Date</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='expiryDate' csvHeader='Enrolled On' width='100' columnTitle>Expiry Date</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='action' width='70' dataFormat={viewFormatter} export={false}>Action</TableHeaderColumn>
                                                    </BootstrapTable>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    
                                    </div>
                              </TabPanel>
                              <TabPanel style={{ marginTop: '2em' }}>
                              <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="card-header">
                                                <strong>All Badges</strong>
                                            </div>
                                            <div className="card">
                                                <div className="card-body text-capitalize">
                                                    <BootstrapTable  version='4' data={badgesData} search={true} pagination={badgesData.length > 10 && true} options={optionsTable} exportCSV={true} csvFileName='badges.csv' hover>
                                                        <TableHeaderColumn dataField='id' csvHeader='Sr. #' width='20' dataSort={true} isKey>#</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='name' csvHeader='Course' width='100' dataSort={true} columnTitle>Course</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='badge' csvHeader='Badge' width='100' columnTitle>Badge</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='image' csvHeader='Image' dataFormat={imageFormatter} width='100' columnTitle export={false}>Image</TableHeaderColumn>
                                                    </BootstrapTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </TabPanel>
                              <TabPanel style={{ marginTop: '2em' }}>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="card-header">
                                                <strong>All Assesments</strong>
                                            </div>
                                            <div className="card">
                                                <div className="card-body text-capitalize">
                                                    <BootstrapTable  version='4' data={assesmentsData} search={true} pagination={assesmentsData.length > 10 && true} options={optionsTable} exportCSV={true} csvFileName='Assesments.csv' hover>
                                                        <TableHeaderColumn dataField='id' csvHeader='Sr. #' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='name' csvHeader='Course' width='100' dataSort={true} columnTitle>Course</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='totalScore' csvHeader='Total Score' width='100' columnTitle>Total Score</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='earned' csvHeader='Earned' width='100' columnTitle>Earned</TableHeaderColumn>
                                                    </BootstrapTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </TabPanel>
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <Footer />
              </div>
            </div>
          </div>
        );
    }
}

function imageFormatter(cell: any, row: any) {
   console.log(cell)
   return (
       <div>
           <img className="zoomImageBadge" src={process.env.PUBLIC_URL + `${cell}`} style={{width:35}} alt="badge"/>
       </div>
   )
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}

const mapStateToProps = (state: any) => {
    return {
        message: state.store.message,
        isInserted: state.store.isInserted,
        brands: state.store.brands,
        channels: state.store.channels,
        types: state.store.types,
        areas: state.store.areas,
        countries: state.store.countries,
    }
}
// const mapDispatchToProps = (dispatch: any) => {
//     return {
//         addStore: function (data: any) {
//             dispatch(addStore(data));
//         },
//         brandsList: function () {
//             dispatch(brandsList())
//         },
//         channelsList: function () {
//             dispatch(channelList())
//         },
//         storeTypeList: function () {
//             dispatch(storeTypeList())
//         },
//         areasList: function () {
//             dispatch(areasList())
//         },
//         countryList: function () {
//             dispatch(countryList())
//         }
//     }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(AddStore);