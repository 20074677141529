import { createStore, applyMiddleware, combineReducers } from 'redux';
import GroupReducer from '../reducers/groupStore';
import StoreReducer from '../reducers/store';
import UserReducer from '../reducers/user';
import ZoneReducer from '../reducers/zone';
import TicketReducer from './../reducers/ticket';
import CategoryReducer from './../reducers/category';
import SubCategoryReducer from './../reducers/sub-category';
import UserGroupReducer from './../reducers/usergroups';
import PriorityReducer from './../reducers/priority';
import CompanyReducer from './../reducers/company';
import reportReducer from './../reducers/reportReducer';

const thunkMiddleware = require('redux-thunk').default;
const mainReducer = combineReducers({
  store: StoreReducer,
  group: GroupReducer,
  user: UserReducer,
  zone: ZoneReducer,
  ticket: TicketReducer,
  category: CategoryReducer,
  subCategory: SubCategoryReducer,
  usergroup: UserGroupReducer,
  priority: PriorityReducer,
  company: CompanyReducer,
  report: reportReducer
});
const store = createStore(mainReducer, applyMiddleware(thunkMiddleware));
export default store;