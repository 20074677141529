import {
    TICKET_GET, TICKET_LIST, FEEDBACK_ENGINEERS_ADMIN, ENGINEER_ADMIN_TICKETS, TICKET_COUNTS_ENG, COMPANY_LIST, TICKET_BY_COMPANY, EXISTING_TICKET, SUPERVISOR_TICKET_LIST, REQUESTER_TICKET_LIST, ENGINEER_TICKET_LIST, ADD_TICKET, ENGINEERS_LIST, PRIORITY_LIST, TYPE_LIST, STATUS_LIST, GROUPS_LIST, SUB_CAT_LIST, TICKET_COUNTS, FEEDBACK_LIST, TICKET_DISCUSSION_LIST_BY_TICKET, FILTERED_TICKETS, TIMER_STATUS, BAR_CHART_DATA, TOP_CUSTOMERS, TOP_CATEGORIES, BAR_CHART_DATA_ENG, TICKET_BY_PERIORITY_ENG, TICKET_UPDATE_BY_ENG, TICKET_UPDATE_BY_SUP, SEND_REPLY, PAUSED_TICKETS, RE_ASSIGN_DATA
} from '../actions/types';
const initialState: any = {
    ticketList: [] || '',
    ticket: {},
    existing_ticket: "",
    assigneeTicketList: [] || '',
    requesterTicketList: [] || '',
    supervisorTicketList: [] || '',
    priority: [] || "",
    status: [] || "",
    ticketByComp: [] || "",
    supervisorStatus: [] || "",
    engineerStatus: [] || "",
    pausedStatus: [] || "",
    resumeStatus: [] || "",
    assignedStatus: [] || "",
    groups: [] || "",
    types: [] || "",
    companies: [] || "",
    feedbacks: [] || "",
    ticketCounts: {},
    ticket_count_eng: {},
    feedbacks_eng: [] || "",
    subCategory: [] || "",
    engineers: [] || "",
    tickets_eng: [] || "",
    isInserted: "",
    message: "",
    ticket_discussion_list: [] || "",
    filteredOrders: [] || "",
    emptyFilterOrder: false,
    ticketsReceived: 0,
    ticketsAssigned: 0, 
    ticketsResolved: 0,
    ticketsClosed: 0,
    timerStatus: true,
    barChartData: {} || "",
    barChartDataEng: {} || "",
    topCustomers: [] || "",
    topCategories: [] || "",
    ticketByPriorityEng: [] || "",
    pausedData: [] || "",
    reAssignData: [] || "",
    isLoad: false
};
const TicketReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TICKET_LIST:
            return {
                ...state,
                ticketList: action.payload,
                ticketsReceived: action.ticketsReceived,
                ticketsAssigned: action.ticketsAssigned,
                ticketsResolved: action.ticketsResolved,
                ticketsClosed: action.ticketsClosed
            };
        case ENGINEER_TICKET_LIST:
            return {
                ...state,
                assigneeTicketList: action.payload
        }
        case FEEDBACK_ENGINEERS_ADMIN:
            return {
                ...state,
                feedbacks_eng: action.payload
        }
        case RE_ASSIGN_DATA:
            return {
                ...state,
                reAssignData: action.payload
        }     
        case ENGINEER_ADMIN_TICKETS:
            return {
                ...state,
                tickets_eng: action.payload
            }    
        case SUPERVISOR_TICKET_LIST:
            return {
                ...state,
                supervisorTicketList: action.payload
            }
            case TICKET_BY_COMPANY:
                return {
                    ...state,
                    ticketByComp: action.payload
                }  
        case COMPANY_LIST:
            return {
                ...state,
                companies: action.payload
            }
        case FEEDBACK_LIST:
            return {
                ...state,
                feedbacks: action.payload
            }                           
        case REQUESTER_TICKET_LIST:
            return {
                ...state,
                requesterTicketList: action.payload
            }

        case ADD_TICKET: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload,
            isLoad: action.isLoad
        }
        case TICKET_UPDATE_BY_ENG: return {
            ...state,
            isLoad: action.isLoad
        }
        case TICKET_UPDATE_BY_SUP: return {
            ...state,
            isLoad: action.isLoad
        }
        case PRIORITY_LIST: return {
            ...state,
            priority: action.payload
        };
        case GROUPS_LIST: return {
            ...state,
            groups: action.payload
        };
        case TICKET_COUNTS: return {
            ...state,
            ticketCounts: action.payload
        };
        case BAR_CHART_DATA: return {
            ...state,
            barChartData: action.payload
        };
        case BAR_CHART_DATA_ENG: return {
            ...state,
            barChartDataEng: action.payload
        };
        case TOP_CUSTOMERS: return {
            ...state,
            topCustomers: action.payload
        };
        case TOP_CATEGORIES: return {
            ...state,
            topCategories: action.payload
        };
        case TICKET_COUNTS_ENG: return {
            ...state,
            ticket_count_eng: action.payload
        };
        case TICKET_BY_PERIORITY_ENG: return {
            ...state,
            ticketByPriorityEng: action.payload
        };
        case STATUS_LIST: return {
            ...state,
            status: action.payload,
            supervisorStatus: action.supervisorStatus,
            engineerStatus: action.engineerStatus,
            pausedStatus: action.pausedStatus,
            resumeStatus: action.resumeStatus,
            assignedStatus: action.assignedStatus
        };
        case TYPE_LIST: return {
            ...state,
            types: action.payload
        };
        case PAUSED_TICKETS: return {
            ...state,
            pausedData: action.payload
        };
        case SUB_CAT_LIST: return {
            ...state,
            subCategory: action.payload
        };
        case ENGINEERS_LIST: return {
            ...state,
            engineers: action.payload
        };
        case EXISTING_TICKET: return {
            ...state,
            existing_ticket: action.payload,
            message: action.message
        };
        case TICKET_GET: return {
            ...state,
            ticket: action.payload
        };
        case SEND_REPLY: return {
            ...state,
            isLoad: action.payload
        };
        case TICKET_DISCUSSION_LIST_BY_TICKET: return {
            ...state,
            ticket_discussion_list: action.payload
        };
        case FILTERED_TICKETS: return {
            ...state,
            filteredOrders: action.payload,
            emptyFilterOrder: action.emptyFilterOrder
        }
        case TIMER_STATUS: return {
            ...state,
            timerStatus: action.payload == false ? false : true
        }
        default:
            return state;
    }
};
export default TicketReducer;