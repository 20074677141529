import React, { Component } from 'react';
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import Topbar from './topbar/topbar'
import Sidebar from './sidebar/sidebar'
import Footer from './footer/footer'
import ReactStars from "react-rating-stars-component";
import { secretKey } from './../secret'
import { Link, Redirect } from 'react-router-dom';
import config from './config/setting'
import { logoutUser } from './../redux/actions/user';
import { sendFeedback } from './../redux/actions/ticket';

class SendFeedBack extends Component<{ match: any, existing_ticket: any, message: any, sendFeedback: (id: any, data: any) => {}, logoutUser: () => {} }, { feedback: any, rating: any, [x: number]: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            feedback: "",
            rating: "",
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        // this.props.roleList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    ratingChanged = (newRating: any) => {
        console.log("rating value:", newRating);
        this.setState({ rating: newRating });
    };


    isCatReady = () => {
        const { feedback, rating } = this.state
        return (feedback !== "" && rating !== "");
    }

    handleSaveBtnClick = (event: any) => {
        let { feedback, rating } = this.state;
        let id = this.props.match.params.id;
        let data = {
            feedback: feedback,
            rating: rating,
            status: 1,
            isActive: 1
        }

        console.log("data", data);
        this.props.sendFeedback(id, data);
    }
    render() {
        // if (sessionStorage.token) {
        //     jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        //         if (err) {
        //             this.props.logoutUser();
        //         }
        //     });
        // } else {
        //     return <Redirect to="/" />
        // }
        console.log("existing", this.props.existing_ticket);

        const { existing_ticket, message } = this.props;

        return (
            <div className="page">
                {/* <Topbar /> */}
                <div className="page-content d-flex align-items-stretch">
                    {/* <Sidebar /> */}
                    <div className="content-inner" style={{width:"100%"}}>
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Share Feedback</h4>
                                </div>
                            </div>
                        </header>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            {(existing_ticket || message) ?
                                                <div className="card-body text-center">
                                                    <h4>{
                                                        existing_ticket ? existing_ticket : message
                                                    }</h4>
                                                </div>
                                                :
                                                <div className="card-body pt-xl-0">

                                                    <div className="form-horizontal p-xl-4">

                                                        <div className="row form-group">
                                                            <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Rate our Services<span className="text-danger">*</span></label>
                                                            <div className="col-md-9 col-sm-9">
                                                                <ReactStars
                                                                    count={5}
                                                                    onChange={this.ratingChanged}
                                                                    size={40}
                                                                    activeColor="#ffd700"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row form-group">
                                                            <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Comments<span className="text-danger">*</span></label>
                                                            <div className="col-md-9 col-sm-9">
                                                                <textarea id="feedback" name="feedback" required data-msg="Please enter Feed" className="input-material" placeholder="Place your feedback here..." onChange={this.handleInputChange} ></textarea>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="form-group float-right mt-3">
                                                        <button className='btn btn-primary' disabled={!this.isCatReady()} onClick={this.handleSaveBtnClick}>Send Feedback</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    console.log("state", state);
    return {
        existing_ticket: state.ticket.existing_ticket,
        message: state.ticket.message

    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {

        sendFeedback: function (id: any, data: any) {
            dispatch(sendFeedback(id, data))
        },
        logoutUser: function () {
            dispatch(logoutUser);
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SendFeedBack);