import React, { Component } from 'react';
import {  EditZoneProps,EditZoneState } from '../../interfaces/zone'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';
import { groupList } from '../../redux/actions/user';
import config from '../config/setting'
import Select from 'react-select'
import { getGroupByZones, getZone, updateZone } from '../../redux/actions/zone';

class EditZone extends Component<EditZoneProps, EditZoneState> {
    id: any;
    constructor(props: any) {
        super(props);
        this.id = this.props.match.params.id; 
        this.state = {
            name: "",
            description: "",
            parent: "",
            child: [],
            status: "1",
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.groupList();
        this.props.getZone(this.id);
        this.props.getGroupByZones(this.id);
    }
    componentWillReceiveProps(nextProps:any){
        console.log("nextProps",nextProps.zoneChild);
        let child:any = [];
        if(nextProps && nextProps.zoneChild){
            nextProps.zoneChild.forEach((element:any) => {
                child.push(element.child.id);    
            });
            
        }
      this.setState({
        // role: (nextProps && nextProps.user.role) && nextProps.user.role.id,
        name: nextProps.zone.name,
        child:child,
        parent: (nextProps && nextProps.zone && nextProps.zone.zoneGroup) && nextProps.zone.zoneGroup[0].parent.id,
        description: nextProps.zone.description,
        status: nextProps.zone.status,
        });
    }
    handleItemsInputChange = (e: any) => {
        console.log("e",e);
        let array:any=[];
        e &&
        e.map((obj:any)=>{
             array.push(obj.value)
        })
        this.setState({child:array})
     };
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isZoneReady = () => {
        const { name, child, status } = this.state
        return (name !== "" && child.length > 0  && status !== "");
    }
    
    handleSaveBtnClick = (event: any) => {
        let { name, parent, child, description, status} = this.state;
        
        let data = {
            name: name,
            parent: parent,
            description: description,
            child: child,
            status: status,
        }
        
        console.log("data",data);
        this.props.updateZone(this.id, data);
    }
    render() {
        // if (sessionStorage.token) {
        //     jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        //         if (err) {
        //             this.props.logoutUser();
        //         }
        //     });
        // } else {
        //     return <Redirect to="/" />
        // }
        let msg;
        let messcolor;
        // const { isInserted, message, brands, channels, types } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }
        let {groups} = this.props;
        let {zone} = this.props;
        let {zoneChild} = this.props;

        // console.log("zoneChild",zoneChild);

        let groupData:any = [];
        groups.forEach((element:any) => {
            var store = {'label': element.name, value:element.id};
            groupData.push(store);
        });

        let selectedChild:any = [];
        
        if(zoneChild != undefined){
            for (let i = 0; i < zoneChild.length; i++) {
                const element = zoneChild[i].child;
                var group = { value:element.id,label: element.name};
                selectedChild.push(group);
            }
        }
        // this.setState({child:child})
        // console.log("selectedChild",selectedChild);

        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Zones Management</h4>
                                </div>
                            </div>
                        </header>
                            {/*  Breadcrumb */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/zones" className="text-primary">Zones</Link></li>
                                    <li className="breadcrumb-item active">Edit Zone</li>
                                </ul>
                            </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                        <div className="card-body pt-xl-0">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="form-horizontal p-xl-4">

                                                <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="name" type="text" name="name" defaultValue={zone.name} required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Parent Group<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            { (zone && zone.zoneGroup) &&
                                                                <input id="name" type="text" name="parent" disabled defaultValue={zone.zoneGroup[0].parent.name} required data-msg="Please enter Store Name" className="input-material bg-white"/>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Child Groups<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                        { selectedChild.length > 0 && 
                                                            <Select
                                                                defaultValue={selectedChild}
                                                                isMulti
                                                                closeMenuOnSelect = {false}
                                                                name="child"
                                                                options={groupData}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={e => this.handleItemsInputChange(e)}
                                                            />
                                                        }
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Description<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <textarea id="description" name="description" defaultValue={zone.description} required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="status" defaultValue={zone.status} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                            <option {...zone.status == config.ACTIVE && { selected: true }}  value={config.ACTIVE}>Active</option>
                                                                <option {...zone.status == config.INACTIVE && { selected: true }} value={config.INACTIVE}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    
                                                </div>

                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isZoneReady()} onClick={this.handleSaveBtnClick}>Update Zone</button>
                                                </div>
                                            </div>
                                        
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    console.log("state",state);
    return {
        groups: state.group.groupList,
        zone: state.zone.getZone,
        zoneChild:state.zone.getGroupByZones,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
      
        groupList: function () {
            dispatch(groupList())
        },
        updateZone: function (id: any, data: any) {
            dispatch(updateZone(id, data));
        },
        getZone: function (id:any) {
            dispatch(getZone(id));
        },
        getGroupByZones: function (id:any) {
            dispatch(getGroupByZones(id))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditZone);