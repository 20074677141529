import { CAT_LIST, GET_CAT, GET_SUBCAT_BY_CAT_ID } from './types';
import Api from '../../components/Api';

export const categoryList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/category/categories', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CAT_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addCategory = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/category/add_category', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/categories";
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editCategory = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/category/edit_cat/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/categories";
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getCategory = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/category/category/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_CAT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getSubCategoryByCatID = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/category/getSubCategoryByCatId/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_SUBCAT_BY_CAT_ID,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const delCategory = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.delete(`/category/del_category/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/categories";
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}