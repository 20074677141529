import React, { Component } from 'react';
import { EditSubCategoryProps, EditSubCategoryState } from '../../interfaces/category'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import CheckChanges from '../confirmOnLeave';
import { Link, Redirect } from 'react-router-dom';
import config from '../config/setting'
import Select from 'react-select'
import { editSubCategory, categoryList, getSubCategory } from '../../redux/actions/sub-category';
import { logoutUser } from './../../redux/actions/user';

class EditSubCategory extends Component<EditSubCategoryProps, EditSubCategoryState> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            description: "",
            category: "",
            status: "1",
            //isActive: "1"
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getSubCategory(id);
        this.props.categoryList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        console.log("nextProps", nextProps.subCategory);
        this.setState({
            name: nextProps.subCategory.name,
            description: nextProps.subCategory.description,
            category: nextProps.subCategory.cat_id,
            status: nextProps.subCategory.status,
        });
    }

    handleCategoryInputChange = (e: any) => {
        console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ category: e.value });
        } else {
            this.setState({ category: "" });
        }
    };

    isCatReady = () => {
        const { name, description, category } = this.state
        return (name !== "" && description !== "" && category !== "");
    }

    handleSaveBtnClick = (event: any) => {
        let { name, description, category, status } = this.state;
        let id = this.props.match.params.id;

        let data = {
            name: name,
            description: description,
            cat_id: category,
            status: status,
            isActive: config.ACTIVE
        }

        console.log("data", data);
        this.props.editSubCategory(id, data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        // const { isInserted, message, brands, channels, types } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }
        const { catList, subCategory } = this.props;
        console.log("this.props", catList);

        //Category Data
        let catData: any = [];
        let selectedCategory: any = [];
        if (catList.length > 0) {
            catList.forEach((element: any) => {
                var type = { value: element.id, label: element.cat_name };
                catData.push(type);

                if(this.state.category && this.state.category == element.id){
                    selectedCategory.push(type);
                }         
            });
        }

        return (
            <div className="page">
                <CheckChanges path="/edit-subcategory" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Sub Category Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/subCategories" className="text-primary">Sub Category</Link></li>
                                <li className="breadcrumb-item active">Edit Sub Category</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body pt-xl-0">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="form-horizontal p-xl-4">

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="name" type="text" name="name" defaultValue={subCategory.name} required data-msg="Please enter Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Description<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <textarea id="description" name="description" defaultValue={subCategory.description} required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Category<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                        {catList.length > 0 &&
                                                                    <Select
                                                                        value={selectedCategory}
                                                                        closeMenuOnSelect={true}
                                                                        name="category"
                                                                        //isMulti
                                                                        //isClearable
                                                                        options={catData}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        onChange={(e: any) => this.handleCategoryInputChange(e)}
                                                                    />
                                                                }
                                                            {/* <select name="category" className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option value="">Select Category</option>
                                                                {catList &&
                                                                    catList.map((c: any, index: any) => (
                                                                        <option key={index} value={c.id} {...subCategory.id === c.id && { selected: true }}>{c.cat_name}</option>
                                                                    ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="status" className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option value={config.ACTIVE}>Active</option>
                                                                <option value={config.INACTIVE}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isCatReady()} onClick={this.handleSaveBtnClick}>Edit Sub Category</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    console.log("state", state);
    return {
        catList: state.subCategory.catList,
        subCategory: state.subCategory.subCategory
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {

        editSubCategory: function (id: any, data: any) {
            dispatch(editSubCategory(id, data))
        },
        categoryList: function () {
            dispatch(categoryList())
        },
        getSubCategory: function (id: any) {
            dispatch(getSubCategory(id))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditSubCategory);