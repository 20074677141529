import React, { Component } from 'react';
import {  AddZoneProps,AddZoneState } from '../../interfaces/zone'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';
import { addUser, groupList, roleList } from '../../redux/actions/user';
import config from '../config/setting'
import Select from 'react-select'
import { addZone } from '../../redux/actions/zone';

class AddZone extends Component<AddZoneProps, AddZoneState> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            description: "",
            parent: "",
            child: [],
            status: "1",
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        // this.props.brandsList();
        // this.props.channelsList();
        // this.props.storeTypeList();
        // this.props.areasList();
        // this.props.countryList();
        this.props.groupList();
        // this.props.roleList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleItemsInputChange = (e: any) => {
        console.log("e",e);
        let array:any=[];
        e &&
        e.map((obj:any)=>{
             array.push(obj.value)
        })
        this.setState({child:array})
     };
    
    isZoneReady = () => {
        const { name, parent, child, status } = this.state
        return (name !== "" && parent !== "" && child.length > 0  && status !== "");
    }
    
    handleSaveBtnClick = (event: any) => {
        let { name, parent, child, description, status} = this.state;
        
        let data = {
            name: name,
            description: description,
            parent: parent,
            child: child,
            status: status,
            isActive: config.ACTIVE
        }
        
        console.log("data",data);
        this.props.addZone(data);
    }
    render() {
        // if (sessionStorage.token) {
        //     jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        //         if (err) {
        //             this.props.logoutUser();
        //         }
        //     });
        // } else {
        //     return <Redirect to="/" />
        // }
        let msg;
        let messcolor;
        // const { isInserted, message, brands, channels, types } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }

        let {groups} = this.props;
        // const stores = this.props.stores;
        let groupData:any = [];
        groups.forEach((element:any) => {
            var store = {'label': element.name, value:element.id};
            groupData.push(store);
        });

        console.log("this.props",this.props);

        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Zones Management</h4>
                                </div>
                            </div>
                        </header>
                            {/*  Breadcrumb */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/zones" className="text-primary">Zones</Link></li>
                                    <li className="breadcrumb-item active">Add Zone</li>
                                </ul>
                            </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body pt-xl-0">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="form-horizontal p-xl-4">

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="name" type="text" name="name" required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Parent Group<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="parent" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                <option value="">Select Parent Group</option>
                                                                {groups &&
                                                                        groups.map((group: any, index: any) => (
                                                                            <option key={index} value={group.id}>{group.name}</option>
                                                                        ))
                                                                    }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Child Groups<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <Select
                                                                // defaultValue={[colourOptions[2], colourOptions[3]]}
                                                                isMulti
                                                                closeMenuOnSelect = {false}
                                                                name="child"
                                                                options={groupData}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={e => this.handleItemsInputChange(e)}
                                                            />
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Description<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <textarea id="description" name="description" required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="status" className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option value={config.ACTIVE}>Active</option>
                                                                <option value={config.INACTIVE}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                                                                        
                                                </div>

                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isZoneReady()} onClick={this.handleSaveBtnClick}>Create Zone</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    console.log("state",state);
    return {
        groups: state.group.groupList,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
      
        groupList: function () {
            dispatch(groupList())
        },
        roleList: function () {
            dispatch(roleList())
        },
        addZone: function (data: any) {
            dispatch(addZone(data));
        }
        // storesList: function () {
        //     dispatch(storesList())
        // },
        // addGroup: function (data: any) {
        //     dispatch(addGroupStore(data));
        // }
        // deleteStore: function (id:any) {
        //     dispatch(deleteStore(id))
        // }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddZone);