import { TICKET_GET, FEEDBACK_LIST, RE_ASSIGN_DATA, TICKET_BY_PERIORITY_ENG, BAR_CHART_DATA_ENG, TOP_CATEGORIES, TOP_CUSTOMERS, BAR_CHART_DATA, FEEDBACK_ENGINEERS_ADMIN, ENGINEER_ADMIN_TICKETS, TICKET_COUNTS_ENG, EXISTING_TICKET, TICKET_BY_COMPANY, SUPERVISOR_TICKET_LIST, TICKET_COUNTS, ENGINEER_TICKET_LIST, REQUESTER_TICKET_LIST, TICKET_LIST, SUB_CAT_LIST, ENGINEERS_LIST, PRIORITY_LIST, STATUS_LIST, TYPE_LIST, GROUPS_LIST, COMPANY_LIST, TICKET_DISCUSSION_LIST_BY_TICKET, FILTERED_TICKETS, TIMER_STATUS, ADD_TICKET, TICKET_UPDATE_BY_SUP, TICKET_UPDATE_BY_ENG, SEND_REPLY, PAUSED_TICKETS} from './types'
import Api from '../../components/Api'
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

let timer: any;
export const launchTimer = (history: any) => {
    return function (dispatch: any) {
        console.log("launch timer");
        dispatch({
            type: TIMER_STATUS,
            payload: true
        })
        timer = setInterval(() => {

            //history.go(0)
            const roleId = sessionStorage.getItem("role");
            if (roleId === "5") {
                dispatch(assigneeTickets());
            } else if (roleId === "3") {
                dispatch(requesterTickets());
            } else if (roleId === "4") {
                dispatch(supervisorTickets());
            }
            else {
                dispatch(ticketListTimer());
            }
        }, 20000);
    }
}

export const stopTimer = () => {
    console.log("stop Timer");
    
    return function (dispatch: any) {
        dispatch({
            type: TIMER_STATUS,
            payload: false
        });
        clearInterval(timer)
    }
}

export const ticketList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var user: any = jwt_decode(token);
        Api.get(`/tickets/getAll/${user.company}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("sessadsd",response);
                if(response.data.success){
                    sessionStorage.setItem('ticketLength', response.data.successResponse.length);
                    let recieved = 0;
                    let assigned = 0;
                    let resolved = 0;
                    let closed = 0;
                    response.data.successResponse.map((obj: any) => {
                        if (obj.ticket_status_code === 1) {
                            return recieved += 1;
                        } else if (obj.ticket_status_code === 2) {
                            return assigned += 1;
                        } else if (obj.ticket_status_code === 3) {
                            return resolved += 1;
                        } else if (obj.ticket_status_code === 8) {
                            return closed += 1;
                        }
                    })
                    dispatch({
                        type: FILTERED_TICKETS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: TICKET_LIST,
                        payload: response.data.successResponse,
                        ticketsReceived: recieved,
                        ticketsAssigned: assigned,
                        ticketsResolved: resolved,
                        ticketsClosed: closed,
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const ticketListTimer = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/tickets/getAll', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("ticketListTimer",response);
                if(response.data.success){
                    let recieved = 0;
                    let assigned = 0;
                    let resolved = 0;
                    let closed = 0;
                    response.data.successResponse.map((obj: any) => {
                        if (obj.ticket_status_code === 1) {
                            return recieved += 1;
                        } else if (obj.ticket_status_code === 2) {
                            return assigned += 1;
                        } else if (obj.ticket_status_code === 3) {
                            return resolved += 1;
                        } else if (obj.ticket_status_code === 8) {
                            return closed += 1;
                        }
                    })
                    dispatch({
                        type: FILTERED_TICKETS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: TICKET_LIST,
                        payload: response.data.successResponse,
                        ticketsReceived: recieved,
                        ticketsAssigned: assigned,
                        ticketsResolved: resolved,
                        ticketsClosed: closed,
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const assigneeTickets = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var user: any = jwt_decode(token);
        let data={
            userId: user.userId,
            company: user.company
        }
        console.log("assTick in action", data);
        Api.post('/tickets/assignee_tickets', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("ticket data in action:", response.data.successResponse)
                if(response.data.success){
                    sessionStorage.setItem('ticketLength', response.data.successResponse.length);
                    let recieved = 0;
                    let assigned = 0;
                    let resolved = 0;
                    let closed = 0;
                    response.data.successResponse.map((obj: any) => {
                        if (obj.ticket_status_code === 1) {
                            return recieved += 1;
                        } else if (obj.ticket_status_code === 2) {
                            return assigned += 1;
                        } else if (obj.ticket_status_code === 3) {
                            return resolved += 1;
                        } else if (obj.ticket_status_code === 8) {
                            return closed += 1;
                        }
                    })
                    console.log("ticket data in action:", response.data.successResponse)

                    dispatch({
                        type: TICKET_LIST,
                        payload: response.data.successResponse,
                        ticketsReceived: recieved,
                        ticketsAssigned: assigned,
                        ticketsResolved: resolved,
                        ticketsClosed: closed,
                    })
            }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const ticketListEng = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
       Api.get(`/tickets/tickets_assignee_performace/${id}`,  {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                //console.log("ticket data in action:", response.data.successResponse)
                if(response.data.success){
                    dispatch({
                        type: ENGINEER_ADMIN_TICKETS,
                        payload: response.data.successResponse
                    })
            }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const supervisorTickets = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var user: any = jwt_decode(token);
        Api.get(`/tickets/tickets_for_supervisor/${user.company}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    sessionStorage.setItem('ticketLength', response.data.successResponse.length);
                console.log("ticket data in action:", response.data.successResponse)
                let recieved = 0;
                    let assigned = 0;
                    let resolved = 0;
                    let closed = 0;
                    response.data.successResponse.map((obj: any) => {
                        if (obj.ticket_status_code === 1) {
                            return recieved += 1;
                        } else if (obj.ticket_status_code === 2) {
                            return assigned += 1;
                        } else if (obj.ticket_status_code === 3) {
                            return resolved += 1;
                        } else if (obj.ticket_status_code === 8) {
                            return closed += 1;
                        }
                    })
                    console.log("ticket data in action:", response.data.successResponse)

                dispatch({
                    type: TICKET_LIST,
                    payload: response.data.successResponse,
                    ticketsReceived: recieved,
                    ticketsAssigned: assigned,
                    ticketsResolved: resolved,
                    ticketsClosed: closed,
                })
            }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const requesterTickets = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var user: any = jwt_decode(token);
        let data={
            userId: user.userId,
            company: user.company
        }
        Api.post('/tickets/requester_tickets', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if(response.data.success){
                    sessionStorage.setItem('ticketLength', response.data.successResponse.length);
                    let recieved = 0;
                    let assigned = 0;
                    let resolved = 0;
                    let closed = 0;
                    response.data.successResponse.map((obj: any) => {
                        if (obj.ticket_status_code === 1) {
                            return recieved += 1;
                        } else if (obj.ticket_status_code === 2) {
                            return assigned += 1;
                        } else if (obj.ticket_status_code === 3) {
                            return resolved += 1;
                        } else if (obj.ticket_status_code === 8) {
                            return closed += 1;
                        }
                    })
                console.log("ticket data in action!!!@@@@!:", response.data.successResponse)

                dispatch({
                    type: TICKET_LIST,
                    payload: response.data.successResponse,
                    ticketsReceived: recieved,
                    ticketsAssigned: assigned,
                    ticketsResolved: resolved,
                    ticketsClosed: closed,
                })
            }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const ReAssignData = (id:any) => {
    console.log("triggerredskc ksdcksdc")
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        // var user: any = jwt_decode(token);
        Api.get(`/tickets/reAssignData/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if(response.data.success){
                    console.log("response reassign", response.data.successResponse)
                    dispatch({
                        type: RE_ASSIGN_DATA,
                        payload: response.data.successResponse,
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const addTicket = (formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/tickets/add_ticket', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            dispatch({
                type: ADD_TICKET,
                isLoad: true
            })
            if (response.data.success) {
                window.location.href = "/tickets";
            }
        }).catch(err => {
            if (err.response) {
                toast.error(err, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                console.log("sdvsdsdv", err.response.data.message)

            } else {
                alert(err.message)
            }
        });
    }
}
export const priorityList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/tickets/get_priority', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: PRIORITY_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const statusList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/tickets/get_status', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let status = response.data.successResponse;
                    let adminStatus = [status[1],status[2], status[3], status[6]];
                    let supervisorStatus = [status[1]];
                    let engineerStatus = [status[2], status[3]];
                    let assignedStatus = [status[1],status[2],status[3] ]
                    let pausedStatus = [status[3]];
                    let resumeStatus = [status[6]]          
                    console.log("supervisor status",supervisorStatus);
                    dispatch({
                        type: STATUS_LIST,
                        payload: adminStatus,
                        supervisorStatus: supervisorStatus,
                        engineerStatus: engineerStatus,
                        pausedStatus: pausedStatus,
                        resumeStatus: resumeStatus,
                        assignedStatus: assignedStatus
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let user: any = jwt_decode(token);
        console.log("user",user);
        let data={
            role: user.role,
            company: user.company
        }
        console.log("data",data);
        // Api.get(`/tickets/groups_company/${user.company}`, {
        
        Api.post(`/tickets/groups_company`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GROUPS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const typeList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/tickets/get_category', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("CAT: ", response.data.successResponse);
                    dispatch({
                        type: TYPE_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const feedbackList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/tickets/all-feedback', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("feedback: ", response.data.successResponse);
                    dispatch({
                        type: FEEDBACK_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const feedbackListEng = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/tickets/all-feedback-engineers/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("feedback: ", response.data.successResponse);
                    dispatch({
                        type: FEEDBACK_ENGINEERS_ADMIN,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const subCategoryList = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/tickets/sub-categories/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("data", response.data.successResponse)
                    dispatch({
                        type: SUB_CAT_LIST,
                        payload: response.data.successResponse
                    })

                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const engineersList = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let user: any = jwt_decode(token);
        console.log("user",user);
        let data={
            role: user.role,
            id: id
        }
        console.log("data",data);
        
        Api.post(`/tickets/get_engineers`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ENGINEERS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                dispatch({
                    type: ENGINEERS_LIST,
                    payload: []
                })
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getTicket = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/tickets/get/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                dispatch(engineersList(response.data.successResponse.group))
                dispatch(subCategoryList(response.data.successResponse.cat_id))
                dispatch({
                    type: TICKET_GET,
                    payload: response.data.successResponse
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const pausedTickets = () => {  
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        const tickets = dispatch(ticketList());
        console.log("tickets", tickets);
        Api.get(`/tickets/pausedTickets/1`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                dispatch({
                    type: PAUSED_TICKETS,
                    payload: response.data.successResponse
            })
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateTicketBySupervisor = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/tickets/update_by_supervisor/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                dispatch({
                    type: TICKET_UPDATE_BY_SUP,
                    isLoad: true
                })
                console.log("response", response);
                window.location.href = "/tickets";

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                    toast.error(err, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const reassignTicketByRequester = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put('/tickets/reassign_by_requester', data, {
            headers: {
                'Authorization': 'Bearer ' + token,
                }
        })
            .then((response) => {
                console.log("response", response);
                window.location.href = "/tickets";
                //toast.error(response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                    
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                    toast.error(err, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateTicket = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/tickets/updateTicket/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                dispatch({
                    type: TICKET_UPDATE_BY_ENG,
                    isLoad: true
                })
                console.log("response", response);
                window.location.href = "/tickets";

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                    toast.error(err, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const sendReply = (formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/tickets/add_ticket_discussion/`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
        .then((response) => {
                dispatch({
                    type: SEND_REPLY,
                    isLoad: true
                })
                dispatch(getTicket(formData.get("ticket_id")));
                dispatch(getAllTicketDiscussionByTicket(formData.get("ticket_id")));
            }).catch(err => {
                if (err.response) {

                    console.log(err.response.data.message)
                    toast.error(err, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deleteTicket = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.delete(`/tickets/delete/${id}`)
            // {
            //     headers: { 'Authorization': 'Bearer ' + token }
            // })
            .then((response) => {
                console.log("response", response);
                window.location.href = "/tickets";
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const sendFeedback = (id: any, data: any) => async (dispatch: any) => {
    try {
        let token: any = sessionStorage.getItem('token');
        const response = await Api.post(`/tickets/send-feedback/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token, }
        });
        if (response.data.success) {
            dispatch({ type: EXISTING_TICKET, message: response.data.message })
        } else if (response.data.success == false) {
            dispatch({ type: EXISTING_TICKET, payload: response.data.message })
        }
    } catch (e) {
        console.log(e);
        // toast.error(e);
    }

    //dispatch({ type: FETCH_CATEGORIES, payload: response.data })
}

export const ticketsByCompany = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/tickets/tickets_by_company/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: TICKET_BY_COMPANY,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const companyList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/tickets/companies', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: COMPANY_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getAllTicketDiscussionByTicket = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/tickets/getAllTicketDiscussionByTicket/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("TICKET_DISCUSSION_LIST_BY_TICKET",response.data.successResponse);
                
                if(response.data.success == true){
                    dispatch({
                        type: TICKET_DISCUSSION_LIST_BY_TICKET,
                        payload: response.data.successResponse
                    })
                }else{
                    dispatch({
                        type: TICKET_DISCUSSION_LIST_BY_TICKET,
                        payload: []
                    })
                }

            }).catch(err => {
                if (err.response) {
                    console.log("ERROR: ",err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const assigneeTicketsTimer = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var user: any = jwt_decode(token);
        let data={
            userId: user.userId,
            company: user.company
        }
        console.log("assTick in action", data);
        Api.post('/tickets/assignee_tickets', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("ticket data in action:", response.data.successResponse)
                dispatch({
                    type: ENGINEER_TICKET_LIST,
                    payload: response.data.successResponse
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const supervisorTicketsTimer = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var user: any = jwt_decode(token);
        Api.get(`/tickets/tickets_for_supervisor/${user.company}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("ticket data in action:", response.data.successResponse)
                dispatch({
                    type: SUPERVISOR_TICKET_LIST,
                    payload: response.data.successResponse
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const requesterTicketsTimer = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var user: any = jwt_decode(token);
        let data={
            userId: user.userId,
            company: user.company
        }
        Api.post('/tickets/requester_tickets', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("ticket data in action:", response.data.successResponse)
                dispatch({
                    type: REQUESTER_TICKET_LIST,
                    payload: response.data.successResponse
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const filterOrdersList = (filteredArr: any) => {
    return function (dispatch: any) {
        dispatch({
            type: FILTERED_TICKETS,
            payload: filteredArr,
            emptyFilterOrder: filteredArr.length > 0 ? false : true
        })
    }
}

// For Dashborad charts

export const ticketCounts = (days: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var user: any = jwt_decode(token);
        Api.post(`/tickets/ticket_counts/${user.company}`, days, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    //console.log("CAT: ", response.data.successResponse);
                    dispatch({
                        type: TICKET_COUNTS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const ticketCountsEng = (eng_id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        //var user: any = jwt_decode(token);
        Api.get(`/tickets/ticket_counts_eng/${eng_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    //console.log("COUNT ENG: ", response.data.successResponse[0]);
                    dispatch({
                        type: TICKET_COUNTS_ENG,
                        payload: response.data.successResponse[0]
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const lastSevenDaysDataBarChart = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var user: any = jwt_decode(token);
        Api.get('/tickets/last_seven_days_barchart', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    //console.log("CAT: ", response.data.successResponse);
                    dispatch({
                        type: BAR_CHART_DATA,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const lastSevenDaysDataBarChartEng = (eng_id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var user: any = jwt_decode(token);
        Api.get(`/tickets/last_seven_days_barchart_eng/${eng_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    //console.log("CAT: ", response.data.successResponse);
                    dispatch({
                        type: BAR_CHART_DATA_ENG,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const TopCustomers = (date: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');

        //var user: any = jwt_decode(token);
        Api.post('/tickets/TopCustomers', date, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    //console.log("COUNT ENG: ", response.data.successResponse[0]);
                    dispatch({
                        type: TOP_CUSTOMERS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const TopCategories = (date: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');

        //var user: any = jwt_decode(token);
        Api.post('/tickets/TopCategories', date, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    //console.log("COUNT ENG: ", response.data.successResponse[0]);
                    dispatch({
                        type: TOP_CATEGORIES,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const TicketByPeriorityEng = (eng_id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/tickets/TicketByPeriorityEng/${eng_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("response fo rrperioritu", response.data.successResponse);
                
                if (response.data.success) {
                    dispatch({
                        type: TICKET_BY_PERIORITY_ENG,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error("No data to report", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: TICKET_BY_PERIORITY_ENG,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}