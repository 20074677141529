import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Chart from 'chart.js'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';


function viewFormatter(cell: any, row: any) {
    return (
        <div>
            <a href="#" title="View Learner" className="btn btn-outline-primary"><i className="fa fa-eye"></i></a>
        </div>
    );
}

class ActionFormatter extends Component<{ row: any, blockunblock: (id: any, is_active: any) => {} }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.blockunblock(id, is_active);
    };
    render() {
        const { row } = this.props
        return (
            <div>
                <Link to="#" title="Delete Message" data-toggle="modal" data-target={`#deleteMessage${row.store_id}`} className="btn btn-outline-danger mx-2"><i className="fa fa-trash"></i></Link>
                <Link title="View Message" className="btn btn-outline-primary mx-2" to={`/view-message`}><i className="fa fa-eye"></i></Link>
                {/* <Link title="Delete Message" className="btn btn-outline-primary" to={`/edit-store`}><i className="fa fa-trash"></i></Link> */}

                {/* <Link title="Edit Store" className="btn btn-outline-primary" to={`/edit-store/${row.store_id}`}><i className="fa fa-edit"></i></Link> */}
                {/* <!-- B/U Modal--> */}
                <div id={`deleteMessage${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "Activate" : "Deactivate"} Store</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to delete this message?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button type="button" data-dismiss="modal" className="btn btn-primary">Confirm</button>
                                {/* <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function actionFormatter(cell: any, row: any, props: any) {
    return (
        // <ActionFormatter row={row} blockunblock={props.blockunblockstore} />
        <ActionFormatter row={row}/>
    );
}

export default class Store extends Component<{},{showDiv:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {showDiv:''}
    }
    
    render() {
        let roleId: any = ""
       
        const storeData: any =[
            {
            'id': '1',
            'subject': 'Message from SimpleX',
            'body': 'SimpleX message body',
            'date': 'October 29, 2020'
        },{
            'id': '2',
            'subject': 'Message from SimpleX',
            'body': 'SimpleX message body',
            'date': 'October 30, 2020'
        },{
            'id': '3',
            'subject': 'Message from SimpleX',
            'body': 'SimpleX message body',
            'date': 'October 31, 2020'
        },
    ]

        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-message" /> },
            noDataText: 'Store Not Found'
        };

        return (
          <div className="page">
            <Topbar />
            <div className="page-content d-flex align-items-stretch">
              <Sidebar />
              <div className="content-inner">
                {/* <!-- Page Header--> */}
                <header className="page-header py-0">
                  <div className="container-fluid">
                    <div className="d-flex justify-content-between py-3">
                      <h4 className="mt-2">Messages Management</h4>
                    </div>
                  </div>
                </header>
                
                   <section className="tables">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="card">
                                    <div className="card-body text-capitalize">
                                        <BootstrapTable  version='4' data={storeData} search={true} pagination={storeData.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='messages.csv' hover>
                                            <TableHeaderColumn dataField='id' csvHeader='Sr. #' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                            <TableHeaderColumn dataField='subject' csvHeader='Name' width='100' columnTitle>Subject</TableHeaderColumn>
                                            <TableHeaderColumn dataField='body' csvHeader='Email' width='100' columnTitle>Body</TableHeaderColumn>
                                            <TableHeaderColumn dataField='date' csvHeader='Phone' width='100' columnTitle>Date</TableHeaderColumn>
                                            <TableHeaderColumn dataField='action' width='70' dataFormat={actionFormatter} export={false}>Actions</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <Footer />
              </div>
            </div>
          </div>
        );
    }
};