import React, { Component, useState } from "react";
import { Calendar, View, DateLocalizer } from "react-big-calendar";
import moment from "moment";

import { momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Topbar from "../topbar/topbar";
import Sidebar from "../sidebar/sidebar";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import { AddEventProps, AddEventState } from "../../interfaces/calendar";

const localizer = momentLocalizer(moment);

// const allViews: View[] = ["agenda", "day", "week", "month"];
const allViews: View[] = ["agenda", "month"];


interface Props {
  localizer: DateLocalizer;
}

class CalendarEvent {
  title: string;
  allDay: boolean;
  start: Date;
  end: Date;
  desc: string;
  resourceId?: string;
  tooltip?: string;

  constructor(
    _title: string,
    _start: Date,
    _endDate: Date,
    _allDay?: boolean,
    _desc?: string,
    _resourceId?: string
  ) {
    this.title = _title;
    this.allDay = _allDay || false;
    this.start = _start;
    this.end = _endDate;
    this.desc = _desc || "";
    this.resourceId = _resourceId;
  }
}

function SelectableCalendar({ localizer }: Props) {
  const [events, setEvents] = useState(([
    { start: moment(), end: moment().add(1, "hours"), title: "test" },
  ] as unknown) as CalendarEvent[]);

  var dateToday = moment().format('YYYY-MM-DD');
  console.log("date",dateToday);
  // date = date.toString();
  const [title, setTitle] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  function updatetitle(event: any) {
    // console.log(event.target.value);
    setTitle(event.target.value);
  }
  function updatestart(event: any) {
    setStart(event.target.value);
  }
  function updateend(event: any) {
    setEnd(event.target.value);
  }
  const handleSelect = ({start, end}: any) => {
    const title = window.prompt("New Event name");
    if (title) {
      let newEvent = {} as CalendarEvent;
      newEvent.start = moment(start).toDate();
      newEvent.end = moment(end).toDate();
      newEvent.title = title;

      // Erroneous code
      // events.push(newEvent);
      // setEvents(events);
      setEvents([...events, newEvent]);
    }
  };
  const AddEvent = (title: any, start: any, end: any) => {
    // const title = window.prompt("New Event name");
    console.log("title: ",title);
    console.log("start: ",start);
    console.log("end: ",end);
    if (title) {
      let newEvent = {} as CalendarEvent;
      newEvent.start = moment(start).toDate();
      newEvent.end = moment(end).toDate();
      newEvent.title = title;

      // Erroneous code
      // events.push(newEvent);
      // setEvents(events);
      setEvents([...events, newEvent]);
    }
  };

  return (
    <div className="page">
      <Topbar />
      <div className="page-content d-flex align-items-stretch">
        <Sidebar />
        <div className="content-inner">
         
          <header className="page-header py-0">
            <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                    <h4 className="mt-2">Events Management</h4>
                    <ul className="mb-0">
                    <button className='btn btn-primary' data-toggle="modal" data-target={`#addEventModal`}>Add New Event</button>
                    </ul>
                </div>
            </div>
        </header>


          <section className="tables">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="card">
                    <div className="card-body text-capitalize">
                      <div></div>
                      <div style={{ height: "500px" }}>
                        <Calendar
                          selectable
                          localizer={localizer}
                          events={events}
                          defaultView="month"
                          views={allViews}
                          defaultDate={new Date()}
                          // onSelectEvent={(event) => alert(event.title)}
                          // onSelectSlot={handleSelect}
                          startAccessor="start"
                          endAccessor="end"
                          titleAccessor="title"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </div>
      <div
        id={`addEventModal`}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        className="modal fade text-left"
      >
        <div role="document" className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 id="exampleModalLabel" className="modal-title">
                Add New Event
              </h4>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                className="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-horizontal">
                    <div className="row form-group">
                      <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">
                        Name<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 col-sm-9">
                        <input
                          id="name"
                          type="text"
                          name="name"
                          required
                          data-msg="Please enter Name"
                          className="input-material"
                          onChange={updatetitle}
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">
                        Start Date<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 col-sm-9">
                        <input
                          id="start"
                          type="date"
                          name="start"
                          required
                          data-msg="Please enter Name"
                          className="input-material"
                          onChange={updatestart}
                          min={dateToday}
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">
                        End Date<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 col-sm-9">
                        <input
                          id="end"
                          type="date"
                          name="end"
                          required
                          data-msg="Please enter Name"
                          className="input-material"
                          onChange={updateend}
                          min={dateToday}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-secondary"
              >
                Close
              </button>
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-primary"
                onClick={() => AddEvent(title, start, end)}
              >
                Add Event
              </button>
              {/* <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Availability() {
  return (
    <div style={{ height: "100vh" }}>
      <SelectableCalendar localizer={localizer} />
    </div>
  );
}

class AddNewEvent extends Component<
  { row: any; blockunblock: (id: any, is_active: any) => {} },
  {}
> {
  constructor(readonly props: any) {
    super(props);
  }
  handleBlockUnblock = (id: any, is_active: any) => {
    this.props.blockunblock(id, is_active);
  };
  render() {
    const { row } = this.props;
    return (
      <div>
        {/* <Link title="Edit Badge" className="btn btn-outline-primary mx-2" to={`/edit-badge`}><i className="fa fa-plus"></i></Link> */}
        {/* <Link title="Delete Message" className="btn btn-outline-primary" to={`/edit-store`}><i className="fa fa-trash"></i></Link> */}

        {/* <Link title="Edit Store" className="btn btn-outline-primary" to={`/edit-store/`}><i className="fa fa-edit"></i></Link> */}
        {/* <!-- B/U Modal--> */}
      </div>
    );
  }
}
