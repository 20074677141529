import Api from '../../components/Api';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TICKET_REPORT, RE_OPENED_TICKET_REPORT, TICKET_BY_CATEGORY_REPORT, TICKET_BY_PERIORITY_REPORT, OPENED_SINCE_DAYS_TICKET_REPORT, OPENED_CLOSED_TICKET_REPORT, RESOLVED_SLA_TICKET_REPORT } from './types';
toast.configure();

export const ticketListReport = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/tickets/ticketReport', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: TICKET_REPORT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error("No data to report", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: TICKET_REPORT,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const ReOpenedTicketReport = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/tickets/ReOpenedticketReport', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: RE_OPENED_TICKET_REPORT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error("No data to report", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: RE_OPENED_TICKET_REPORT,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const ResolvedSLATicketReport = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/tickets/ResolvedTicketSLA', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: RESOLVED_SLA_TICKET_REPORT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error("No data to report", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: RESOLVED_SLA_TICKET_REPORT,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const OpenedClosedTicketReport = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/tickets/OpenedClosedTickets', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: OPENED_CLOSED_TICKET_REPORT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error("No data to report", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: OPENED_CLOSED_TICKET_REPORT,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const OpenedSinceDaysTicketReport = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/tickets/OpenSinceDaysTickets', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: OPENED_SINCE_DAYS_TICKET_REPORT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error("No data to report", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: OPENED_SINCE_DAYS_TICKET_REPORT,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const TicketByPeriorityReport = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/tickets/TicketByPeriority', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: TICKET_BY_PERIORITY_REPORT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error("No data to report", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: TICKET_BY_PERIORITY_REPORT,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const TicketByCategoryReport = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/tickets/TicketByCategory', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: TICKET_BY_CATEGORY_REPORT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error("No data to report", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: TICKET_BY_CATEGORY_REPORT,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const customersReport = (data: any, page: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post(`/reports/customers/${page}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        //type: CUSTOMERS_REPORTS,
                        payload: response.data.successResponse,
                        currentPage: response.data.currentPage,
                        pageCount: response.data.pageCount,
                        numOfRows: response.data.numOfRows,
                        msg: ""
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error("No data to report", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    console.log(err.response.data.message)
                    dispatch({
                        // type: CUSTOMERS_REPORTS,
                        payload: [],
                        currentPage: 0,
                        pageCount: 0,
                        numOfRows: 0,
                        msg: ""
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const pmixReport = (data: any, page: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post(`/reports/pmix_report/${page}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        // type: PMIX_REPORTS,
                        payload: response.data.successResponse,
                        currentPage: response.data.currentPage,
                        pageCount: response.data.pageCount,
                        numOfRows: response.data.numOfRows,
                        msg: ""
                    })
                }
            }).catch(err => {
                if (err.response) {
                    toast.error("No data to report", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        // type: PMIX_REPORTS,
                        payload: [],
                        currentPage: 0,
                        pageCount: 0,
                        numOfRows: 0,
                        msg: ""
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}

