import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import './tickets.css'
import { logoutUser } from './../../redux/actions/user';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { ticketList, companyList, ticketsByCompany, deleteTicket, assigneeTickets, requesterTickets, supervisorTickets, reassignTicketByRequester, launchTimer, filterOrdersList, stopTimer, pausedTickets, ReAssignData } from './../../redux/actions/ticket';
import config from '../config/setting'

function viewFormatter(cell: any, row: any) {
    return (
        <div>
            <a href="#" title="View Learner" className="btn btn-outline-primary"><i className="fa fa-eye"></i></a>
        </div>
    );
}

class ActionFormatter extends Component<{ row: any, data: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.data.deleteTicket(id);
    };
    render() {
        const { row } = this.props;
        return (
            <div>
                {/* <Link to="#" title="Delete Ticket" data-toggle="modal" data-target={`#deleteTicket${row.id}`} className="btn btn-outline-danger mx-2"><i className="fa fa-trash"></i></Link> */}
                <Link to={`/edit-ticket/${row.id}`} title="Edit Ticket" className="btn btn-outline-primary"><i className="fa fa-edit"></i></Link>
                {/* <!-- B/U Modal--> */}
                <div id={`deleteTicket${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Ticket</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to Delete this User Group?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button type="button" data-dismiss="modal" className="btn btn-primary" onClick={() => { this.handleDelete(row.id) }}>Confirm</button>
                                {/* <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function actionFormatter(cell: any, row: any, props: any) {
    return (
        // <ActionFormatter row={row} blockunblock={props.blockunblockstore} />
        <ActionFormatter row={row} data={props} />
    );
}

class LinkFormatter extends Component<{ row: any, data: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {row.ticket_status_code === 8 ?  
                <span title="Edit Ticket" className="btn btn-outline-primary">{row.id}</span>
                :<Link to={`/edit-ticket/${row.id}`} title="Edit Ticket" className="btn btn-outline-primary">{row.id}</Link>
            }
            </div>
        )
    }
}

function linkFormatter(cell: any, row: any, props: any) {
    return (
        <LinkFormatter row={row} data={props} />
    );
}

class ReAssign extends Component<{ row: any, data: any }, {re_assign_reason: any, [x:number]: any}> {

    constructor(readonly props: any) {
        super(props);
        this.state = {
            re_assign_reason: ""
        }
    }

    handleInputChange = (event: { target: { name: any; value: any; }; }) => {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }

    handleSaveBtnClick = (event: any) => {
        let ticketDate = moment().utc(false).format('YYYY-MM-DD HH:mm:ss')
        const { row } = this.props;
        let requester: any = {
            id: sessionStorage.userId,
            email: sessionStorage.email,
        }
        const dataa: any = {
            id: row.id,
            subject: row.subject,
            requester_id: requester,
            ticket_status_code: 2,
            assigned_time: ticketDate,
            resolved_time: "",
            work_note: "",
            closed_time: "",
            cronTime: "",
            resolutionTime: 0,
            isAssign: 1,
            isActive: 1,
            re_assign_reason: this.state.re_assign_reason
        }
        this.props.data.reassignTicketByRequester(dataa);
    }

    render() {
        const { row } = this.props;
        // console.log("Row", row);

        return (
            <div>
                {(row.ticket_status_code === 3 || row.ticket_status_code === 8) ?
                    <div>
                        <button data-toggle="modal" data-target={`#reAssignTicket${row.id}`} className="btn btn-outline-primary" title="ReOpen Ticket">Re Open</button>

                        <div title="Re-Assign Ticket" id={`reAssignTicket${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                            <div role="document" className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 id="exampleModalLabel" className="modal-title">Re-Assign Ticket</h4>
                                        <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure, you want to Re-Assign this Ticket?</p>
                                        <h5>Re-Assign Reason</h5>
                                        <textarea name="re_assign_reason" required placeholder="Please enter Re-assign reason." className="form-control rounded-0 input-material" onChange={this.handleInputChange} rows={6}></textarea>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                        <button type="button" data-dismiss="modal" disabled={this.state.re_assign_reason == ""} className="btn btn-primary" onClick={() => this.handleSaveBtnClick(row.id)} >Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
                }
            </div>
        )
    }
}

function reAssign(cell: any, row: any, props: any) {
    return (
        <ReAssign row={row} data={props} />
    );
}

class ReAssignReason extends Component<{ row: any, data: any }, {[x:number]: any}> {

    constructor(readonly props: any) {
        super(props);
        // this.state = {
        // }
    }
    getAssignReasons = (id: any) => {
        this.props.data.ReAssignData(id);
    }

    render() {
        const { row, data } = this.props;
        console.log("reassignData", data.reAssignData)    
        // let reason_list = data.reAssignData.filter(function(el:any) { return el.ticket.id === row.id  });
        return (
            <div>
                {((row.ticket_status_code == 3 || row.ticket_status_code == 8) && row.isAssign == 1) ?
                    <div>
                        <button data-toggle="modal" data-target={`#reAssignReason${row.id}`} className="btn btn-outline-primary" onClick={()=>{this.getAssignReasons(row.id)}} title="ReOpen Resons">Reasons</button>
                        <div title="Re-Assign Reason" id={`reAssignReason${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                            <div role="document" className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 id="exampleModalLabel" className="modal-title">Re-Assign Resons</h4>
                                        <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                    </div>
                                    <div className="modal-body">
                                        {/* <h5>Re-Assign Reasons</h5> */}
                                        { data.reAssignData.map((element:any,index:any) => {
                                            return <p className="text-dark">{element.re_assign_reason} <hr /></p>    
                                        })}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
                }
            </div>
        )
    }
}

function reAssignReason(cell: any, row: any, props: any) {
    return (
        <ReAssignReason row={row} data={props} />
    );
}

function storeName(cell: any, row: any, props: any) {
    return (
        <div>
            {
                <span title={(row.store_name ? row.store_name : "N/A")}>{row.store_name ? row.store_name : "N/A"}</span>
            }
        </div>
    );
}
function recievedTimeFormat(cell: any, row: any, props: any) {
    return (
        <div title={moment(row.recieved_time).local().format('MMM Do YYYY | hh:mm a ')}>
            {
                moment(row.recieved_time).local().format('MMM Do YYYY | hh:mm a ')
            }
        </div>
    );
}
function assignedTimeFormat(cell: any, row: any, props: any) {

    return (
        <div title={row.assigned_time && moment(row.assigned_time).local().format('MMM Do YYYY | hh:mm a ')}>
            {row.assigned_time &&
                moment(row.assigned_time).local().format('MMM Do YYYY | hh:mm a ')
            }
        </div>
    );
}
function resolvedTimeFormat(cell: any, row: any, props: any) {
    return (
        <div title={row.resolved_time && moment(row.resolved_time).local().format('MMM Do YYYY | hh:mm a ')}>
            {row.resolved_time &&
                moment(row.resolved_time).local().format('MMM Do YYYY | hh:mm a ')
            }
        </div>
    );
}
function closedTimeFormat(cell: any, row: any, props: any) {
    return (
        <div title={row.closed_time && moment(row.closed_time).local().format('MMM Do YYYY | hh:mm a ')}>
            {row.closed_time &&
                moment(row.closed_time).local().format('MMM Do YYYY | hh:mm a ')
            }
        </div>
    );
}
function stopedTimeFormat(cell: any, row: any, props: any) {
    return (
        <div title={row.stop_time && moment(row.stop_time).local().format('MMM Do YYYY | hh:mm a ')}>
            {row.stop_time &&
                moment(row.stop_time).local().format('MMM Do YYYY | hh:mm a ')
            }
        </div>
    );
}
function scheduledTimeFormat(cell: any, row: any, props: any) {
    return (
        <div>
            {row.scheduled_time &&
                moment(row.scheduled_time).local().format('MMM Do YYYY | hh:mm a ')
            }
        </div>
    );
}
function ticketStatusFormat(cell: any, row: any, props: any) {
    return (
        <>
            <div>
                {(row.ticket_status_code == 1) &&
                    <div>
                        <span className="badge badge-received badge-font-padding">{cell}</span>
                    </div>
                }
                {(row.ticket_status_code == 2) &&
                    <div>
                        <span className="badge badge-assigned badge-font-padding">{cell}</span>
                    </div>
                }
                {(row.ticket_status_code == 3) &&
                    <div>
                        <span className="badge badge-success badge-font-padding">{cell}</span>
                    </div>
                }
                {(row.ticket_status_code == 8) &&
                    <div>
                        <span className="badge badge-closed badge-font-padding">{cell}</span>
                    </div>
                }
                {(row.ticket_status_code == 4) &&
                    <div>
                        <span className="badge badge-warning badge-font-padding">{cell}</span>
                    </div>
                }
                {(row.ticket_status_code == 9) &&
                    <div>
                        <span className="badge badge-info text-white badge-font-padding">{cell}</span>
                    </div>
                }
            </div>
        </>
    );
}
function re_scheduledTimeFormat(cell: any, row: any, props: any) {
    return (
        <div>
            {row.re_scheduled_time &&
                moment(row.re_scheduled_time).local().format('MMM Do YYYY | hh:mm a ')
            }
        </div>
    );
}

function responseTimeFormat(cell: any, row: any, props: any) {
    // console.log("Row", row);
    var recieved: any = moment(row.recieved_time, 'YYYY-MM-DD HH:mm:ss');
    var assigned: any = moment(row.assigned_time, 'YYYY-MM-DD HH:mm:ss');
    var responseTime = assigned.diff(recieved, 'minutes');

    return (
        <>
            {row.assigned_time ?
                <div>
                    {(responseTime <= row.priority_response) ?
                        <div>
                            <span>{responseTime ? responseTime : 0} mins</span> <span className="badge badge-success">meet time</span>
                        </div>

                        : <div>
                            <span>{responseTime ? responseTime : 0} mins</span> <span className="badge badge-danger">breach time</span>
                        </div>
                    }

                </div> :
                <div>
                    <span>N/A</span>
                </div>}
        </>
    );
}

function resolutionTimeFormat(cell: any, row: any, props: any) {
    // var recieved: any = moment(row.recieved_time, 'YYYY-MM-DD HH:mm:ss');
    // var resolved: any = moment(row.resolved_time, 'YYYY-MM-DD HH:mm:ss');
    // var resolutionTime = resolved.diff(recieved, 'minutes');

    return (
        <>
            {row.resolved_time ?
                <div>
                    {(row.resolutionTime <= row.priority_resolution) ?
                        <div>
                            <span>{row.resolutionTime ? row.resolutionTime : 0} mins </span> <span className="badge badge-success">meet time </span>
                        </div>
                        : <div>
                            <span>{row.resolutionTime ? row.resolutionTime : 0} mins</span> <span className="badge badge-danger">breach time</span>
                        </div>
                    }
                </div> :

                <div>
                    <span>N/A</span>
                </div>


            }
        </>
    );
}

class Ticket extends Component<{ reAssignData:any[], tickets: any[], companies: any[], ticketsByCompany: any[], requesterTicketList: any[], assigneeTicketList: any[], supervisorTicketList: any[], ticketsReceived: any, ticketsAssigned: any, ticketsResolved: any, ticketsClosed: any, supervisorTickets: () => {}, ReAssignData: (id:any) => {}, reassignTicketByRequester?: (data: any) => {}, assigneeTickets: () => {}, companyList: () => {}, getTicketByCompany: (id: any) => {}, requesterTickets: () => {}, deleteTicket: () => {}, logoutUser: () => {},launchTimer:(History:any)=>{},filteredOrders: any[], pausedTickets: () => {}, pausedData:any, emptyFilteredOrder:any[]}, { showDiv: any, company: any, checkTimer: any }> {
    closeModal: any;
    constructor(readonly props: any) {
        super(props);
        this.state = { showDiv: '', company: '', checkTimer: false }
        this.handleTimer = this.handleTimer.bind(this);
    }
    componentDidMount() {
        const roleId:any = sessionStorage.getItem("role");
        if (roleId == 5) {
            this.props.assigneeTickets();
        } else if (roleId == 3) {
            this.props.requesterTickets();
        } else if (roleId == 4) {
            this.props.supervisorTickets();
        }
        else {
            this.props.ticketList();
        }
        this.props.launchTimer(this.props.history);
        this.props.companyList();
        document.title = "EathosServices | Tickets"
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        const roleId = sessionStorage.getItem("role");
        // console.log("nextProps!!@",nextProps);

        // for (let i = 0; i < nextProps.tickets.length; i++) {
        //     const element = nextProps.tickets[i];
        //     // console.log("tickets!!!", element);
        //     let pausedData = this.props. 
            
        // }
        let ticketLength: any = sessionStorage.getItem('ticketLength');
        // console.log("nextProps Tickets",nextProps.tickets.length);
        // console.log("ticketLength",ticketLength);


            if (roleId === "5") {
                if (nextProps.assigneeTicketList.length > ticketLength) {
                    this.playAudio();
                    sessionStorage.setItem('ticketLength', nextProps.assigneeTicketList.length);
                }
            } else if (roleId === "3") {
                if (nextProps.requesterTicketList.length > ticketLength) {
                    this.playAudio();
                    sessionStorage.setItem('ticketLength', nextProps.requesterTicketList.length);
                }
            } else if (roleId === "4") {
                if (nextProps.supervisorTicketList.length > ticketLength) {
                    this.playAudio();
                    sessionStorage.setItem('ticketLength', nextProps.supervisorTicketList.length);
                }
            }
            else {
                if (nextProps.tickets.length > ticketLength) {
                    this.playAudio();
                    sessionStorage.setItem('ticketLength', nextProps.tickets.length);
                }
            }

        // }
    }

    playAudio() {
        var audio = new Audio();
            audio.src = process.env.PUBLIC_URL + "/assets/sound/ringtone.mp3";
            audio.oncanplaythrough = (event) => {
                var playedPromise = audio.play();
                if (playedPromise) {
                    playedPromise.catch((e) => {
                        // console.log(e)
                        if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                            console.log(e.name);
                        }
                    }).then(() => {
                        console.log("playing sound !!!");
                        return
                    });
                }
            }
    }

    handleStoresInputChange = (e: any) => {
        // console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ company: e.value });
        } else {
            this.setState({ company: "" });
        }
        if (e && e.value > 0) {
            this.props.ticketsByCompany(e.value);
        }

    };

    rowClassNameFormat(row: any, rowIdx: any) {
        return row.isAssign == 1 ? 'td-column-function-even-example':""
    }

    FilterbyStatus(status_code: any) {
        let { tickets } = this.props;
        let filteredArr: any = [];
        if (status_code === 1) {
            filteredArr = tickets.filter((ticket: any) => ticket.ticket_status_code == status_code)
        } else if (status_code === 2) {
            filteredArr = tickets.filter((ticket: any) => ticket.ticket_status_code == status_code)
        } else if (status_code === 3) {
            filteredArr = tickets.filter((ticket: any) => ticket.ticket_status_code == status_code)
        } else if (status_code === 8) {
            filteredArr = tickets.filter((ticket: any) => ticket.ticket_status_code == status_code)
        } else if (status_code === 0) {
            filteredArr = tickets.filter((ticket: any) => ticket.isActive == 1)
        }
        console.log("filteredArr",filteredArr);
        
        this.props.filterOrdersList(filteredArr)
    }

    handleTimer() {
        console.log("this.props.timerStatus",this.props.timerStatus);
        
        if (this.props.timerStatus == false) {
            this.props.launchTimer(this.props.history);
        } else if (this.props.timerStatus == true) {
            this.props.stopTimer();
        }
        this.closeModal.click();
    }

    render() {
        // console.log("tokeene", sessionStorage.token);
        
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { tickets, ticketsReceived, ticketsAssigned, ticketsResolved, ticketsClosed, filteredOrders, emptyFilteredOrder } = this.props;

        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            //insertModal: () => { return <Redirect to="/add-ticket" /> },
            noDataText: 'Tickets Not Found'
        };

        const { assigneeTicketList, reAssignData, requesterTicketList, supervisorTicketList, companies, ticketsByCompany } = this.props;
        // console.log("supervisor tickets", supervisorTicketList);
        // console.log("requester tickets", requesterTicketList);
        // console.log("assignee tickets", assigneeTicketList);
        // console.log("reAssignData", reAssignData);

        // let companyData: any = [];
        // companies.forEach((element: any) => {
        //     var company = { value: element.id, label: `${element.company_name} (${element.ticket.length} tickets)` };
        //     // var company = { value: element.id, label: element.company_name };
        //     companyData.push(company);
        // });

        console.log("tickets: ", requesterTicketList);

        const roleId = sessionStorage.getItem("role");
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Ticket Management<button title={this.props.timerStatus == false ? "Enable Refresh" : "Disable Refresh"} data-toggle="modal" data-target={`#enableTimer`} className={this.props.timerStatus == false ? "btn btn-outline-success ml-2" : "btn btn-outline-danger ml-2"}><i className={"fa fa-refresh"}></i></button></h4>
                                </div>

                            </div>
                        </header>
                        
                        <section className="dashboard-counts py-2">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow p-0">
                                    <div className="col-xl-2 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(1) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                            <div className="title"><span>Received</span><br />
                                                <strong className="text-bold text-dark">{ticketsReceived}</strong>
                                                <div className="progress">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-2 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(2) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-blue"><i className="fa fa-cutlery"></i></div>
                                            <div className="title"><span>Assigned</span> <br />
                                                <strong className="text-bold text-dark">{ticketsAssigned}</strong>
                                                <div className="progress">
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-2 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(3) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-orange"><i className="fa fa-truck"></i></div>
                                            <div className="title"><span>Resolved</span><br />
                                                <strong className="text-bold text-dark">{ticketsResolved}</strong>
                                                <div className="progress">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-3 col-sm-6" style={{ maxWidth: "23%" }}>
                                        <div onClick={() => { this.FilterbyStatus(8) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-violet"><i className="fa fa-handshake-o"></i></div>
                                            <div className="title"><span>Closed</span> <br />
                                                <strong className="text-bold text-dark">{ticketsClosed}</strong>
                                                <div className="progress">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(0) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-red"><i className="fa fa-clock-o"></i></div>
                                            <div className="title"><span>Total<br /> <strong className="text-bold text-dark">{tickets.length > 0 ? tickets.length: 0}</strong></span>
                                                <div className="progress">                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
{/* <div role="progressbar" style={{ width: `${recievedOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-green"></div>  ariaValuenow="40" ariaValuemin="0" ariaValuemax="100" */}
{/* <div role="progressbar" style={{ width: `${avg / 100}%`, height: "4px" }} className="progress-bar bg-red"></div> ariaValuenow="50" ariaValuemin="0" ariaValuemax="100" */}
{/* <div role="progressbar" style={{ width: `${kitchenCount / 100}%`, height: "4px" }} className="progress-bar bg-blue"></div>  ariaValuenow="25" ariaValuemin="0" ariaValuemax="100" */}
{/* <div role="progressbar" style={{ width: `${completedOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-violet"></div> ariaValuenow="50" ariaValuemin="0" ariaValuemax="100" */}
{/* <div role="progressbar" style={{ width: `${readyOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-orange"></div>  ariaValuenow="40" ariaValuemin="0" ariaValuemax="100" */}
                        {/*  Breadcrumb */}
                        {/* <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/tickets" className="text-primary">Tickets</Link></li>
                                <li className="breadcrumb-item active">All Tickets</li>
                            </ul>
                        </div> */}

                        {/* <section className="dashboard-counts py-2">
                            <div className="container-fluid">
                                
                                    <Select
                                        name="company"
                                        closeMenuOnSelect={false}
                                        isClearable
                                        options={companyData}
                                        className="text-capitalize select mt-2 col-md-6"
                                        classNamePrefix="select"
                                        onChange={(e) => this.handleStoresInputChange(e)}
                                    />
                                
                            </div>
                        </section> */}


                        <section className="tables py-2">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                            <BootstrapTable version='4' data={filteredOrders.length > 0 || emptyFilteredOrder ? filteredOrders : tickets } search={true} pagination={ tickets.length > 10 && true } options={options} trClassName={this.rowClassNameFormat} exportCSV={true} csvFileName='tickets.csv' hover>
                                                    {(roleId === '3' || roleId === '1' || roleId === '4') &&
                                                        <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='120' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataFormat={reAssign} formatExtraData={this.props} columnTitle>Re Open</TableHeaderColumn>
                                                    }
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='100' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='id' formatExtraData={this.props} dataFormat={linkFormatter} csvHeader='Ticket Id' dataSort={true} isKey>Ticket Id</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='150' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='subject' csvHeader='Subject' dataSort={true} columnTitle>Subject</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='200' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='description' csvHeader='Description' dataSort={true} columnTitle>Issue Description</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='130' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='store_name' csvHeader='Store'dataFormat={storeName}  dataSort={true} columnTitle>Store</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='130' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='ticket_status_description' dataFormat={ticketStatusFormat} formatExtraData={this.props} dataSort={true} csvHeader='Status' columnTitle>Ticket Status</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='130' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='ticket_priority_description' dataSort={true} csvHeader='Priority' columnTitle>Ticket Priority</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='150' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='name' csvHeader='RequesterName' dataSort={true} columnTitle>Requester Name</TableHeaderColumn>
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='150' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='email' csvHeader='RequesterEmail' dataSort={true} columnTitle>Requester Email</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='150' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='phone' csvHeader='RequesterPhone' dataSort={true} columnTitle>Requester Phone</TableHeaderColumn> */}

                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='120' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='cat_name' csvHeader='Category' dataSort={true} columnTitle>Category</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='130' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='sub_category_name' csvHeader='SubCatgory' dataSort={true} columnTitle>Sub Category</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='130' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='group_name' csvHeader='User Group' dataSort={true}>Assignee Group</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='100' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='assignee_name' csvHeader='Assignee' dataSort={true}>Assignee</TableHeaderColumn>

                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='200' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='recieved_time' dataFormat={recievedTimeFormat} dataSort={true} formatExtraData={this.props} csvHeader='RecievedTime' columnTitle>Recieved Time</TableHeaderColumn>
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='200' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='stop_time' dataFormat={stopedTimeFormat} dataSort={true} formatExtraData={this.props} csvHeader='StopTime' columnTitle>Stop Time</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='200' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='assigned_time' dataFormat={assignedTimeFormat} dataSort={true} formatExtraData={this.props} csvHeader='AssignedTime'>Assigned Time</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='200' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='resolved_time' dataFormat={resolvedTimeFormat} dataSort={true} formatExtraData={this.props} csvHeader='ResolvedTime'>Resolved Time</TableHeaderColumn>

                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='200' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='priority_response' dataFormat={responseTimeFormat} dataSort={true} formatExtraData={this.props} csvHeader='Response Time'>Response Time</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='200' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='priority_resolution' dataFormat={resolutionTimeFormat} dataSort={true} formatExtraData={this.props} csvHeader='Resolution Time'>Resolution Time</TableHeaderColumn>

                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='200' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='closed_time' dataFormat={closedTimeFormat} dataSort={true} formatExtraData={this.props} csvHeader='CalosedTime' columnTitle>Closed Time</TableHeaderColumn>
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='200' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='scheduled_time' dataFormat={scheduledTimeFormat} dataSort={true} formatExtraData={this.props} csvHeader='ScheduledTime' columnTitle>Scheduled Time</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='200' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='re_scheduled_time' dataFormat={re_scheduledTimeFormat} dataSort={true} csvHeader='ReScheduledTime'>Rescheduled Time</TableHeaderColumn> */}
                                                    {roleId !== '5' &&
                                                    <TableHeaderColumn thStyle={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} width='115' tdStyle={{ fontSize: 12, textAlign: 'center' }} dataField='reasons' formatExtraData={this.props} dataFormat={reAssignReason} csvHeader='Reason' dataSort={true}>Reasons</TableHeaderColumn>
                                                    }
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="enableTimer" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                        <div role="document" className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 id="exampleModalLabel" className="modal-title">Auto Refresh</h4>
                                    <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure,you want to {this.props.timerStatus == false ? "enable" : "disable"} auto refresh?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" ref={el => this.closeModal = el} data-dismiss="modal" className="btn btn-secondary">Close</button>
                                    <button onClick={this.handleTimer} className="btn btn-primary">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
};

function timeFormatter(cell: any) {
    let Finaltime: any = "";
    if (cell !== "") {
        //convert the time 24 hour to 12 hour
        let time = cell.split(":")
        let Hours = time[0]
        let AmOrPm = Hours >= 12 ? 'PM' : 'AM';
        Hours = (Hours % 12) || 12;
        Finaltime = Hours + ":" + time[1] + " " + AmOrPm;
    }
    return Finaltime;
}

const mapStateToProps = (state: any) => {
    // console.log("state",state);
    return {
        tickets: state.ticket.ticketList,
        assigneeTicketList: state.ticket.assigneeTicketList,
        requesterTicketList: state.ticket.requesterTicketList,
        supervisorTicketList: state.ticket.supervisorTicketList,
        ticketByCompany: state.ticket.ticketByComp,
        companies: state.ticket.companies,
        pausedData: state.ticket.pausedData,
        ticketsReceived: state.ticket.ticketsReceived,
        ticketsAssigned: state.ticket.ticketsAssigned,
        ticketsResolved: state.ticket.ticketsResolved,
        ticketsClosed: state.ticket.ticketsClosed,
        filteredOrders: state.ticket.filteredOrders,
        emptyFilteredOrder: state.ticket.emptyFilterOrder,
        timerStatus: state.ticket.timerStatus,
        reAssignData: state.ticket.reAssignData
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        ticketList: function () {
            dispatch(ticketList())
        },
        assigneeTickets: function () {
            dispatch(assigneeTickets())
        },
        requesterTickets: function () {
            dispatch(requesterTickets())
        },
        supervisorTickets: function () {
            dispatch(supervisorTickets())
        },
        getTicketByCompany: function (id: any) {
            dispatch(ticketsByCompany(id))
        },
        companyList: function () {
            dispatch(companyList())
        },
        deleteTicket: function (id: any) {
            dispatch(deleteTicket(id))
        },
        reassignTicketByRequester: function (data: any) {
            dispatch(reassignTicketByRequester(data))
        },
        filterOrdersList: (filteredArr: any) => {
            dispatch(filterOrdersList(filteredArr))
        },
        launchTimer: (history: any) => {
            dispatch(launchTimer(history))
        },
        stopTimer: () => {
            dispatch(stopTimer())
        },
        pausedTickets: () => {
            dispatch(pausedTickets())
        },
        ReAssignData: (id:any) => {
            dispatch(ReAssignData(id));
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Ticket);