import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { connect } from 'react-redux'
import _ from 'lodash'
import jwt from 'jsonwebtoken'
import config from '../config/setting'
import { lastSevenDaysDataBarChart, ticketCounts, TopCategories, TopCustomers } from '../../redux/actions/ticket';
import { allEngineers, logoutUser } from '../../redux/actions/user';
import { ticketList, feedbackList } from './../../redux/actions/ticket';
import moment from 'moment';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
toast.configure();
Chart.plugins.register(ChartDataLabels);

class BarChart extends Component<{ data: any[] }, {}> {
  constructor(readonly props: any) {
      super(props)
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
      // console.log("nextPropsBarChart", nextProps.data);
      
      let assigned = [];
      let resolved = [];
      let weekDays: any = [];
        for (let i = 0; i < nextProps.data.length; i++) {
            let date = nextProps.data[i].createdAt.split("T")
            weekDays.push(moment(date[0]).local().format("DD MMM"))
        }
      if (nextProps && nextProps.data) {
          for (let i = 0; i < nextProps.data.length; i++) {
              assigned.push(nextProps.data[i].assignedTickets);
              resolved.push(nextProps.data[i].resolvedTickets);
          }
      }
      let sortWeekDays = weekDays.reverse()
      var BARCHART: any = document.getElementById('barChart');
      let chart = new Chart(BARCHART, {
          type: 'bar',
          options:
          {
              scales:
              {
                  xAxes: [{
                      display: true
                  }],
                  yAxes: [{
                      display: true
                  }],
              },
              legend: {
                  display: true
              },
              plugins: {
                datalabels: {
                   display: function(context: any) {
                      return context.dataset.data[context.dataIndex] > 0; // or >= 1 or ...
                   }
                }
              },
              responsive: true,
              maintainAspectRatio: false,
          },
          data: {
              labels: sortWeekDays,
              datasets: [
                  {
                      label: "Assigned",
                      backgroundColor: 'rgb(54, 162, 235)',
                      fill: false,
                      borderColor: 'rgb(54, 162, 235)',
                      borderWidth: 1,
                      data: assigned.reverse(),
                      datalabels: {
                        color: 'white',
                        font: {
                            size: 16,
                            family: 'cursive'
                        }
                    }  
                  },
                  {
                    label: "Resolved",
                    backgroundColor: 'rgb(34, 139, 34)',
                    borderColor: 'rgb(34, 139, 34)',
                    fill: false,
                    borderWidth: 1,
                    datalabels: {
                      color: 'white',
                      font: {
                          size: 16,
                          family: 'cursive'
                      }
                    },  
                    data: resolved.reverse()
                }
              ]
          }
      });
      chart.update({
          duration: 800,
          lazy: false,
          easing: 'easeOutBounce'
      });
  }
  render() {
      return <canvas height="315px" id="barChart" />;
  }
}

class PieChart extends Component<{ data: any }, {}> {
  constructor(readonly props: any) {
      super(props);
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // console.log("nextProps", nextProps);
      let dataa: any = [];

      const counts = nextProps.data[0];
      let closed = counts.closedBySystem ? counts.closedBySystem : "";
      let recieved = counts.recievedTickets ? counts.recievedTickets : "";
      let assigned = counts.assignedTickets ? counts.assignedTickets : "";
      let resolved = counts.resolvedTickets ? counts.resolvedTickets : "";
      dataa.push(closed, recieved, assigned, resolved);
      console.log("dataa pieee", dataa);
      
      let PIECHART: any = document.getElementById('piechart');
      new Chart(PIECHART, {
          type: 'pie',
          plugins: [ChartDataLabels],
          options: {
            plugins: {
              datalabels: {
                 display: function(context: any) {
                    return context.dataset.data[context.dataIndex] > 0; // or >= 1 or ...
                 }
              }
            },
            responsive: true,
            legend: {
              position: 'bottom',
            },
            title: {
                display: true,
                text: 'Tickets',
                fontSize: 20
            }
          },
          data: {
              labels: ["Closed", "Recieved","Assigned", "Resolved"],
              datasets: [
                  {
                      data: dataa,
                      backgroundColor: [
                          'rgb(255, 99, 132)',
                          'rgb(54, 162, 235)',
                          'rgb(255, 205, 86)',
                          'rgb(34, 139, 34)'
                        ],
                        hoverOffset: 4,
                        datalabels: {
                          color: 'white',
                          font: {
                              size: 16,
                              family: 'cursive'
                          }
                      }  
                  }]
          }
      });
  }
  render() {
      return (
      <div>
          <canvas height="190px" id="piechart" />
      </div>
      );
  }
}

class Dashboard extends Component<{
  ticket_count: any, tickets: any[], topCategories: any[], topCustomers: any[], feedbacks: any[], engineers: any[], barChartData: any,
  ticketCounts: (date: any) => {}, TopCategories: () => {}, lastSevenDaysDataBarChart: () => {}, TopCustomers: (date: any) => {}, allEngineers: () => {}, feedbackList: () => {}, ticketList: () => {}, logoutUser: () => {}
},
  { showDiv: any, days: any, startDate: any, endDate: any, validTimeFlag: any, [x: number]: any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = { 
      showDiv: '',
      days: 0,
      startDate: "",
      endDate: "",
      validTimeFlag: ""
    }
    this.handleDaysFilter = this.handleDaysFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidTime = this.handleValidTime.bind(this)
  }
  componentDidMount() {
    let obj = { days: this.state.days };
    this.props.TopCustomers(obj)
    this.props.TopCategories(obj)
    this.props.lastSevenDaysDataBarChart()
    this.props.ticketCounts(obj)
    this.props.allEngineers()
    this.props.ticketList()
    this.props.feedbackList()
    document.title = "EathosServices | Dashboard"
  }

  handleDaysFilter(event: { target: { name: any; value: any; }; }) {
    let obj = { days: event.target.value };
    this.props.TopCustomers(obj)
    this.props.TopCategories(obj)
    this.props.ticketCounts(obj)
  }
  handleValidTime(event: { target: { name: any; value: any; }; }) {
    let futureDatetime = new Date(event.target.value)
    let validMinDateTime = new Date();
    if (futureDatetime <= validMinDateTime) {
        this.setState({ [event.target.name]: event.target.value, validTimeFlag: true });
    } else {
        this.setState({ validTimeFlag: false })
        toast.error("Invalid date and time selected");
    }
}
  isStatsDateReady = () => {
      let { startDate, endDate, validTimeFlag } = this.state;
      return (startDate !== "" && endDate !== "" && validTimeFlag == true);
  }
  handleSubmit() {
      let { startDate, endDate } = this.state;
      let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
      let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
      let data: any = {
          start: startDateUtc,
          end: endDateUtc
      }
      this.props.ticketCounts(data);
      this.props.TopCustomers(data);
      this.props.TopCategories(data);
  }

  render() {
    let roleId: any = ""

    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        } else {
          roleId = decoded.role;
        }
      });
    } else {
      return <Redirect to="/" />
    }
    const { ticket_count, topCustomers, topCategories, subCatList, barChartData, feedbacks, stores, groups, tickets, priorities, engineers } = this.props;
    console.log("ticket_count", ticket_count);
    
    var response_time = 0;
    var resolution_time = 0;
    var total_rating: any = 0;
    var breach = 0;

    // Feedback Avg rating
    feedbacks.map((el: any) => {
      let rating = el.rating;
      total_rating += Number(rating)
    })
    var avgFeedback = total_rating / feedbacks.length;
    var avg_feedback = Math.round(avgFeedback);

    // SLA Breach Tickets
    tickets.map((el: any) => {
      // Response
      var recievedTime: any = moment(el.recieved_time, 'YYYY-MM-DD HH:mm:ss');
      var assignedTime: any = moment(el.assigned_time, 'YYYY-MM-DD HH:mm:ss');
      var responseTime: any = assignedTime.diff(recievedTime, 'minutes');
      if (responseTime) {
        response_time += responseTime;
      }

      // // Resolution
      // var recievedTime: any = moment(el.recieved_time, 'YYYY-MM-DD HH:mm:ss');
      // var resolvedTime: any = moment(el.resolved_time, 'YYYY-MM-DD HH:mm:ss');
      // var resolutionTime: any = resolvedTime.diff(recievedTime, 'minutes');
      if (el.resolutionTime) {
        resolution_time += el.resolutionTime;
      }

      //Get SLA Breach Tickets
      if (responseTime > el.priority_response || el.resolutionTime > el.priority_resolution) {
        breach++
        // console.log("breach", breach);
      }

    })
    // console.log("breach tickets", breach);

    var avgResponse = response_time / tickets.length;
    let avg_response: any = Math.round(avgResponse);
    // console.log("avg_response",avg_response);

    var avgResolution = resolution_time / tickets.length;
    let avg_resolution: any = Math.round(avgResolution);

    // console.log("avg_resolution",avg_resolution);
    // Ticket Counts
    const counts = ticket_count[0];
    // console.log("counts",counts);
    // console.log("ticket_count asdasdasdasdassd", counts.totalTicketes);

    let total = counts && counts.totalTicketes;
    let recieved = counts && counts.recievedTickets
    let assigned = counts && counts.assignedTickets
    let resolved = counts && counts.resolvedTickets
    let paused = counts && counts.pausedTickets
    let closed = counts && counts.closedBySystem

    let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                  <div className="row py-2">
                      <div className="col-lg-6 col-md-6 col-12">
                          <h4 className="mt-2">Dashboard</h4>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                          <div className="row">
                              <div className="col-lg-6 col-md-6 col-12">
                                  <div className="form-group">
                                      <input
                                          id="start"
                                          type="datetime-local"
                                          name="startDate"
                                          max={maxDate}
                                          value={this.state.startDate}
                                          className="input-material"
                                          onChange={this.handleValidTime}
                                      />
                                  </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-12">
                                  <div className="form-group mb-1">
                                      <input
                                          id="end"
                                          type="datetime-local"
                                          name="endDate"
                                          max={maxDate}
                                          value={this.state.endDate}
                                          className="input-material"
                                          onChange={this.handleValidTime}
                                      />
                                  </div>
                                  <button disabled={!this.isStatsDateReady()} className="badge badge-pill btn-info text-capitalize" style={{ cursor: 'pointer', float: 'right' }} onClick={() => { this.handleSubmit() }}>get data</button>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>
            </header>

            {/* <!-- Dashboard Counts Section--> */}
            <section className="dashboard-counts no-padding-bottom">
              <div className="container-fluid">
                <div className="row bg-white has-shadow">
                  {/* <!-- Item --> */}
                  <div className="col-xl-2 col-sm-6">
                    <div className="item d-flex align-items-center" style={{ border: "none" }}>
                      {roleId === 1 || roleId === 2 || roleId === 4 || roleId === 5 ? (
                        <div className="icon bg-violet">
                          <i className="fa fa-get-pocket"></i>
                        </div>
                      ) : (
                        <div className="icon bg-violet">
                          <i className="fa fa-get-pocket"></i>
                        </div>
                      )}
                      <div className="title">
                        <span>Recieved</span><br />
                      <div className="number mr-2">
                        <strong>{recieved ? recieved : 0}</strong>
                      </div>
                        <div className="progress">
                          <div
                            role="progressbar"
                            style={{ width: `${recieved / 100}%`, height: "4px" }}
                            className="progress-bar bg-violet"
                          ></div>{" "}
                          {/*ariaValuenow="25" ariaValuemin="0" ariaValuemax="100"*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Item --> */}
                  <div className="col-xl-2 col-sm-6">
                    <div className="item d-flex align-items-center" style={{ border: "none" }}>
                      {roleId === 1 ||
                        roleId === 3 ||
                        roleId === 4 ||
                        roleId === 5 ? (
                        <div className="icon bg-red">
                          <i className="fa fa-users"></i>
                        </div>
                      ) : (
                        <div className="icon bg-red">
                          <i className="fa fa-users"></i>
                        </div>
                      )}
                      <div className="title">
                        <span>Assigned</span><br />
                      <div className="number mr-2">
                        <strong>{assigned ? assigned : 0}</strong>
                      </div>
                        <div className="progress">
                          <div
                            role="progressbar"
                            style={{ width: `${assigned / 100}%`, height: "4px" }}
                            className="progress-bar bg-red"
                          ></div>{" "}
                          {/*ariaValuenow="70" ariaValuemin="0" ariaValuemax="100"*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Item --> */}
                  <div className="col-xl-2 col-sm-6">
                    <div className="item d-flex align-items-center" style={{ border: "none" }}>
                      {roleId === 1 || roleId === 3 || roleId === 4 || roleId === 5 ? (
                        <div className="icon bg-orange">
                          <i className="fa fa-unlock-alt"></i>
                        </div>
                      ) : (
                        <div className="icon bg-orange">
                          <i className="fa fa-unlock-alt"></i>
                        </div>
                      )}
                      <div className="title">
                        <span>Resolved</span><br />
                      <div className="number mr-2">
                        <strong>{resolved ? resolved : 0}</strong>
                      </div>
                        <div className="progress">
                          <div
                            role="progressbar"
                            style={{ width: `${resolved / 100}%`, height: "4px" }}
                            className="progress-bar bg-orange"
                          ></div>{" "}
                          {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Item --> */}
                  <div className="col-xl-3 col-sm-6">
                    <div className="item d-flex align-items-center" style={{ border: "none" }}>
                      {roleId === 1 || roleId === 3 || roleId === 4 || roleId === 5 ? (
                        <div className="icon bg-green">
                          <i className="fa fa-ticket"></i>
                        </div>
                      ) : (
                        <div className="icon bg-green">
                          <i className="fa fa-ticket"></i>
                        </div>
                      )}
                      <div className="title">
                        <span>Closed</span><br />
                      <div className="number">
                        <strong>{closed ? closed : 0}</strong>
                      </div>
                        <div className="progress">
                          <div
                            role="progressbar"
                            style={{ width: `${closed / 100}%`, height: "4px" }}
                            className="progress-bar bg-green"
                          ></div>{" "}
                          {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Item */}
                  <div className="col-xl-3 col-sm-6" style={{ maxWidth: "23%" }}>
                    <div className="item d-flex align-items-center" style={{ border: "none" }}>
                      {roleId === 1 || roleId === 3 || roleId === 4 || roleId === 5 ? (
                        <div className="icon bg-green">
                          <i className="fa fa-ticket"></i>
                        </div>
                      ) : (
                        <div className="icon bg-green">
                          <i className="fa fa-ticket"></i>
                        </div>
                      )}
                      <div className="title">
                        <span>Total</span><br />
                      <div className="number">
                        <strong>{total ? total : 0}</strong>
                      </div>
                        <div className="progress">
                          <div
                            role="progressbar"
                            style={{ width: `${total / 100}%`, height: "4px" }}
                            className="progress-bar bg-green"
                          ></div>{" "}
                          {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                        </div>
                      </div>
                    </div>
                  </div>         
                  </div>
              </div>
            </section>
            {/* Top Customers */}
            <section className="dashboard-header no-padding-bottom">
              <div className="container-fluid">
                <div className="row">
                  {/* Pie Chart */}
                  <div className="statistics col-lg-3 col-3">
                    {topCustomers && topCustomers.map((data:any, index: any) => (
                    <div className="statistic d-flex align-items-center bg-white has-shadow" style={{ padding: '10px 15px' }}>
                      {(() => {
                        if (index == 0) {
                            return (
                                <div className="icon bg-orange"><i className="fa fa-shield"></i></div>
                            )
                        } else if (index == 1) {
                            return (
                                <div className="icon bg-gray"><i className="fa fa-star"></i></div>
                            )
                        }
                        else if (index == 2) {
                            return (
                                <div className="icon bg-violet"><i className="fa fa-star-half-o"></i></div>
                            )
                        }
                        else if (index == 3 || index == 4) {
                            return (
                                <div className="icon bg-red"><i className="fa fa-star-o"></i></div>
                            )
                        }
                    })()}
                      <div className="text">{data.name}<br /><strong style={{ fontSize: '95%' }}><span className="text-secondary">{data.requester_tickets} {data.requester_tickets > 1 ? "tickets" : "ticket"}</span></strong></div>
                    </div>
                    ))
                    }
                  </div>
                  <div className="col-lg-6 col-6">
                    {(counts && counts !== []) &&
                      <div className="pie-chart text-white bg-white align-items-center justify-content-center has-shadow">
                          <PieChart data={ticket_count} />
                      </div>         
                    }
                  </div>       
                  <div className="statistics col-lg-3 col-3">
                  {topCategories && topCategories.map((data:any, index: any) => (
                    <div className="statistic d-flex align-items-center bg-white has-shadow" style={{ padding: '10px 15px' }}>
                      {(() => {
                        if (index == 0) {
                            return (
                                <div className="icon bg-orange"><i className="fa fa-shield"></i></div>
                            )
                        } else if (index == 1) {
                            return (
                                <div className="icon bg-gray"><i className="fa fa-star"></i></div>
                            )
                        }
                        else if (index == 2) {
                            return (
                                <div className="icon bg-violet"><i className="fa fa-star-half-o"></i></div>
                            )
                        }
                        else if (index == 3 || index == 4) {
                            return (
                                <div className="icon bg-red"><i className="fa fa-star-o"></i></div>
                            )
                        }
                    })()}
                      <div className="text">{data.cat_name}<br /><strong style={{ fontSize: '95%' }}><span className="text-secondary">{data.category_total} {data.category_total > 1 ? "tickets" : "ticket"}</span></strong></div>
                    </div>
                    ))
                    }
                  </div>
                </div>
              </div>
            </section>
            
            {/* <!-- Dashboard Charts Section  --> */}
            <section className="dashboard-header no-padding-bottom">
              <div className="container-fluid">
                <div className="row">
                  {/* Pie Chart */}
                  <div className="col-lg-12 col-12">
                    <div className="bar-chart text-white bg-white align-items-center justify-content-center has-shadow">
                      {barChartData && barChartData != {} &&
                        <BarChart data={barChartData} />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Table Cards */}
            <section className="dashboard-header no-padding-bottom">
              <div className="container-fluid">
                <div className="row">
                  {/* <!-- Statistics --> */}

                  <div className="chart col-lg-4 col-12">
                    <div className="statistic d-flex align-items-center bg-white has-shadow">
                      <div className="icon bg-blue">
                        <i className="fa fa-user"></i>
                      </div>
                      <div className="text">
                        <strong>{engineers.length}</strong>
                        <br />
                        <small>Active Engineers</small>
                      </div>
                    </div>
                  </div>
                  <div className="chart col-lg-4 col-12">
                    {/* <!-- Numbers--> */}
                    <div className="statistic d-flex align-items-center bg-white has-shadow">
                      <div className="icon bg-info">
                        <i className="fa fa-clock-o"></i>
                      </div>
                      <div className="text">
                        <strong>{avg_response ? avg_response : 0} mins</strong>
                        <br />
                        <small>AVG Response Time</small>
                      </div>
                    </div>
                  </div>
                  <div className="chart col-lg-4 col-12">
                    <div className="statistic d-flex align-items-center bg-white has-shadow">
                      <div className="icon bg-success">
                        <i className="fa fa-clock-o"></i>
                      </div>
                      <div className="text">
                        <strong>{avg_resolution ? avg_resolution : 0} mins</strong>
                        <br />
                        <small>AVG Resolution Time</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="dashboard-header pt-xl-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="chart col-lg-4 col-12">
                    <div className="statistic d-flex align-items-center bg-white has-shadow">
                      <div className="icon bg-orange">
                        <i className="fa fa-pause"></i>
                      </div>
                      <div className="text">
                        <strong>{paused ? paused : 0}</strong>
                        <br />
                        <small>Hold Tickets</small>
                      </div>
                    </div>
                  </div>
                  <div className="chart col-lg-4 col-12">
                    <div className="statistic d-flex align-items-center bg-white has-shadow">
                      <div className="icon bg-red">
                        <i className="fa fa-ban"></i>
                      </div>
                      <div className="text">
                        <strong>{breach ? breach : 0}</strong>
                        <br />
                        <small>SLA Breach Tickets</small>
                      </div>
                    </div>
                  </div>
                  <div className="statistics col-lg-4 col-12">
                    <div className="statistic d-flex align-items-center bg-white has-shadow">
                      <div className="icon bg-orange">
                        <i className="fa fa-comments-o"></i>
                      </div>
                      <div className="text">
                        <strong>{avg_feedback ? avg_feedback : 0}</strong>
                        <br />
                        <small>Feedback Score</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Footer />
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state: any) => {
  // console.log("state",state);
  return {
    ticket_count: state.ticket.ticketCounts,
    barChartData: state.ticket.barChartData,
    topCustomers: state.ticket.topCustomers,
    topCategories: state.ticket.topCategories,
    engineers: state.user.engineers,
    tickets: state.ticket.ticketList,
    feedbacks: state.ticket.feedbacks
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    ticketList: function () {
      dispatch(ticketList())
    },
    feedbackList: function () {
      dispatch(feedbackList())
    },
    allEngineers: function () {
      dispatch(allEngineers());
    },
    ticketCounts: function (days: any) {
      dispatch(ticketCounts(days));
    },
    lastSevenDaysDataBarChart: function () {
      dispatch(lastSevenDaysDataBarChart());
    },
    TopCustomers: function (date: any) {
      dispatch(TopCustomers(date));
    },
    TopCategories: function (date: any) {
      dispatch(TopCategories(date));
    },
    logoutUser: function () {
      dispatch(logoutUser());
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)