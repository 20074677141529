import {
  STORE_LIST, STORE_GET, COMPANY_LIST, STORE_GET_BY_COMPANY
} from '../actions/types';
const initialState: any = {
  storeList: [] || '',
  companyList: [] || '',
  storeGet: {},
  store_count: 0,
  storesByCompany: [] || "",

};
const StoreReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STORE_LIST:
      return {
        ...state,
        storeList: action.payload
      };
    case COMPANY_LIST:
      return {
        ...state,
        companyList: action.payload
      };
    case STORE_GET_BY_COMPANY:
      return {
        ...state,
        storesByCompany: action.payload,
        store_count: action.store_count
      };
    case STORE_GET:
      return {
        ...state,
        storeGet: action.payload
      };

    default:
      return state;
  }
};
export default StoreReducer;