import {
   ZONE_GET, ZONE_LIST, GROUP_LIST,GROUP_PARENT_LIST,GROUP_BY_ZONES_GET
  } from '../actions/types';
  const initialState: any = {
    groupList: [] || '',
    groupParentList: [] || '',
    zoneList: [] || '',
    getGroupByZones: [] || '',
    getZone: {}
  
  };
  const ZoneReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case GROUP_LIST:
        return {
          ...state,
          groupList: action.payload
        };

      case GROUP_PARENT_LIST:
        return {
          ...state,
          groupParentList: action.payload
        };
      
        case ZONE_LIST:
        return {
          ...state,
          zoneList: action.payload
        };
     
      case ZONE_GET:
        return {
          ...state,
          getZone: action.payload
        };

        case GROUP_BY_ZONES_GET:
        return {
          ...state,
          getGroupByZones: action.payload
        };

      default:
        return state;
    }
  };
  export default ZoneReducer;