import React, { Component } from 'react';
import { EditTicketProps, EditTicketState } from '../../interfaces/ticket'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Select from 'react-select'
import Footer from '../footer/footer'
import moment from 'moment'
import Loader from "react-loader-spinner";
import CheckChanges from '../confirmOnLeave';
import { BASE_URL } from "../config/setting";
import { Link, Redirect } from 'react-router-dom';
import { updateTicketBySupervisor, engineersList, subCategoryList, getTicket, statusList, priorityList, typeList, groupList, updateTicket, sendReply, getAllTicketDiscussionByTicket } from '../../redux/actions/ticket';
import { logoutUser } from './../../redux/actions/user';
//import FileViewer from 'react-file-viewer';
import subCategory from '../sub-category/sub-category';
import config from '../config/setting'
import { extname } from 'path';
import { WithContext as ReactTags} from 'react-tag-input';
import './tickets.css'
import { toast } from 'react-toastify';

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const suggestions: any = [
    { id: 'USA', text: 'USA' },
    { id: 'Germany', text: 'Germany' },
    { id: 'Austria', text: 'Austria' },
    { id: 'Costa Rica', text: 'Costa Rica' },
    { id: 'Sri Lanka', text: 'Sri Lanka' },
    { id: 'Thailand', text: 'Thailand' }
]
class EditTicket extends Component<EditTicketProps, EditTicketState> {
    closeModal: any;
    closeModalReply: any;
    selectRef: any;
    selectRefCat: any;
    constructor(props: any) {
        super(props);
        this.state = {
            supervisor_file: "",
            engineer_file: "",
            priority: "",
            group: "",
            status: "",
            category: "",
            work_note: "",
            subCategory: "",
            assignee: "",
            replyDescription: "",
            replyFile: "",
            toRequester: "",
            toEnginner: "",
            emailFrom:"",
            requester_id:"",
            email_to_id: "",
            paused_reason: "",
            isValidEmail: true,
            emailRecipents: []

        };
        this.selectRef = null;
        this.selectRefCat = null;
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDeleteTag = this.handleDeleteTag.bind(this);
        this.handleAdditionTag = this.handleAdditionTag.bind(this);
        this.handleDragTag = this.handleDragTag.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getTicket(id);
        this.props.priorityList();
        this.props.statusList();
        this.props.groupList();
        this.props.typeList();
        this.props.getAllTicketDiscussionByTicket(id);
        document.title = "EathosServices | Update Ticket"
    }

    handleDeleteTag(i: any) {
        const { emailRecipents } = this.state;
        var reg = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (reg.test(i.id) === false) {
            this.setState({
                emailRecipents: emailRecipents.filter((tag: any, index: any) => index !== i),
                isValidEmail: true
            });
        } else {
            this.setState({
                emailRecipents: emailRecipents.filter((tag: any, index: any) => index !== i),
                isValidEmail: false
            });
        }
    }

    handleAdditionTag(tag: any) {
        console.log("Tag!@@", tag);
        
        var reg = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (reg.test(tag.id) === false) {
          this.setState(state => ({ emailRecipents: [...state.emailRecipents, tag], isValidEmail: false }))
        } else {
          this.setState(state => ({ emailRecipents: [...state.emailRecipents, tag], isValidEmail: true }));
        }
        // this.setState(state => ({ emailRecipents: [...state.emailRecipents, tag] }));
    }

    handleDragTag(tag: any, currPos: any, newPos: any) {
        const tags = [...this.state.emailRecipents];
        const newTags = this.state.emailRecipents.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        this.setState({ emailRecipents: newTags });
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        // console.log("nextProps", nextProps.ticket);
        this.setState({
            priority: nextProps.ticket.ticket_priority_code,
        });
        if (this.props.ticket !== nextProps.ticket) {
            this.setState({
                // status: nextProps.ticket.ticket_status_code,
                category: nextProps.ticket.cat_id,
                subCategory: nextProps.ticket.sub_category_name,
                group: nextProps.ticket.group,
                assignee: nextProps.ticket.assignee_id,
                toRequester: nextProps.ticket.requester_email,
                toEnginner: nextProps.ticket.assignee_email,
                requester_id: nextProps.ticket.requester_id,
            });

        }
        if(sessionStorage){
            this.setState({emailFrom:sessionStorage.getItem('email')})
        }
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }

    handleInputChangeGroup = (e: any) => {
        // console.log("e", e);
        if(this.props.engineers.length>0){
            this.selectRef.select.clearValue();
        }
        if (e && e.value > 0) {
            this.props.engineersList(e.value);
            this.setState({ group: e.value });
        } else {
            this.setState({ group: "" });
        }
    }

    handleAssigneeInputChange = (e: any) => {
        // console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ assignee: e.value });
        } else {
            this.setState({ assignee: "" });
        }
    };

    handleInputChangeCategory = (event: any) => {
        if(this.props.subCategory.length>0){
            this.selectRefCat.select.clearValue();
        }
        if (event && event.value > 0) {
            this.props.subCategoryList(event.value);
            this.setState({
                category: event.value
            });
            this.setState({
                subCategory: ""
            });
        } else {
            this.setState({ assignee: "" });
        }

    }

    handleCategoryInputChange = (e: any) => {
        // console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ category: e.value });
        } else {
            this.setState({ category: "" });
        }
    };
    handleSubCategoryInputChange = (e: any) => {
        console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ subCategory: e.label });
        } else {
            this.setState({ subCategory: "" });
        }
    };

    maxSelectFile = (event: any) => {
        let files = event.target.files // create file object
        if (files.length > 5) {
            const msg = 'Only 5 files can be uploaded at a time'
            event.target.value = null // discard selected file
            toast.error(msg, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
            console.log(msg)
            return false;
        }
        return true;
    }

    checkFileSize = (event: any) => {
        let files = event.target.files
        let size = 5242880
        let err = "";
        for (var x = 0; x < files.length; x++) {
            if (files[x].size > size) {
                err += 'file too large, please pick a smaller file upto 5mb\n';
            }
        };
        if (err !== '') {
            event.target.value = null
            toast.error(err, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
            console.log(err)
            return false
        }
        return true;
    }
    fileSelectedHandler = (e: any) => {
        if (this.maxSelectFile(e) && this.checkFileSize(e)) {
            this.setState({ engineer_file: e.target.files })
        }
    }
    fileSelectedHandlerReply = (e: any) => {
        if (this.maxSelectFile(e) && this.checkFileSize(e)) {
            this.setState({ replyFile: e.target.files })
        }
    }
    isTicketReady = () => {
        const { group, category, subCategory, priority, status, assignee, work_note } = this.state
        const roleId = sessionStorage.getItem("role");
        if (roleId === '5') {
            if(status === 5) {
                return (
                    work_note !== "" && status !== ""
                );
            } else {
                return ( status !== "" )
            }
        } else {
            return (
                group !== "" && category !== "" && subCategory !== "" && priority !== "" && assignee !== "" && status !== ""
                // && status !== 1 && status !== 2 && status !== 4 && status !== 9 && status !== 3
            );
        }
    }

    isReplyReady = () => {
        const { toEnginner, toRequester, replyDescription } = this.state
        const roleId = sessionStorage.getItem("role");
        if (roleId === '3') {
            return (
                toEnginner !== "" && replyDescription !== ""
            );
        } else {
            return (
                toRequester !== "" && replyDescription !== ""
            );
        }
    }

    handleSaveBtnClick = (event: any) => {
        const { category, assignee, subCategory, engineer_file, work_note, paused_reason, supervisor_file, priority, group, status } = this.state
        let id = this.props.match.params.id;
        let { ticket } = this.props;
        const data: any = new FormData()
        let ticketDate = moment().utc(false).format('YYYY-MM-DD HH:mm:ss')
        const roleId = sessionStorage.getItem("role");
            // For admin or supervisors fields
            if (status == 2) {
                data.append("assigned_time", ticketDate)
            }
            data.append('cat_id', category)
            data.append('sub_category_name', subCategory)
            data.append('assignee_id', assignee)
            data.append('ticket_priority_code', priority)
            data.append('group_id', group)

            // For Engineers Fields
            if (engineer_file) {
                for (var x = 0; x < engineer_file.length; x++) {
                    data.append('files', engineer_file[x])
                }
            }
            if(paused_reason) {
                data.append('paused_reason', paused_reason);
            }
            if (status == 3) {
                data.append("resolved_time", ticketDate)
            }
            data.append('work_note', work_note);
            data.append('ticket_status_code', status);
            data.append('isActive', config.ACTIVE)

            // console.log(data)
            this.props.updateTicket(id, data);
            event.preventDefault();
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleAddEmailTo = (email_id:any, user_id: any) => {
        this.setState({ toEnginner: email_id, email_to_id: user_id })
    }
    handleReplySaveBtnClick = (e?: any) => {
        const { toEnginner, toRequester, replyFile, emailRecipents, replyDescription,emailFrom, email_to_id } = this.state
        const data: any = new FormData();
        const roleId = sessionStorage.getItem("role");
        const userId = sessionStorage.getItem("userId");


        if(emailRecipents.length > 0){
            data.append("emailRecipents", JSON.stringify(emailRecipents));
        }
        if (replyFile) {
            for (var x = 0; x < replyFile.length; x++) {
                data.append('files', replyFile[x])
            }
        }
        data.append("description", replyDescription)
        data.append("emailFrom", emailFrom)
        data.append("ticket_id", this.props.match.params.id)

        data.append("email_from_id",userId)

        data.append("emailTo",toEnginner)
        data.append("email_to_id",email_to_id)

        this.props.sendReply(data);
        this.setState({ replyDescription: "", replyFile: e.target.files = null, emailRecipents: [] })
        let docObj = (document as any)
        docObj.getElementById("myFile1").value = null;
        this.closeModal.click();
        // const roleId = sessionStorage.getItem("role");
        // if (roleId === '3') {
        //     return (
        //         toEnginner !== "" && replyDescription !== ""
        //     );
        // } else {
        //     return (
        //         toRequester !== "" && replyDescription !== ""
        //     );
        // }
    }

    handleReplyCustomSaveBtnClick = (e?: any) => {
        const { toEnginner, toRequester, replyFile, emailRecipents, replyDescription, emailFrom, email_to_id } = this.state
        const data: any = new FormData();
        const roleId = sessionStorage.getItem("role");
        const userId = sessionStorage.getItem("userId");

        if (replyFile) {
            for (var x = 0; x < replyFile.length; x++) {
                data.append('files', replyFile[x])
            }
        }
        data.append("description", replyDescription)
        data.append("ticket_id", this.props.match.params.id)

        data.append("email_from_id", userId)

        this.props.sendReply(data);
        this.setState({ replyDescription: "", replyFile: e.target.files = null, emailRecipents: [] })
        let docObj = (document as any)
        docObj.getElementById("myFile2").value = null;
        this.closeModalReply.click();
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const file = 'http://192.168.18.8:3005/b2422c50-c842-11eb-86d8-e302cb8e65c9-JSONforSingleMenuItem-2021-06-08151714.docx'
        let fileTypeReq = ''
        const { isInserted, message, ticket, isLoad, priority, subCategory, pausedStatus, resumeStatus, assignedStatus, status, supervisorStatus, engineerStatus, engineers, types, groups, ticket_discussion_list } = this.props;
        console.log("Ticket data: ", ticket);
        console.log("engineers!@@: ", engineers.length);
        if(ticket_discussion_list.length > 0){
            console.log("ticket_discussion_list",ticket_discussion_list);
        }

        const { category, assignee, group, emailRecipents } = this.state

        // Assignee Data
        let userData: any = [];
        let selectedOption: any = [];
        if (engineers.length > 0) {
            engineers.forEach((element: any) => {
                var user = { value: element.id, label: element.name };
                userData.push(user);
                if (assignee && assignee == element.id) {
                    selectedOption.push(user);
                }
            });
        }
        // Groups Data
        let groupData: any = [];
        let selectedGroup: any = [];
        if (groups.length > 0) {
            groups.forEach((element: any) => {
                var groupp = { value: element.id, label: element.name };
                groupData.push(groupp);
                if (group && group == element.id) {
                    selectedGroup.push(groupp);
                }
            });
        }

        //Category Data
        let catData: any = [];
        let selectedCategory: any = [];
        if (types.length > 0) {
            types.forEach((element: any) => {
                var type = { value: element.id, label: element.cat_name };
                catData.push(type);

                if (category && category == element.id) {
                    selectedCategory.push(type);
                }
            });
        }

        //SubCategory Data
        let subCatData: any = [];
        let selectedSubCategory: any = [];
        if (subCategory.length > 0) {
            subCategory.forEach((element: any) => {
                var subCat = { value: element.id, label: element.name };
                subCatData.push(subCat);

                if (this.state.subCategory && this.state.subCategory == element.name) {
                    selectedSubCategory.push(subCat);
                }
            });
        }
        console.log("subCategory", this.state.subCategory);


        if (ticket.subCategory) {
            this.props.subCategoryList(ticket.cat_id);
        }

        if (ticket.requester_file) {
            fileTypeReq = extname(ticket.requester_file);
            fileTypeReq = fileTypeReq.split('.')[1];
        }
        console.log("ticket.resolved_time", ticket.resolved_time);

        if (isInserted) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        const roleId = sessionStorage.getItem("role");

        console.log("engineer file", ticket.engineer_file);


        return (
            <div className="page">
                <CheckChanges path="/edit-ticket" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex bd-highlight py-3">
                                    <div className="mr-auto bd-highlight">
                                        <h4>Ticket Management</h4>
                                    </div>
                                    {/* {ticket.assignee_email ?
                                        <div className="bd-highlight"><button data-toggle="modal" data-target="#replyModal" title="Reply" className="btn btn-outline-success mr-2">Reply</button></div>
                                        : <div></div>
                                    } */}

                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/tickets" className="text-primary">Tickets</Link></li>
                                <li className="breadcrumb-item active">Update Ticket</li>
                            </ul>
                        </div>
                        <section className="forms pt-3  ">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* <div className="col-lg-6 col-6"> */}
                                    <div className="col-lg-12 col-12">
                                        <div className="card-header">
                                            <strong>{ticket.subject}</strong>
                                            <div className="text-right">
                                                {/* <span title="Reply" className="reply-btn" data-toggle="modal" data-target="#replyModal" ><i className="pl-xl-1 fa fa-reply"></i></span> */}
                                                <a title="Type Message" className="reply-btn" onClick={() => this.handleAddEmailTo(ticket.requester_email, ticket.requester_id)} data-toggle="modal" data-target="#replyModalHeader" ><i className="pl-xl-1 fa fa-reply"></i></a>
                                            </div>
                                        </div>
                                        {/* <button className="btn btn-outline-primary mx-2" type="submit">Reply</button> */}
                                    </div>
                                    {/* <div className="col-lg-6 col-6">
                                        
                                        </div> */}
                                    {/* </div> */}
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-7">
                                        <div className="card">
                                            
                                                {ticket.resolved_time && ticket.resolved_time != "0000-00-00 00:00:00" &&
                                                    <div className="card-body">
                                                        <div className="post-heading">
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <div className="media">
                                                                        <div className="media-left">
                                                                            {ticket.profileImage != "undefined" || ticket.profileImage != undefined ?
                                                                                <img className="media-object photo-profile" src={config.IMAGES_URL + ticket.profileImage} width="40" height="40" alt="..." />
                                                                                :
                                                                                <img className="media-object photo-profile" src={process.env.PUBLIC_URL + "/assets/img/profile_vector.png"} width="40" height="40" alt="..." />
                                                                            }
                                                                        </div>
                                                                        <div className="media-body pl-xl-3">
                                                                            <span className="comment-click anchor-username"><h6 className="m-0 media-heading">{ticket.assignee_name}</h6>({ticket.ticket_status_description})</span>
                                                                            <p className="m-0 forum-date">{moment(ticket.resolved_time).local().format('MMM Do YYYY | hh:mm a ')}</p>
                                                                            {ticket.requester_id && <p className="m-0 forum-date">Sent to: {ticket.requester_name}</p>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 text-right">
                                                                    {/* <span title="Reply" className="reply-btn" data-toggle="modal" data-target="#replyModal" ><i className="pl-xl-1 fa fa-reply"></i></span> */}
                                                                    <a title="Reply" className="reply-btn" onClick={() => this.handleAddEmailTo(ticket.assignee_email, ticket.assignee_id)} data-toggle="modal" data-target="#replyModal" ><i className="pl-xl-1 fa fa-reply"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="post-body pt-xl-3">
                                                            <p className="">{ticket.work_note}</p>
                                                            {ticket.engineer_file &&
                                                                JSON.parse(ticket.engineer_file).length > 0 &&
                                                                JSON.parse(ticket.engineer_file).map((el: any, index: any) => {
                                                                    return (
                                                                        <p><a target="_blank" href={`${config.IMAGES_URL}${el.res}`} download="download" style={{fontSize:"0.8rem"}}>Click to open attachment</a></p>
                                                                    )
                                                                })
                                                            }
                                                            {/* {ticket.engineer_file &&
                                                            <p><a target="_blank" href={`${config.IMAGES_URL}${ticket.engineer_file}`} download="download" style={{fontSize:"0.8rem"}}>Click to open attachment</a></p>
                                                            } */}
                                                        </div>
                                                        <div className="clearfix"></div>
                                                        <div className="clear"></div>
                                                    </div>
                                                }

                                                {ticket_discussion_list.length > 0 &&
                                                    ticket_discussion_list.map((item, index) => (
                                                        <div key={index} className="card-body">
                                                            <div className="post-heading">
                                                                <div className="row">
                                                                    <div className="col-10">
                                                                        <div className="media">
                                                                            <div className="media-left">
                                                                                <a href="#">
                                                                                    {item.email_from_id && item.email_from_id.profileImage != "undefined" ?
                                                                                        <img className="media-object photo-profile" src={config.IMAGES_URL + item.email_from_id.profileImage} width="40" height="40" alt="..." />
                                                                                        :
                                                                                        <img className="media-object photo-profile" src={process.env.PUBLIC_URL + "/assets/img/profile_vector.png"} width="40" height="40" alt="..." />
                                                                                    }
                                                                                </a>
                                                                            </div>
                                                                            <div className="media-body pl-xl-3">
                                                                                <span className="comment-click anchor-username"><h6 className="m-0 media-heading">{item.email_from_id && item.email_from_id.name}</h6></span>
                                                                                <p className="m-0 forum-date">{moment(item.createdAt).local().format('MMM Do YYYY | hh:mm a ')}</p>
                                                                                {item.email_to_id ?
                                                                                    <div>
                                                                                        <p className="m-0 forum-date"><span className="text-bold">Sent to:</span> {item.email_to_id && item.email_to_id.name}</p>

                                                                                        {item.emailRecipents &&
                                                                                            <p className="m-0 forum-date"><span className="text-bold">CC: </span>
                                                                                                {JSON.parse(item.emailRecipents).length > 0 ?
                                                                                                    JSON.parse(item.emailRecipents).map((obj: any) => {
                                                                                                        return (
                                                                                                            `, ${obj.id}`
                                                                                                        )
                                                                                                    }) : ""}
                                                                                            </p>
                                                                                        }
                                                                                    </div>
                                                                                    : ""
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 text-right">
                                                                        <a title="Reply" className="reply-btn" onClick={() => this.handleAddEmailTo(item.email_from_id.email, item.email_from_id.id)} data-toggle="modal" data-target="#replyModal" ><i className="pl-xl-1 fa fa-reply"></i></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="post-body pt-xl-3">
                                                                <p className="">{item.description}</p>
                                                                {/* {item.replyFile && <p className="">{item.description}Click to download attachment</p>} */}
                                                                {item.replyFile && 
                                                                    JSON.parse(item.replyFile).length > 0 ?
                                                                    JSON.parse(item.replyFile).map((ele: any) => {
                                                                        return (
                                                                            <p><a target="_blank" href={`${config.IMAGES_URL}${ele.res}`} download="download" style={{ fontSize: "0.8rem" }}>Click to open attachment</a></p>
                                                                        )
                                                                    }) : ""
                                                                }
                                                            </div>
                                                            <div className="clearfix"></div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    ))
                                                }
                                                <div className="card-body">
                                                    <div className="post-heading">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <div className="media">
                                                                    <div className="media-left">
                                                                        {ticket.profileImage != "undefined" ?
                                                                            <img className="media-object photo-profile" src={config.IMAGES_URL + ticket.profileImage} width="40" height="40" alt="..." />
                                                                            :
                                                                            <img className="media-object photo-profile" src={process.env.PUBLIC_URL + "/assets/img/profile_vector.png"} width="40" height="40" alt="..." />
                                                                        }
                                                                    </div>
                                                                    <div className="media-body pl-xl-3">
                                                                        <span className="comment-click anchor-username"><h6 className="m-0 media-heading">{ticket.requester_name}</h6></span>
                                                                        <p className="m-0 forum-date">{moment(ticket.recieved_time).local().format('MMM Do YYYY | hh:mm a ')}</p>
                                                                        {ticket.assignee_id && <p className="m-0 forum-date">Sent to: {ticket.assignee_name}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 text-right">
                                                                <a title="Reply" className="reply-btn" onClick={() => this.handleAddEmailTo(ticket.requester_email, ticket.requester_id)} data-toggle="modal" data-target="#replyModal" ><i className="pl-xl-1 fa fa-reply"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="post-body pt-xl-3">
                                                        <p className="">{ticket.description}</p>
                                                        {ticket.requester_file &&
                                                            JSON.parse(ticket.requester_file).length > 0 &&
                                                            JSON.parse(ticket.requester_file).map((el: any) => {
                                                                return (
                                                                    <p><a target="_blank" href={`${config.IMAGES_URL}${el.req}`} download="download" style={{ fontSize: "0.8rem" }}>Click to open attachment</a></p>
                                                                )
                                                            })
                                                        }
                                                        {/* {ticket.supervisor_file &&
                                                            JSON.parse(ticket.supervisor_file).length > 0 &&
                                                            JSON.parse(ticket.supervisor_file).map((el: any) => {
                                                                return (
                                                                    <p><a target="_blank" href={`${config.IMAGES_URL}${el.res}`} download="download" style={{ fontSize: "0.8rem" }}>Click to open attachment</a></p>
                                                                )
                                                            })
                                                        }
                                                        {ticket.engineer_file &&
                                                            JSON.parse(ticket.engineer_file).length > 0 &&
                                                            JSON.parse(ticket.engineer_file).map((el: any) => {
                                                                return (
                                                                    <p><a target="_blank" href={`${config.IMAGES_URL}${el.res}`} download="download" style={{ fontSize: "0.8rem" }}>Click to open attachment</a></p>
                                                                )
                                                            })
                                                        } */}
                                                    </div>
                                                    <div className="clearfix"></div>
                                                    <div className="clear"></div>
                                                </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="form-horizontal">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label font-weight-bold">Subject</label>
                                                                <input id="subject" type="text" disabled name="subject" required data-msg="Please enter Ticket Name" defaultValue={ticket.subject} className="input-material input-material-disabled" onChange={this.handleInputChange} />

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label font-weight-bold">Requester Name</label>
                                                                <input disabled defaultValue={ticket.requester_name} className="input-material input-material-disabled" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row ">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label font-weight-bold">Email Address</label>
                                                                <input id="email" type="text" disabled name="email" required data-msg="Please enter Ticket Name" defaultValue={ticket.requester_email} className="input-material input-material-disabled" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label font-weight-bold">Phone Number</label>
                                                                <input id="phone" type="text" disabled name="phone" required data-msg="Please enter Ticket Name" defaultValue={ticket.phone} className="input-material input-material-disabled" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label font-weight-bold">Description</label>
                                                                <textarea name="description" disabled required data-msg="Please enter Issue Description" defaultValue={ticket.description} className="form-control rounded-0 input-material-disabled" onChange={this.handleInputChange} id="exampleFormControlTextarea1" rows={6}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Attachment</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            {/* <textarea name="rfile" disabled required data-msg="Please enter Issue Description" defaultValue={ticket.description} className="form-control rounded-0" onChange={this.handleInputChange} id="exampleFormControlTextarea1" rows={6}></textarea> */}
                                                    {/* <iframe src={`${BASE_URL}${ticket.requester_file}`} style={{ overflow: "hidden", overflowX: "hidden", overflowY: "hidden", height: "100%", width: "100%", position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px" }} height="100%" width="100%"></iframe> */}
                                                    {/* <iframe src={`${BASE_URL}${ticket.requester_file}`} frameBorder="0" width="100%" height="100%" allowFullScreen={true}></iframe> */}
                                                    {/* <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQGTf0669TQ5W89zyd2afEIK1aZhw_dDUbzXxmqF9N3sf-3ra01_9LcUs3k35boOm6e5p0RKrQN97Ni/pub?start=true&loop=false&delayms=3000" frameBorder="0" width="100%" height="100%" allowFullScreen={true}></iframe>
                                                        </div>
                                                    </div> */}
                                                    {(roleId === '5'  || roleId === '3')?
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Assignee Group<span className="text-danger">*</span></label>
                                                                    <input disabled defaultValue={ticket.group_name} className="input-material input-material-disabled" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Assignee Group<span className="text-danger">*</span></label>
                                                                        <Select
                                                                            value={selectedGroup}
                                                                            closeMenuOnSelect={true}
                                                                            defaultValue={{label: 'Select Group', value:0}}
                                                                            // isDisabled={ticket.ticket_status_code == 4 || ticket.ticket_status_code == 9 || ticket.ticket_status_code == 2}
                                                                            name="group"
                                                                            //isMulti
                                                                            //isClearable
                                                                            options={groupData}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            onChange={(e: any) => this.handleInputChangeGroup(e)}
                                                                        />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {(roleId === '5' || roleId === '3' ) ?
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Assigned To<span className="text-danger">*</span></label>
                                                                    <input disabled defaultValue={ticket.assignee_name} className="input-material input-material-disabled" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Assigned To<span className="text-danger">*</span></label>
                                                                        <Select
                                                                            ref={ref => {this.selectRef = ref }}
                                                                            defaultValue={{label: 'Select Engineer', value:0}}
                                                                            value={selectedOption}
                                                                            closeMenuOnSelect={true}
                                                                            name="assignee"
                                                                            // isDisabled={ticket.ticket_status_code == 4 || ticket.ticket_status_code == 9 || ticket.ticket_status_code == 2}
                                                                            //isMulti
                                                                            //isClearable
                                                                            options={userData}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            onChange={(e: any) => this.handleAssigneeInputChange(e)}
                                                                        />
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {(roleId === '5' || roleId === '3' ) ?
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Select Category<span className="text-danger">*</span></label>
                                                                    <input disabled defaultValue={ticket.category_name} className="input-material input-material-disabled" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Select Category<span className="text-danger">*</span></label>
                                                                        <Select
                                                                            value={selectedCategory}
                                                                            closeMenuOnSelect={true}
                                                                            defaultValue={{label: 'Select Category', value:0}}
                                                                            // isDisabled={ticket.ticket_status_code == 4 || ticket.ticket_status_code == 9 || ticket.ticket_status_code == 2}
                                                                            name="category"
                                                                            //isMulti
                                                                            //isClearable
                                                                            options={catData}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            onChange={(e: any) => this.handleInputChangeCategory(e)}
                                                                        />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {(roleId === '5' || roleId === '3' ) ?
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Select Sub Category<span className="text-danger">*</span></label>

                                                                    <input disabled defaultValue={ticket.sub_category_name} className="input-material input-material-disabled" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Select Sub Category<span className="text-danger">*</span></label>
                                                                        <Select
                                                                            ref={ref => {this.selectRefCat = ref }}
                                                                            value={selectedSubCategory}
                                                                            closeMenuOnSelect={true}
                                                                            name="subCategory"
                                                                            defaultValue={{label: 'Select Sub Category', value:0}}
                                                                            // isDisabled={ticket.ticket_status_code == 4 || ticket.ticket_status_code == 9 || ticket.ticket_status_code == 2}
                                                                            //isMulti
                                                                            isClearable
                                                                            options={subCatData}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            onChange={(e: any) => this.handleSubCategoryInputChange(e)}
                                                                        />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {(roleId === '5' || roleId === '3' ) ?
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Ticket Priority<span className="text-danger">*</span></label>

                                                                    <input disabled defaultValue={ticket.ticket_priority_description} className="input-material input-material-disabled" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Ticket Priority<span className="text-danger">*</span></label>

                                                                    <select name="priority" value={this.state.priority} className="form-control text-capitalize mt-2" required data-msg="Please select priority" onChange={this.handleInputChange}>
                                                                        <option>Select Priority</option>
                                                                        {priority &&
                                                                            priority.map((p, index) => (
                                                                                <option key={index} value={p.ticket_priority_code} {...ticket.id === p.ticket_priority_code && { selected: true }}>{p.ticket_priority_description}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {(roleId === '5' || roleId === '3' ) ?
                                                        <>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Current Status</label>
                                                                    <input disabled value={ticket.ticket_status_description} className="form-control text-capitalize input-material-disabled mt-2" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Ticket Status<span className="text-danger">*</span></label>
                                                                        {(ticket.ticket_status_code == 9) ?
                                                                            <select name="status" defaultValue={this.state.status} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                                <option>Change Status</option>
                                                                                {(ticket.ticket_status_code !== 9 && pausedStatus) ?
                                                                                pausedStatus.map((value, index) => (
                                                                                    <option key={index} value={value.ticket_status_code} {...ticket.id === value.ticket_status_code && { selected: true }}>{value.ticket_status_description}</option>
                                                                                )) :
                                                                                engineerStatus && engineerStatus.map((value, index) => (
                                                                                    <option key={index} value={value.ticket_status_code} {...ticket.id === value.ticket_status_code && { selected: true }}>{value.ticket_status_description}</option>
                                                                                ))
                                                                                }
                                                                            </select>   
                                                                            :
                                                                            (ticket.ticket_status_code == 4) ?
                                                                            <select name="status" defaultValue={this.state.status} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                                <option>Select Status</option>
                                                                                {resumeStatus &&
                                                                                resumeStatus.map((value, index) => (
                                                                                    <option key={index} value={value.ticket_status_code} {...ticket.id === value.ticket_status_code && { selected: true }}>{value.ticket_status_description}</option>
                                                                                )) }
                                                                            </select>:
                                                                            (ticket.ticket_status_code == 3) ?
                                                                            <select className="form-control text-capitalize mt-2">
                                                                                <option value={ticket.ticket_status_code}>{ticket.ticket_status_description}</option>
                                                                            </select>:
                                                                            <select name="status" value={this.state.status} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                                <option>Select Status</option>
                                                                                {engineerStatus &&
                                                                                 engineerStatus.map((value, index) => (
                                                                                    <option key={index} value={value.ticket_status_code} {...ticket.id === value.ticket_status_code && { selected: true }}>{value.ticket_status_description}</option>
                                                                                )) }  
                                                                            </select>
                                                                        }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                        : (roleId === '1' || roleId === '4') ?
                                                            <>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label font-weight-bold">Current Status</label>
                                                                        <input disabled value={ticket.ticket_status_description} className="form-control text-capitalize input-material-disabled mt-2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label font-weight-bold">Ticket Status<span className="text-danger">*</span></label>
                                                                        <select name="status" value={this.state.status} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                            <option>Select Status</option>
                                                                            {(ticket.ticket_status_code == 1) ?
                                                                                supervisorStatus && supervisorStatus.map((value, index) => (
                                                                                    <option key={index} value={value.ticket_status_code} {...ticket.id === value.ticket_status_code && { selected: true }}>{value.ticket_status_description}</option>
                                                                                )) :
                                                                            (ticket.ticket_status_code == 2 || ticket.ticket_status_code == 9) ?
                                                                                assignedStatus && assignedStatus.map((value, index) => (
                                                                                    <option key={index} value={value.ticket_status_code} {...ticket.id === value.ticket_status_code && { selected: true }}>{value.ticket_status_description}</option>
                                                                                )) :
                                                                            (ticket.ticket_status_code == 4) ?
                                                                                resumeStatus && resumeStatus.map((value, index) => (
                                                                                    <option key={index} value={value.ticket_status_code} {...ticket.id === value.ticket_status_code && { selected: true }}>{value.ticket_status_description}</option>
                                                                                )) :        
                                                                                status &&
                                                                                status.map((value, index) => (
                                                                                    <option key={index} value={value.ticket_status_code} {...ticket.id === value.ticket_status_code && { selected: true }}>{value.ticket_status_description}</option>
                                                                                )) 
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </>
                                                            :
                                                            <>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label font-weight-bold">Current Status</label>
                                                                        <input disabled value={ticket.ticket_status_description} className="form-control text-capitalize input-material-disabled mt-2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label font-weight-bold">Ticket Status<span className="text-danger">*</span></label>
                                                                        <select name="status" value={this.state.status} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                            <option>Change Status</option>
                                                                            {assignedStatus.length > 0 &&
                                                                                assignedStatus.map((value, index) => (
                                                                                    <option key={index} value={value.ticket_status_code} {...ticket.id === value.ticket_status_code && { selected: true }}>{value.ticket_status_description}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </>
                                                     }
                                                        {(this.state.status == 4) && 
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label font-weight-bold">Paused Reason <span className="text-danger">*</span></label>
                                                                        <textarea name="paused_reason" required data-msg="Please enter Paused reason" className="form-control rounded-0 input-material-disabled" onChange={this.handleInputChange} id="exampleFormControlTextarea1" rows={5}></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    {(this.state.status == 3) &&
                                                        ((roleId === '5' || roleId === '3' ) ?
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Work Notes {(this.state.status == 3 || ticket.ticket_status_code == 2) && <span className="text-danger">*</span>}</label>
                                                                    <textarea defaultValue={ticket.work_note} disabled={this.state.status == 4 || this.state.status == 9} name="work_note" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Work Note" placeholder="Write your note here..." className="form-control rounded-0 input-material-disabled" onChange={this.handleInputChange} id="exampleFormControlTextarea1" rows={7}></textarea>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Work Notes</label>
                                                                    <textarea defaultValue={ticket.work_note} disabled={ticket.ticket_status_code == 1 || this.state.status == 4 || this.state.status == 9} name="work_note" required data-msg="Please enter Work Note" placeholder="Write your note here..." className="form-control rounded-0 input-material-disabled" onChange={this.handleInputChange} id="exampleFormControlTextarea1" rows={7}></textarea>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    } 
                                                    {(this.state.status == 3) &&   
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label font-weight-bold">Select Attachment</label>
                                                                    <input id="files" type="file" name="files" disabled={ticket.ticket_status_code == 1 || ticket.ticket_status_code == 4} className=" btn btn-primary form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                {isLoad ? <Loader style={{ display: "block" }} type="ThreeDots" color="#00BFFF" height={50} width={50} /> :
                                                    ((roleId != '3' && ticket.ticket_status_code !== 3) &&
                                                    <div className="form-group mt-3">
                                                        <button className='btn btn-primary' disabled={!this.isTicketReady()} onClick={this.handleSaveBtnClick}>Update Ticket</button>
                                                    </div>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
                {/* File Viewer */}
                <div id="requesterAttachment" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Requester Attachment</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">

                                {/* {ticket.requester_file &&
                                            <FileViewer
                                                fileType={fileTypeReq}
                                                filePath={`${config.IMAGES_URL}${ticket.requester_file}`}
                                            />
                                        } */}
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* Reply From Requester */}
                <div id="replyModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Reply</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="form-horizontal">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label font-weight-bold">To<span className="text-danger">*</span></label>
                                                <input id="toEnginner" type="text" disabled name="toEnginner" required data-msg="Please enter Email" defaultValue={this.state.toEnginner} className="input-material input-material-disabled" />
                                                {/* {roleId === '5' ?
                                                    <input id="toEnginner" type="text" disabled name="toEnginner" required data-msg="Please enter Email" defaultValue={ticket.assignee_email} className="input-material input-material-disabled" onChange={this.handleInputChange} />
                                                    :
                                                    <input id="toRequester" type="text" disabled name="toRequester" required data-msg="Please enter Email" defaultValue={ticket.requester_email} className="input-material input-material-disabled" onChange={this.handleInputChange} />
                                                } */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label font-weight-bold">CC</label>
                                                <div>
                                                    <ReactTags
                                                        placeholder="Press enter to add email."
                                                        tags={emailRecipents}
                                                        value={this.state.emailRecipents}
                                                        suggestions={suggestions}
                                                        handleDelete={this.handleDeleteTag}
                                                        handleAddition={this.handleAdditionTag}
                                                        handleDrag={this.handleDragTag}
                                                        // classNames={{ tag: (this.state.isValidEmail == false && emailRecipents.length > 0) && "bg-danger text-white" }}
                                                        // style={{ color : (this.state.isValidEmail == false && emailRecipents.length > 0) ? "red" : "" }}
                                                        delimiters={delimiters} />
                                                        {(this.state.isValidEmail == false && emailRecipents.length > 0) &&
                                                            <small className="form-text text-danger">Invalid Email</small>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label font-weight-bold">Description<span className="text-danger">*</span></label>
                                                <textarea name="replyDescription" required data-msg="Please enter Description" value={this.state.replyDescription} className="form-control rounded-0 input-material-disabled" onChange={this.handleInputChange} id="exampleFormControlTextarea1" rows={6}></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label font-weight-bold">Select Attachment</label>
                                                <input id="myFile1" type="file" name="files" multiple className=" btn btn-primary form-control-file" onChange={this.fileSelectedHandlerReply} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" ref={el => this.closeModal = el} data-dismiss="modal" className="btn btn-secondary">Close</button>
                                {isLoad ? <Loader style={{ display: "block" }} type="ThreeDots" color="#00BFFF" height={50} width={50} /> :
                                <button className="btn btn-primary" disabled={!this.isReplyReady()} onClick={this.handleReplySaveBtnClick}>Send</button>
                                }
                                {/* <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Reply From Requester without send emails */}
                <div id="replyModalHeader" role="dialog" aria-labelledby="exampleModalLabelHeader" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Reply</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">

                                <div className="form-horizontal">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label font-weight-bold">Description<span className="text-danger">*</span></label>
                                                <textarea name="replyDescription" required data-msg="Please enter Description" value={this.state.replyDescription} className="form-control rounded-0 input-material-disabled" onChange={this.handleInputChange} id="exampleFormControlTextarea1" rows={6}></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label font-weight-bold">Select Attachment</label>
                                                <input id="myFile2" type="file" name="files" className="btn btn-primary form-control-file" onChange={this.fileSelectedHandlerReply} multiple />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" ref={el => this.closeModalReply = el} data-dismiss="modal" className="btn btn-secondary">Close</button>
                                {isLoad ? <Loader style={{ display: "block" }} type="ThreeDots" color="#00BFFF" height={50} width={50} /> :
                                    <button className="btn btn-primary" disabled={!this.isReplyReady()} onClick={this.handleReplyCustomSaveBtnClick}>Send</button>
                                }
                                {/* <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
console.log("state", state);

    return {
        ticket: state.ticket.ticket,
        priority: state.ticket.priority,
        status: state.ticket.status,
        supervisorStatus: state.ticket.supervisorStatus,
        engineerStatus: state.ticket.engineerStatus,
        pausedStatus: state.ticket.pausedStatus,
        resumeStatus: state.ticket.resumeStatus,
        assignedStatus: state.ticket.assignedStatus,
        types: state.ticket.types,
        subCategory: state.ticket.subCategory,
        engineers: state.ticket.engineers,
        groups: state.ticket.groups,
        message: state.ticket.message,
        isInserted: state.ticket.isInserted,
        ticket_discussion_list: state.ticket.ticket_discussion_list,
        isLoad: state.ticket.isLoad
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateTicketBySupervisor: function (id: any, data: any) {
            dispatch(updateTicketBySupervisor(id, data));
        },
        updateTicket: function (id: any, data: any) {
            dispatch(updateTicket(id, data));
        },
        sendReply: function (data: any) {
            dispatch(sendReply(data));
        },
        priorityList: function () {
            dispatch(priorityList());
        },
        statusList: function () {
            dispatch(statusList());
        },
        groupList: function () {
            dispatch(groupList());
        },
        getTicket: function (id: any) {
            dispatch(getTicket(id));
        },
        getAllTicketDiscussionByTicket: function (id: any) {
            dispatch(getAllTicketDiscussionByTicket(id));
        },
        typeList: function () {
            dispatch(typeList());
        },
        subCategoryList: function (id: any) {
            dispatch(subCategoryList(id))
        },
        engineersList: function (id: any) {
            dispatch(engineersList(id));
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditTicket);