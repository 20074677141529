import {GROUP_BY_ZONES_GET, ZONE_GET, GROUP_LIST, USER_GET, USER_LIST, ZONE_LIST} from './types'
import Api from '../../components/Api'
import { Redirect } from 'react-router-dom';
import React from 'react';


export const groupList = () => {
    return function (dispatch: any) {
        // console.log("JAWAD SHAH");

        let token: any = sessionStorage.getItem('token');
        Api.get('/group/getAllGroups')
        // {
        //     headers: { 'Authorization': 'Bearer ' + token }
        // })
            .then((response) => {
                // console.log("response",response);
                    dispatch({
                        type: GROUP_LIST,
                        payload: response.data.data
                    })
                
            }).catch(err => {
                console.log("err",err);
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const zoneList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/zone/getAll')
        // {
        //     headers: { 'Authorization': 'Bearer ' + token }
        // })
            .then((response) => {
                    dispatch({
                        type: ZONE_LIST,
                        payload: response.data.data
                    })
                
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getGroupByZones = (id:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        // console.log("id",id);
        Api.get(`/zone/getZoneGroups/${id}`)
        // {
        //     headers: { 'Authorization': 'Bearer ' + token }
        // })
            .then((response) => {
                //   console.log("response group",response)
                    dispatch({
                        type: GROUP_BY_ZONES_GET,
                        payload: response.data.data
                    })
                
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const addZone = (data:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        console.log("addZONE",data);
        // Api.post('/user/add',data)
        // {
        //     headers: { 'Authorization': 'Bearer ' + token }
        // })
        Api.post('/zone/add',data)
            .then((response) => {
                    console.log("response",response);
                    window.location.href="/zones";
                
            }).catch(err => {
                console.log("err",err);
                if (err.response) {
                    console.log("err.response",err.response)
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_DUP_ENTRY") {
                            error = "UserName Already Exist"
                            // alert(error);
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                        // alert(error);
                    }
                    // dispatch({
                    //     type: USER_ADD,
                    //     isInserted: false,
                    //     payload: error
                    // })


                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getZone = (id:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/zone/get/${id}`)
        // {
        //     headers: { 'Authorization': 'Bearer ' + token }
        // })
            .then((response) => {
                  console.log("zone get",response);
                    dispatch({
                        type: ZONE_GET,
                        payload: response.data.data
                    })
                
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateZone = (id:any,data:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/zone/update/${id}`,data)
        // {
        //     headers: { 'Authorization': 'Bearer ' + token }
        // })
            .then((response) => {
                    // console.log("response",response);
                window.location.href="/zones";
                
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deleteZone = (id:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/zone/delete/${id}`)
        // {
        //     headers: { 'Authorization': 'Bearer ' + token }
        // })
            .then((response) => {
                    console.log("response",response);
                    window.location.href="/zones";
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}