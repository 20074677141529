import {
  USER_LIST, USER_GET, ROLE_LIST, ENGINEER_ADMINS, LOGIN_USER, FORGOT_PASS, COURSE_GROUP_LIST, USERS_BY_COURSE_GROUPS, GROUP_LIST, ENGINEER_LIST
} from '../actions/types';
const initialState: any = {
  roleList: [] || '',
  userList: [] || '',
  groups: [] || '',
  courseGroupList: [] || '',
  userCourseGroup: [] || '',
  userGet: {},
  engineers: [] || "",
  engineers_admins: [] || "",
  isLoggedIn: "",
  isReset: ""

};
const UserReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USER_LIST:
      return {
        ...state,
        userList: action.payload
      };
      case ENGINEER_LIST:
        return {
          ...state,
          engineers: action.payload
        };
        case ENGINEER_ADMINS:
          return {
            ...state,
            engineers_admins: action.payload
          };    
    case USER_GET:
      return {
        ...state,
        userGet: action.payload
      };
    case USERS_BY_COURSE_GROUPS:
      return {
        ...state,
        userCourseGroup: action.payload
      };

    case ROLE_LIST:
      return {
        ...state,
        roleList: action.payload
      };
    case GROUP_LIST:
      return {
        ...state,
        groups: action.payload
      };

    case COURSE_GROUP_LIST:
      return {
        ...state,
        courseGroupList: action.payload
      };

    case FORGOT_PASS: return {
      isReset: action.isReset,
      message: action.payload
    }

    case LOGIN_USER: return {
      isLoggedIn: action.isLoggedIn,
      message: action.payload
    }


    default:
      return state;
  }
};
export default UserReducer;