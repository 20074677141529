import React, { Component } from 'react';
import { EditUserProps, EditUserState } from '../../interfaces/user'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import CheckChanges from '../confirmOnLeave';
import { Link, Redirect } from 'react-router-dom';
import { addUser, courseGroupList, getCourseGroupByUser, getUser, groupList, logoutUser, roleList, updateUser } from '../../redux/actions/user';
import config from '../config/setting'
import Select from 'react-select';

class EditUser extends Component<EditUserProps, EditUserState> {
    id: any;
    constructor(props: any) {
        super(props);
        this.id = this.props.match.params.id;
        this.state = {
            name: "",
            role: [],
            group: "",
            courseGroup: [],
            email: "",
            phone: "",
            address: "",
            visaIssue: "",
            visaExpire: "",
            visaImage: "",
            passportExpire: "",
            passportIssue: "",
            passportImage: "",
            status: config.ACTIVE,
            isValidEmail: true,
            isValidPhone: true,
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        // this.props.brandsList();
        // this.props.channelsList();
        // this.props.storeTypeList();
        // this.props.areasList();
        // this.props.countryList();
        this.props.getUser(this.id);
        this.props.roleList();
        this.props.groupList();
        this.props.courseGroupList();
        this.props.getCourseGroupByUser(this.id);
    }
    componentWillReceiveProps(nextProps: any) {
        console.log("nextProps", nextProps.user);
        var userObj: any = {};
        for (var i = 0; i < nextProps.user.length; i++) {
            userObj = nextProps.user[i];
        }
        console.log("nextPropsObj", userObj);
        // let coursesGroupIds:any = [];
        // if(nextProps && nextProps.courseGroupsUser && nextProps.courseGroupsUser.length > 0){
        //     nextProps.courseGroupsUser.forEach((element:any) => {
        //         coursesGroupIds.push(element.courseGroup.id);
        //     });
        // }
        // console.log("coursesGroupIds",coursesGroupIds);   
        this.setState({
            name: userObj.name,
            //role: (nextProps && nextProps.user.role) && nextProps.user[0].role,
            //group: (nextProps && nextProps.user.group) && nextProps.user.group.id,
            // courseGroup: coursesGroupIds,
            role: userObj.role,
            group: userObj.group,
            email: userObj.email,
            phone: userObj.phone,
            address: userObj.address,
            visaIssue: userObj.visaIssue,
            visaExpire: userObj.visaExpire,
            visaImage: userObj.visaImage,
            passportExpire: userObj.passportExpire,
            passportIssue: userObj.passportIssue,
            passportImage: userObj.passportImage,
            status: userObj.status,
        });
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }
    VisaFileSelectedHandler = (e: any) => {
        this.setState({ visaImage: e.target.files })
    }
    PassportFileSelectedHandler = (e: any) => {
        this.setState({ passportImage: e.target.files })
    }
    isUserReady = () => {
        const { name, role, group, courseGroup, email, phone, address, status } = this.state
        return (name !== "" && role !== "" && group !== "" && email !== "" && phone !== "" && status !== "" && address !== "");
    }
    handleGroupsInputChange = (e: any) => {
        console.log("eeeeeeeeeee", e);
        if (e && e.value > 0) {
            this.setState({ group: e.value });
        } else {
            this.setState({ group: "" });
        }
    };

    handleItemsInputChange = (e: any) => {
        console.log("e", e);
        let array: any = [];
        e &&
            e.map((obj: any) => {
                array.push(obj.value)
            })
        this.setState({ courseGroup: array })
    };

    validateEmail = (event: { target: { name: any; value: any; }; }) => {
        var reg = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (reg.test(event.target.value) === false) {
          this.setState({ email: event.target.value, isValidEmail: false })
        } else {
          this.setState({ email: event.target.value, isValidEmail: true });
        }
        };
    validatePhone = (event: { target: { name: any; value: any; }; }) => {
        let maxNum = 11;
        if (event.target.value.length > maxNum) {
        event.target.value = event.target.value.slice(0, maxNum);
        }
        let reg = /[^0-9]/;
        if (reg.test(event.target.value) == false) {
        this.setState({ phone: event.target.value, isValidPhone: true })
        } else {
        this.setState({ phone: event.target.value.replace(''), isValidPhone: false });
        }
    };

    handleSaveBtnClick = (event: any) => {
        let { name, role, group, phone, address, status, visaIssue, visaExpire, visaImage, passportExpire, passportIssue, passportImage } = this.state;

        // console.log("visaImage[0]",visaImage[0]);
        // console.log("passportImage[0]",passportImage[0]);

        const data: any = new FormData();
        if (passportImage) {
            data.append('files', passportImage[0]);
        }
        data.append('name', name);
        data.append('role', role);
        data.append('group', group);
        // data.append('email', email);
        data.append('phone', phone);
        data.append('address', address);
        data.append('status', status);
        data.append('profileImage', passportIssue);
        //data.append('isActive', config.ACTIVE);

        this.props.updateUser(this.id, data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        // const { isInserted, message, brands, channels, types } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }
        let { groups } = this.props;
        let { roles } = this.props;
        let { user } = this.props;
        let { courseGroups } = this.props;
        let { courseGroupsUser } = this.props;
        // let {courseGroup} = this.state;

        // let courseGroupData: any = [];
        // if (courseGroups && courseGroups.length > 0) {
        //     courseGroups.forEach(element => {
        //         var group = { label: element.name, value: element.id };
        //         courseGroupData.push(group);
        //     })
        // }

        // let selectedCourseGroup: any = [];
        // if (courseGroupsUser != undefined) {
        //     for (let i = 0; i < courseGroupsUser.length; i++) {
        //         const element = courseGroupsUser[i].courseGroup;
        //         var group = { value: element.id, label: element.name };
        //         // courseGroup.push(element.id);
        //         selectedCourseGroup.push(group);
        //     }
        // }

        // Groups Data
        let groupData: any = [];
        let selectedGroup: any = [];
        if (groups.length > 0) {
            groups.forEach((element: any) => {
                var groupp = { value: element.id, label: element.name };
                groupData.push(groupp);

                if(this.state.group && this.state.group == element.id){
                    selectedGroup.push(groupp);
                }         
            });
        }

        console.log("roles", roles);
        console.log("groups", groups);
        console.log("user", user);

        //convert
        var userObj: any = {};
        for (var i = 0; i < user.length; i++) {
            userObj = user[i];
        }

        console.log("userObj", userObj);

        return (
            <div className="page">
                <CheckChanges path="/edit-user" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Users Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/users" className="text-primary">Users</Link></li>
                                <li className="breadcrumb-item active">Edit User</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body pt-xl-0">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="card-header card-header-info d-flex align-items-center">
                                                    <h5>Personal Information</h5>
                                                </div>

                                                <div className="form-horizontal p-xl-4">

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="name" type="text" name="name" defaultValue={userObj.name} required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Role<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="role" value={this.state.role} className="form-control text-capitalize mt-2" required data-msg="Please select role" onChange={this.handleInputChange}>
                                                                {(userObj && roles.length > 0) &&
                                                                    roles.map((role: any, index: any) => (
                                                                        // <option key={index} value={role.id}>{role.name}</option>
                                                                        <option key={index} value={role.id} {...userObj.id === role.id && { selected: true }}>{role.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Group<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                        {groups.length > 0 &&
                                                                    <Select
                                                                        value={selectedGroup}
                                                                        closeMenuOnSelect={true}
                                                                        name="group"
                                                                        //isMulti
                                                                        //isClearable
                                                                        options={groupData}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        onChange={(e: any) => this.handleGroupsInputChange(e)}
                                                                    />
                                                                }
                                                            {/* <select name="group" value={this.state.group} className="form-control text-capitalize mt-2" required data-msg="Please select role" onChange={this.handleInputChange}>
                                                                <option>Select Group</option>
                                                                {groups &&
                                                                    groups.map((group: any, index: any) => (
                                                                        <option key={index} value={group.id} {...userObj.id === group.id && { selected: true }}>{group.name}</option>
                                                                    ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Address<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="address" type="text" name="address" defaultValue={userObj.address} required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    {/* Email Info */}
                                                    {/* <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Email<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="email" type="email" name="email" defaultValue={userObj.email} required data-msg="Please enter Email" className="input-material" onChange={this.validateEmail} />
                                                            {(this.state.isValidEmail == false && this.state.email !== "") &&
                                                            <small className="form-text text-danger">Invalid Email</small>}
                                                        </div>
                                                    </div> */}

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Phone<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="phone" type="text" name="phone" defaultValue={userObj.phone} required data-msg="Please enter Phone" className="input-material" onChange={this.validatePhone} />
                                                            {(this.state.isValidPhone == false && this.state.phone !== "") &&
                                                            <small className="form-text text-danger">Invalid Phone Number</small>}
                                                        </div>
                                                    </div>

                                                </div>


                                                <div className="card-header card-header-info d-flex align-items-center">
                                                    <h5>Status Information</h5>
                                                </div>

                                                <div className="form-horizontal p-xl-4">
                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="status" defaultValue={userObj.status} className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option {...userObj.status == config.ACTIVE && { selected: true }} value={config.ACTIVE}>Active</option>
                                                                <option {...userObj.status == config.INACTIVE && { selected: true }} value={config.INACTIVE}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isUserReady()} onClick={this.handleSaveBtnClick}>Update User</button>
                                                </div>
                                            </div>

                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    console.log("state", state);
    return {
        groups: state.user.groups,
        roles: state.user.roleList,
        user: state.user.userGet,
        courseGroups: state.user.courseGroupList,
        courseGroupsUser: state.user.userCourseGroup,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {

        groupList: function () {
            dispatch(groupList())
        },
        roleList: function () {
            dispatch(roleList())
        },
        updateUser: function (id: any, data: any) {
            dispatch(updateUser(id, data));
        },
        getUser: function (id: any) {
            dispatch(getUser(id));
        },
        courseGroupList: function () {
            dispatch(courseGroupList())
        },
        getCourseGroupByUser: function (id: any) {
            dispatch(getCourseGroupByUser(id))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
        // storesList: function () {
        //     dispatch(storesList())
        // },
        // addGroup: function (data: any) {
        //     dispatch(addGroupStore(data));
        // }
        // deleteStore: function (id:any) {
        //     dispatch(deleteStore(id))
        // }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);