import React, { Component } from 'react';
import { Link } from 'react-router-dom'

export default class Footer extends Component<{}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    render() {
        let currentYear = new Date().getFullYear();
        return (
            <footer className="main-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <p>SimpleX &copy; 2018-{currentYear}</p>
                        </div>
                        <div className="col-sm-6 text-right">
                        <p>Powered By <a href="https://simplexts.net/" className="external">SimpleX Technology Solutions</a> </p>
                        </div>
                    </div>
                </div>
            </footer >
        );
    }
};