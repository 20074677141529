import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { connect } from 'react-redux'
import _ from 'lodash'
import jwt from 'jsonwebtoken'
import jwt_decode from "jwt-decode";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import config from '../config/setting'
import { lastSevenDaysDataBarChartEng, TicketByPeriorityEng, ticketCountsEng } from '../../redux/actions/ticket';
import { allEngineers, logoutUser } from '../../redux/actions/user';
import { ticketListEng, feedbackListEng } from './../../redux/actions/ticket';
import moment from 'moment';
import Select from 'react-select'

import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.plugins.register(ChartDataLabels);

class PieChart extends Component<{ data: any }, {}> {
  constructor(readonly props: any) {
      super(props);
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    console.log("nextProps", nextProps && nextProps.data);
      let dataa: any = [];

      const counts = nextProps && nextProps.data;
      let closed = counts.closedBySystem ? counts.closedBySystem : " ";
      //let recieved = counts.recievedTickets ? counts.recievedTickets : "";
      let assigned = counts.assignedTickets ? counts.assignedTickets : " ";
      let resolved = counts.resolvedTickets ? counts.resolvedTickets : " ";
      dataa.push(closed, assigned, resolved);
      console.log("dataa pieee", dataa);
      
      let PIECHART: any = document.getElementById('piechart');
      new Chart(PIECHART, {
          type: 'pie',
          plugins: [ChartDataLabels],
          options: {
            plugins: {
              datalabels: {
                 display: function(context: any) {
                    return context.dataset.data[context.dataIndex] > 0; // or >= 1 or ...
                 }
              }
            },
              responsive: true,
              legend: {
                position: 'bottom',
              },
              title: {
                  display: true,
                  text: 'Tickets',
                  fontSize: 20
              },
          },
          data: {
              labels: ["Closed", "Assigned", "Resolved"],
              datasets: [
                  {
                      data: dataa,
                      backgroundColor: [
                          'rgb(255, 99, 132)',
                          'rgb(54, 162, 235)',
                          'rgb(34, 139, 34)',
                        ],
                        hoverOffset: 3,
                        datalabels: {
                          color: 'white',
                          font: {
                              size: 16,
                              family: 'cursive'
                          }
                      }  
                  }]
          }
      });
  }
  render() {
      return (
      <div>
          <canvas height="140px" id="piechart" />
      </div>
      );
  }
}

class BarChart extends Component<{ data: any[] }, {}> {
  constructor(readonly props: any) {
      super(props)
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
      // console.log("nextPropsBarChart", nextProps.data);
      
      let assigned = [];
      let resolved = [];
      let weekDays: any = [];
        for (let i = 0; i < nextProps.data.length; i++) {
            let date = nextProps.data[i].createdAt.split("T")
            weekDays.push(moment(date[0]).local().format("DD MMM"))
        }
      if (nextProps && nextProps.data) {
          for (let i = 0; i < nextProps.data.length; i++) {
              assigned.push(nextProps.data[i].assignedTickets);
              resolved.push(nextProps.data[i].resolvedTickets);
          }
      }
      let sortWeekDays = weekDays.reverse()
      var BARCHART: any = document.getElementById('barChart');
      let chart = new Chart(BARCHART, {
          type: 'bar',
          options:
          {
              scales:
              {
                  xAxes: [{
                      display: true
                  }],
                  yAxes: [{
                      display: true
                  }],
              },
              legend: {
                  display: true
              },
              plugins: {
                datalabels: {
                   display: function(context: any) {
                      return context.dataset.data[context.dataIndex] > 0; // or >= 1 or ...
                   }
                }
              },
              responsive: true,
              maintainAspectRatio: false,
          },
          data: {
              labels: sortWeekDays,
              datasets: [
                  {
                      label: "Assigned",
                      backgroundColor: 'rgb(54, 162, 235)',
                      fill: false,
                      borderColor: 'rgb(54, 162, 235)',
                      borderWidth: 1,
                      data: assigned.reverse(),
                      datalabels: {
                        color: 'white',
                        font: {
                            size: 16,
                            family: 'cursive'
                        }
                    }  
                  },
                  {
                    label: "Resolved",
                    backgroundColor: 'rgb(34, 139, 34)',
                    borderColor: 'rgb(34, 139, 34)',
                    fill: false,
                    borderWidth: 1,
                    datalabels: {
                      color: 'white',
                      font: {
                          size: 16,
                          family: 'cursive'
                      }
                    },  
                    data: resolved.reverse()
                }
              ]
          }
      });
      chart.update({
          duration: 800,
          lazy: false,
          easing: 'easeOutBounce'
      });
  }
  render() {
      return <canvas height="315px" id="barChart" />;
  }
}

class Performance extends Component<{
  ticket_count_eng: any, tickets_eng: any[], ticketByPriorityEng: any, barChartDataEng: any, feedbacks_eng: any[], engineers: any[],
  ticketCountsEng: (eng_id: any) => {}, TicketByPeriorityEng: (eng_id:any) => {}, lastSevenDaysDataBarChartEng: (eng_id:any) => {}, allEngineers: () => {}, feedbackListEng: (eng_id: any) => {}, ticketListEng: (eng_id: any) => {}, logoutUser: () => {}
},
  { showDiv: any, assignee: any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = { showDiv: '', assignee: "" }
  }
  componentDidMount() {
    let token: any = sessionStorage.getItem('token');
    var user: any = jwt_decode(token);
    let roleId = user.role;
    let userId = user.userId;
    if(roleId == 5) {
      this.props.ticketCountsEng(userId);
      this.props.lastSevenDaysDataBarChartEng(userId)
      this.props.TicketByPeriorityEng(userId)
      this.props.ticketListEng(userId)
      this.props.feedbackListEng(userId)
    }
    
    this.props.allEngineers()
    //this.props.ticketList()
    //this.props.feedbackList()
    document.title = "EathosServices | Dashboard"
  }
  handleAssigneeInputChange = (e: any) => {
    console.log("eeeeeeeeeee", e);
    if (e && e.value > 0) {
      this.setState({ assignee: e.value });
      this.props.ticketCountsEng(e.value)
      this.props.lastSevenDaysDataBarChartEng(e.value)
      this.props.ticketListEng(e.value)
      this.props.feedbackListEng(e.value)
      this.props.TicketByPeriorityEng(e.value)
    } else {
      this.setState({ assignee: "" });
    }
  };

  render() {
    let roleId: any = ""
    console.log("roleId", roleId);
    
    if (sessionStorage.token) {
        jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        } else {
          roleId = decoded.role;
        }
      });
    } else {
      return <Redirect to="/" />
    }

    const options: any = {
      // sizePerPageList: [5,10,15],
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
    };
    const { ticket_count_eng, feedbacks_eng, ticketByPriorityEng, barChartDataEng, tickets_eng, engineers } = this.props;
    console.log("barChartDataEng", ticketByPriorityEng);

    // Feedback Avg rating
    var total_rating: any = 0;
    feedbacks_eng && feedbacks_eng.map((el: any) => {
      if (el.ticket.ticket_status_code.ticket_status_code == 3 || el.ticket.ticket_status_code.ticket_status_code == 5) {
        let rating = el.rating;
        total_rating += Number(rating)
      }
    })
    console.log("total",feedbacks_eng,"lenght",feedbacks_eng.length)
    var avg_rating = Math.round(total_rating / ticket_count_eng.totalTicketes);

    // Tickets data  
    var resolution_time = 0;
    var breach = 0;

    tickets_eng && tickets_eng.map((el: any) => {
      // // Resolution
      // var recievedTime: any = moment(el.recieved_time, 'YYYY-MM-DD HH:mm:ss');
      // var resolvedTime: any = moment(el.resolved_time, 'YYYY-MM-DD HH:mm:ss');
      // var resolutionTime: any = resolvedTime.diff(recievedTime, 'minutes');
      if (el.resolutionTime) {
        resolution_time += el.resolutionTime;
      }

      //Get SLA Breach Tickets
      if (el.resolutionTime > el.priority_resolution) {
        breach++
      }
    })
    var avgResolution = resolution_time / tickets_eng.length;
    let avg_resolution: any = Math.round(avgResolution);

    // data for ticket counts for tiles and chart
    const counts = ticket_count_eng && ticket_count_eng;
    let total = counts && counts.totalTicketes;
    // let recieved = counts && counts.recievedTickets;
    let assigned = counts && counts.assignedTickets;
    let resolved = counts && counts.resolvedTickets;
    let closed = counts && counts.closedBySystem;
    // Assignee Data
    const { assignee } = this.state;
    let userData: any = [];
    let selectedOption: any = [];
    if (engineers.length > 0) {
      engineers.forEach((element: any) => {
        var user = { value: element.id, label: element.name };
        userData.push(user);

        if (assignee && assignee == element.id) {
          selectedOption.push(user);
        }
      });
    }

    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">{roleId == 5 ? "Dashboard" : "Engineer's Performance"}</h4>
                  {(roleId !== 5 && engineers.length > 0) &&
                    <Select
                      closeMenuOnSelect={true}
                      name="group"
                      isClearable
                      options={userData}
                      className="basic-multi-select col-6"
                      classNamePrefix="select"
                      onChange={(e: any) => this.handleAssigneeInputChange(e)}
                    />
                  }
                </div>
              </div>
            </header>
            {/* <!-- Dashboard Counts Section--> */}
            {roleId !== 5 ?
            (this.state.assignee !== "" ?
              <section className="dashboard-counts no-padding-bottom">
                <div className="container-fluid">
                  <div className="row bg-white has-shadow">
                    {/* <!-- Item --> */}
                    <div className="col-xl-3 col-sm-6">
                      <div className="item d-flex align-items-center">
                          <div className="icon bg-orange">
                            <i className="fa fa-unlock-alt"></i>
                          </div>
                        <div className="title">
                          <span>Assigned <br /> Tickets</span>
                          <div className="progress">
                            <div
                              role="progressbar"
                              style={{ width: `${assigned / 100}%`, height: "4px" }}
                              className="progress-bar bg-orange"
                            ></div>{" "}
                            {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                          </div>
                        </div>
                        <div className="number mr-2">
                          <strong>{assigned ? assigned : 0}</strong>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Item --> */}
                    <div className="col-xl-3 col-sm-6">
                      <div className="item d-flex align-items-center">
                          <div className="icon bg-orange">
                            <i className="fa fa-unlock-alt"></i>
                          </div>
                        <div className="title">
                          <span>Resolved <br /> Tickets</span>
                          <div className="progress">
                            <div
                              role="progressbar"
                              style={{ width: `${resolved / 100}%`, height: "4px" }}
                              className="progress-bar bg-orange"
                            ></div>{" "}
                            {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                          </div>
                        </div>
                        <div className="number mr-2">
                          <strong>{resolved ? resolved : 0}</strong>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Item --> */}
                    <div className="col-xl-3 col-sm-6">
                      <div className="item d-flex align-items-center">
                        <div className="icon bg-green">
                          <i className="fa fa-ticket"></i>
                        </div>
                        <div className="title">
                          <span>Closed <br /> Tickets</span>
                          <div className="progress">
                            <div
                              role="progressbar"
                              style={{ width: `${closed / 100}%`, height: "4px" }}
                              className="progress-bar bg-green"
                            ></div>{" "}
                            {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                          </div>
                        </div>
                        <div className="number">
                          <strong>{closed ? closed : 0}</strong>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Item --> */}
                    <div className="col-xl-3 col-sm-6">
                      <div className="item d-flex align-items-center">
                        <div className="icon bg-green">
                          <i className="fa fa-ticket"></i>
                        </div>
                        <div className="title">
                          <span>Total <br /> Tickets</span>
                          <div className="progress">
                            <div
                              role="progressbar"
                              style={{ width: `${total / 100}%`, height: "4px" }}
                              className="progress-bar bg-green"
                            ></div>{" "}
                            {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                          </div>
                        </div>
                        <div className="number">
                          <strong>{total ? total : 0}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              : <div className="h3 card-body text-secondary text-center">Please first select the engineer!</div>
            )
            :
            <section className="dashboard-counts no-padding-bottom">
                <div className="container-fluid">
                  <div className="row bg-white has-shadow">
                    {/* <!-- Item --> */}
                    <div className="col-xl-3 col-sm-6">
                      <div className="item d-flex align-items-center">
                          <div className="icon bg-orange">
                            <i className="fa fa-unlock-alt"></i>
                          </div>
                        <div className="title">
                          <span>Assigned <br /> Tickets</span>
                          <div className="progress">
                            <div
                              role="progressbar"
                              style={{ width: `${assigned / 100}%`, height: "4px" }}
                              className="progress-bar bg-orange"
                            ></div>{" "}
                            {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                          </div>
                        </div>
                        <div className="number mr-2">
                          <strong>{assigned ? assigned : 0}</strong>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Item --> */}
                    <div className="col-xl-3 col-sm-6">
                      <div className="item d-flex align-items-center">
                          <div className="icon bg-orange">
                            <i className="fa fa-unlock-alt"></i>
                          </div>
                        <div className="title">
                          <span>Resolved <br /> Tickets</span>
                          <div className="progress">
                            <div
                              role="progressbar"
                              style={{ width: `${resolved / 100}%`, height: "4px" }}
                              className="progress-bar bg-orange"
                            ></div>{" "}
                            {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                          </div>
                        </div>
                        <div className="number mr-2">
                          <strong>{resolved ? resolved : 0}</strong>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Item --> */}
                    <div className="col-xl-3 col-sm-6">
                      <div className="item d-flex align-items-center">
                        <div className="icon bg-green">
                          <i className="fa fa-ticket"></i>
                        </div>
                        <div className="title">
                          <span>Closed <br /> Tickets</span>
                          <div className="progress">
                            <div
                              role="progressbar"
                              style={{ width: `${closed / 100}%`, height: "4px" }}
                              className="progress-bar bg-green"
                            ></div>{" "}
                            {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                          </div>
                        </div>
                        <div className="number">
                          <strong>{closed ? closed : 0}</strong>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Item --> */}
                    <div className="col-xl-3 col-sm-6">
                      <div className="item d-flex align-items-center">
                        <div className="icon bg-green">
                          <i className="fa fa-ticket"></i>
                        </div>
                        <div className="title">
                          <span>Total <br /> Tickets</span>
                          <div className="progress">
                            <div
                              role="progressbar"
                              style={{ width: `${total / 100}%`, height: "4px" }}
                              className="progress-bar bg-green"
                            ></div>{" "}
                            {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                          </div>
                        </div>
                        <div className="number">
                          <strong>{total ? total : 0}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            }
            {/* <!-- Dashboard PieChart Section --> */}
            <section className="dashboard-header no-padding-bottom">
              <div className="container-fluid">
                <div className="row">
                  {roleId !== 5 ?
                    <>
                      <div className="col-lg-8 col-8">
                        <div className="pie-chart text-white bg-white align-items-center justify-content-center has-shadow">
                          {(ticket_count_eng.resolvedTickets !== "0" || ticket_count_eng.assignedTickets !== "0" || ticket_count_eng.closedBySystem !== "0") && (this.state.assignee !== "") &&
                            <PieChart data={ticket_count_eng} />
                          }
                        </div>
                      </div>
                      <div className="statistics col-lg-4 col-4">
                      {ticketByPriorityEng && ticketByPriorityEng.map((data:any, index: any) => (
                        <div className="statistic d-flex align-items-center bg-white has-shadow" style={{ padding: '10px 15px' }}>
                          {(() => {
                            if (index == 0) {
                                return (
                                    <div className="icon bg-orange"><i className="fa fa-shield"></i></div>
                                )
                            } else if (index == 1) {
                                return (
                                    <div className="icon bg-gray"><i className="fa fa-star"></i></div>
                                )
                            }
                            else if (index == 2) {
                                return (
                                    <div className="icon bg-violet"><i className="fa fa-star-half-o"></i></div>
                                )
                            }
                            else if (index == 3 || index == 4) {
                                return (
                                    <div className="icon bg-red"><i className="fa fa-star-o"></i></div>
                                )
                            }
                        })()}
                          <div className="text">{data.ticket_priority_description}<br /><strong style={{ fontSize: '95%' }}><span className="text-secondary">{data.periority_total} {data.periority_total > 1 ? "tickets" : "ticket"}</span></strong></div>
                        </div>
                        ))
                      }
                      </div>
                    </>   
                    :
                    <>
                      <div className="col-lg-8 col-8">   
                        <div className="pie-chart text-white bg-white align-items-center justify-content-center has-shadow">
                          {(ticket_count_eng.resolvedTickets !== "0" || ticket_count_eng.assignedTickets !== "0" || ticket_count_eng.closedBySystem !== "0") &&
                            <PieChart data={ticket_count_eng} />
                          }
                        </div>
                      </div>
                      <div className="statistics col-lg-4 col-4">
                      {ticketByPriorityEng && ticketByPriorityEng.map((data:any, index: any) => (
                        <div className="statistic d-flex align-items-center bg-white has-shadow" style={{ padding: '10px 15px' }}>
                          {(() => {
                            if (index == 0) {
                                return (
                                    <div className="icon bg-orange"><i className="fa fa-shield"></i></div>
                                )
                            } else if (index == 1) {
                                return (
                                    <div className="icon bg-gray"><i className="fa fa-star"></i></div>
                                )
                            }
                            else if (index == 2) {
                                return (
                                    <div className="icon bg-violet"><i className="fa fa-star-half-o"></i></div>
                                )
                            }
                            else if (index == 3 || index == 4) {
                                return (
                                    <div className="icon bg-red"><i className="fa fa-star-o"></i></div>
                                )
                            }
                        })()}
                          <div className="text">{data.ticket_priority_description}<br /><strong style={{ fontSize: '95%' }}><span className="text-secondary">{data.periority_total} {data.periority_total > 1 ? "tickets" : "ticket"}</span></strong></div>
                        </div>
                        ))
                      }
                      </div>
                    </>
                  }
                </div>
              </div>
            </section>
            {/* Bar Chart */}
            <section className="dashboard-header no-padding-bottom">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 col-12">
                    {roleId !== 5 ?
                      <div className="bar-chart text-white bg-white align-items-center justify-content-center has-shadow">
                        {assignee !== "" &&
                          <BarChart data={barChartDataEng} />
                        }
                      </div> :
                      <div className="bar-chart text-white bg-white align-items-center justify-content-center has-shadow">
                        <BarChart data={barChartDataEng} />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </section>
            
            {/* Table Cards */}
            {roleId !== 5 ? 
            (this.state.assignee !== "" &&
              <section className="dashboard-header pt-xl-2 mt-5">
                <div className="container-fluid">
                  <div className="row">
                    <div className="chart col-lg-4 col-12">
                      <div className="statistic d-flex align-items-center bg-white has-shadow">
                        <div className="icon bg-success">
                          <i className="fa fa-clock-o"></i>
                        </div>
                        <div className="text">
                          <strong>{avg_resolution ? avg_resolution : 0} mins</strong>
                          <br />
                          <small>AVG Resolution Time</small>
                        </div>
                      </div>
                    </div>
                    <div className="chart col-lg-4 col-12">
                      <div className="statistic d-flex align-items-center bg-white has-shadow">
                        <div className="icon bg-red">
                          <i className="fa fa-ban"></i>
                        </div>
                        <div className="text">
                          <strong>{breach ? breach : 0}</strong>
                          <br />
                          <small>SLA Breach Tickets</small>
                        </div>
                      </div>
                    </div>
                    <div className="statistics col-lg-4 col-12">
                      <div className="statistic cursor-pointer d-flex align-items-center bg-white has-shadow" onClick={() => { this.setState({ showDiv: 'feedbacks' }) }}>
                        <div className="icon bg-orange">
                          <i className="fa fa-comments-o"></i>
                        </div>
                        <div className="text">
                          <strong>{avg_rating ? avg_rating : 0}</strong>
                          <br />
                          <small>Feedback Score</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>)
              :
              <section className="dashboard-header pt-xl-2 mt-5">
              <div className="container-fluid">
                <div className="row">
                  <div className="chart col-lg-4 col-12">
                    <div className="statistic d-flex align-items-center bg-white has-shadow">
                      <div className="icon bg-success">
                        <i className="fa fa-clock-o"></i>
                      </div>
                      <div className="text">
                        <strong>{avg_resolution ? avg_resolution : 0} mins</strong>
                        <br />
                        <small>AVG Resolution Time</small>
                      </div>
                    </div>
                  </div>
                  <div className="chart col-lg-4 col-12">
                    <div className="statistic d-flex align-items-center bg-white has-shadow">
                      <div className="icon bg-red">
                        <i className="fa fa-ban"></i>
                      </div>
                      <div className="text">
                        <strong>{breach ? breach : 0}</strong>
                        <br />
                        <small>SLA Breach Tickets</small>
                      </div>
                    </div>
                  </div>
                  <div className="statistics col-lg-4 col-12">
                    <div className="statistic cursor-pointer d-flex align-items-center bg-white has-shadow" onClick={() => { this.setState({ showDiv: 'feedbacks' }) }}>
                      <div className="icon bg-orange">
                        <i className="fa fa-comments-o"></i>
                      </div>
                      <div className="text">
                        <strong>{avg_rating ? avg_rating : 0}</strong>
                        <br />
                        <small>Feedback Score</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            }
            {/* Feedack Table */}
            {roleId !== 5 ?
            ((this.state.showDiv == 'feedbacks' && this.state.assignee !== "") &&
              <section className="tables p-0">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="card-header">
                        <strong>All Feedback</strong>
                      </div>
                      <div className="card">
                        <div className="card-body text-capitalize">
                          <BootstrapTable version='4' data={feedbacks_eng} search={true} pagination={feedbacks_eng.length > 10 && true} options={options} exportCSV={true} csvFileName='feedbacks.csv' hover>
                            <TableHeaderColumn formatExtraData={this.props} dataFormat={ticketId} csvHeader='Sr. #' width='70' dataSort={true} >#</TableHeaderColumn>
                            <TableHeaderColumn formatExtraData={this.props} dataFormat={ticketSubject} csvHeader='Ticket' width='100' columnTitle>Ticket</TableHeaderColumn>
                            <TableHeaderColumn dataField='feedback' csvHeader='Feedback' width='100' isKey columnTitle>Feedback</TableHeaderColumn>
                            <TableHeaderColumn formatExtraData={this.props} dataFormat={assigneeName} csvHeader='Assignee' width='100' columnTitle>Assignee</TableHeaderColumn>
                            <TableHeaderColumn formatExtraData={this.props} dataFormat={requesterName} csvHeader='Customer' width='100' columnTitle>Customer</TableHeaderColumn>
                            <TableHeaderColumn dataField='rating' csvHeader='Rating' width='100' columnTitle>Rating</TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>) :
              (this.state.showDiv == 'feedbacks' &&
              <section className="tables p-0">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="card-header">
                        <strong>All Feedback</strong>
                      </div>
                      <div className="card">
                        <div className="card-body text-capitalize">
                          <BootstrapTable version='4' data={feedbacks_eng} search={true} pagination={feedbacks_eng.length > 10 && true} options={options} exportCSV={true} csvFileName='feedbacks.csv' hover>
                            <TableHeaderColumn formatExtraData={this.props} dataFormat={ticketId} csvHeader='Sr. #' width='70' dataSort={true} >#</TableHeaderColumn>
                            <TableHeaderColumn formatExtraData={this.props} dataFormat={ticketSubject} csvHeader='Ticket' width='100' columnTitle>Ticket</TableHeaderColumn>
                            <TableHeaderColumn dataField='feedback' csvHeader='Feedback' width='100' isKey columnTitle>Feedback</TableHeaderColumn>
                            <TableHeaderColumn formatExtraData={this.props} dataFormat={assigneeName} csvHeader='Assignee' width='100' columnTitle>Assignee</TableHeaderColumn>
                            <TableHeaderColumn formatExtraData={this.props} dataFormat={requesterName} csvHeader='Customer' width='100' columnTitle>Customer</TableHeaderColumn>
                            <TableHeaderColumn dataField='rating' csvHeader='Rating' width='100' columnTitle>Rating</TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>)
            }
            <Footer />
          </div>
        </div>
      </div>
    );
  }
};

function ticketId(cell: any, row: any, props: any) {
  return (
    <span>{row.ticket.id}</span>
  );
}
function ticketSubject(cell: any, row: any, props: any) {
  return (
    <span>{row.ticket.subject}</span>
  );
}
function assigneeName(cell: any, row: any, props: any) {
  return (
    <span>{row.assignee_id.name}</span>
  );
}
function requesterName(cell: any, row: any, props: any) {
  return (
    <span>{row.requester_id.name}</span>
  );
}


const mapStateToProps = (state: any) => {
  // console.log("state",state);
  return {
    ticket_count_eng: state.ticket.ticket_count_eng,
    barChartDataEng: state.ticket.barChartDataEng,
    tickets_eng: state.ticket.tickets_eng,
    feedbacks_eng: state.ticket.feedbacks_eng,
    engineers: state.user.engineers,
    ticketByPriorityEng: state.ticket.ticketByPriorityEng,
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    ticketListEng: function (id: any) {
      dispatch(ticketListEng(id))
    },
    feedbackListEng: function (id: any) {
      dispatch(feedbackListEng(id))
    },
    allEngineers: function () {
      dispatch(allEngineers());
    },
    ticketCountsEng: function (id: any) {
      dispatch(ticketCountsEng(id));
    },
    lastSevenDaysDataBarChartEng: function (id: any) {
      dispatch(lastSevenDaysDataBarChartEng(id));
    },
    TicketByPeriorityEng: function (id: any) {
      dispatch(TicketByPeriorityEng(id));
    },
    logoutUser: function () {
      dispatch(logoutUser());
  },

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Performance)
