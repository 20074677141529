
import React, { Component } from 'react';
import {  AddUserState } from '../../interfaces/user'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';

export default class AddStore extends Component<{}, AddUserState> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            role: "",
            email: "",
            phone: "",
            username: "",
            address: "",
            password: "",
            status: "",
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        // this.props.brandsList();
        // this.props.channelsList();
        // this.props.storeTypeList();
        // this.props.areasList();
        // this.props.countryList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isUserReady = () => {
        const { name, role,  email, phone, address, username , password, status } = this.state
        return (name !== "" && role !== "" && email !== "" && phone !== ""&& username !== ""&& password !== ""&& status !== "" && address !== "");
    }
    
    handleSaveBtnClick = (event: any) => {
        let { name, role,  email, phone, address, username , password, status} = this.state;
        
        let data = {
          name: name,
          role: role,
          email: email,
          phone: phone,
          address: address,
          username: username,
          password: password,
          status: status,
        };
        // this.props.addStore(data);
    }
    render() {
        // if (sessionStorage.token) {
        //     jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        //         if (err) {
        //             this.props.logoutUser();
        //         }
        //     });
        // } else {
        //     return <Redirect to="/" />
        // }
        let msg;
        let messcolor;
        // const { isInserted, message, brands, channels, types } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Users Management</h4>
                                </div>
                            </div>
                        </header>
                            {/*  Breadcrumb */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/users" className="text-primary">Users</Link></li>
                                    <li className="breadcrumb-item active">View User</li>
                                </ul>
                            </div>
                        
                            <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body pt-xl-0">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="card-header card-header-info d-flex align-items-center">
                                                    <h5>Personal Information</h5>
                                                </div>

                                                <div className="form-horizontal p-xl-4">

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="name" type="text" name="name" value="Junaid Sheikh" disabled className="input-material bg-white"/>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Role<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="" type="text" name="" value="Learner" disabled className="input-material bg-white"/>
                                                        </div>
                                                    </div>

                                                    

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Address<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                        <input id="" type="text" name="" value="PIA Society, Lahore" disabled className="input-material bg-white" />
                                                        </div>
                                                    </div>

                                                    {/* Email Info */}
                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Email<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="" type="text" name="" value="sheikh@simplexts.net" disabled className="input-material bg-white" />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Phone<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="" type="text" name="" value="+9232300000000" disabled className="input-material bg-white" />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Username<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="" type="text" name="" value="sheikh" disabled className="input-material bg-white" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card-header card-header-info d-flex align-items-center">
                                                    <h5>Status Information</h5>
                                                </div>

                                                <div className="form-horizontal p-xl-4">
                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="" type="text" name="" value="Active" disabled className="input-material bg-white" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                          
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        message: state.store.message,
        isInserted: state.store.isInserted,
        brands: state.store.brands,
        channels: state.store.channels,
        types: state.store.types,
        areas: state.store.areas,
        countries: state.store.countries,
    }
}
// const mapDispatchToProps = (dispatch: any) => {
//     return {
//         addStore: function (data: any) {
//             dispatch(addStore(data));
//         },
//         brandsList: function () {
//             dispatch(brandsList())
//         },
//         channelsList: function () {
//             dispatch(channelList())
//         },
//         storeTypeList: function () {
//             dispatch(storeTypeList())
//         },
//         areasList: function () {
//             dispatch(areasList())
//         },
//         countryList: function () {
//             dispatch(countryList())
//         }
//     }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(AddStore);