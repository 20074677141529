import React, { Component } from 'react';
import { AddForumProps, AddForumState } from '../../interfaces/forum'
// import { connect } from 'react-redux'
// import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link } from 'react-router-dom';
// import { Link, Redirect } from 'react-router-dom';


class UploadPreview extends React.Component <{},{file:any}> {
    constructor(props:any) {
      super(props);
      this.state = { file: null };
      this.onChange = this.onChange.bind(this);
      this.resetFile = this.resetFile.bind(this);
    }
    onChange(event:any) {
      this.setState({
        file: URL.createObjectURL(event.target.files[0])
      });
    }
  
    resetFile(event:any) {
      event.preventDefault();
      this.setState({ file: null });
    }
    render() {
      return (
        <span>
          <input name="image" id="image" type="file" onChange={this.onChange} />
          
          <img className="" style={{ width: "130px" }} src={this.state.file} />
          {this.state.file && (
              <button  onClick={this.resetFile} className="close"><span aria-hidden="true">×</span></button>
          )}
        </span>
      );
    }
  }

export default class Reports extends Component<AddForumProps, AddForumState> {
    constructor(props: any) {
        super(props);
        this.state = {
            user: '',
            image: '',
            post: '',
            comment:1,
            forum: '',
            postShow: 1,
            commentsShow: 1
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        // this.props.brandsList();
        // this.props.channelsList();
        // this.props.storeTypeList();
        // this.props.areasList();
        // this.props.countryList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        // this.setState({
        //     [event.target.name]: event.target.value
        // });
    }
    isCompanyReady = () => {
        const { post, image } = this.state
        return (post !== "" && image !== "");
    }
    
    handleSaveBtnClick = (event: any) => {
        // let { name, address, phone} = this.state;
        // let data = {
        //     name: name,
        //     address: address,
        //     phone: phone,
        //     is_active: 1
        // }
        // this.props.addStore(data);
    }
    render() {
        // if (sessionStorage.token) {
        //     jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        //         if (err) {
        //             this.props.logoutUser();
        //         }
        //     });
        // } else {
        //     return <Redirect to="/" />
        // }
        let msg;
        let messcolor;
        // const { isInserted, message, brands, channels, types } = this.props;
        // if (isInserted) {
        //     msg = message;
        //     messcolor = 'green'
        // } else {
        //     msg = message;
        //     messcolor = 'red'
        // }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Forum Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="forms pb-xl-0">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body pt-xl-0">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div>
                                                    <button  className="collapsible" onClick={()=>{this.setState({postShow:this.state.postShow==0? 1:0})}}>
                                                        Write Post
                                                    </button>

                                                    {
                                                        this.state.postShow == 0 && 
                                                        <div className="col form-horizontal">

                                                            <div className="row form-group">
                                                                <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Description<span className="text-danger">*</span></label>
                                                                <div className="col-md-9 col-sm-9 pt-xl-2">
                                                                    <textarea id="address" name="address" required data-msg="Please enter Address" className="input-material" onChange={this.handleInputChange} ></textarea>
                                                                </div>
                                                            </div>

                                                            {/* <div className="row form-group">
                                                                <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Upload Image<span className="text-danger">*</span></label>
                                                                <div className="col-md-9 col-sm-9">
                                                                    <UploadPreview />
                                                                </div>
                                                            </div> */}
                                                            <div className="form-group float-right mt-3">
                                                                <button className='btn btn-primary' disabled={!this.isCompanyReady()} onClick={this.handleSaveBtnClick}>Post</button>
                                                            </div>
                                                        </div>

                                                    }
                                                

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="forms pt-xl-0">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="post-heading">
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <div className="media">
                                                            <div className="media-left">
                                                                <a href="#">
                                                                    <img className="media-object photo-profile" src="http://0.gravatar.com/avatar/38d618563e55e6082adf4c8f8c13f3e4?s=40&d=mm&r=g" width="40" height="40" alt="..."/>
                                                                </a>
                                                            </div>
                                                            <div className="media-body pl-xl-3">
                                                                <span className="comment-click anchor-username"><h4 className="m-0 media-heading">Junaid Sheikh</h4></span>
                                                                <p className="m-0 forum-date">Oct 30,2020 12:43</p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 text-right">
                                                            {/* <span><i className="fa fa-pencil"></i></span> */}
                                                            <span title="Delete Post" className="comment-delete"><i className="pl-xl-1 fa fa-trash"></i></span>
                                                        </div>
                                                    </div>             
                                                </div>
                                                <div className="post-body pt-xl-3">
                                                    <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras 
                                                    turpis sem, dictum id bibendum eget, malesuada ut massa. Ut scel
                                                    erisque nulla sed luctus dapibus. Nulla sit amet mi vitae purus sol
                                                    licitudin venenatis. Praesent et sem urna. Integer vitae lectus nis
                                                    l. Fusce sapien ante, tristique efficitur lorem et, laoreet ornare lib
                                                    ero. Nam fringilla leo orci. Vivamus semper quam nunc, sed ornare magna dignissim sed. Etiam interdum justo quis risus
                                                    efficitur dictum. Nunc ut pulvinar quam. N
                                                    unc mollis, est a dapibus dignissim, eros elit tempor diam, eu tempus quam felis eu velit.</p>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="clear"></div>

                                                <div className="row">
                                                    <div className="col-md-2" onClick={()=>{this.setState({comment:this.state.comment==0? 1:0})}}>
                                                        <span className="comment-click"><i className="fa fa-comment"></i> Comment</span>
                                                    </div>
                                                    {
                                                        this.state.comment==0 &&
                                                        <>
                                                        <div className="col-md-9">
                                                            <textarea id="address" name="address" style={{height:'30px'}} required data-msg="Enter comment......." placeholder="Enter comment......." className="input-material textarea-comment" onChange={this.handleInputChange} ></textarea>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <span title="Send Comment" style={{height:'30px'}} className="comment-save btn mx-2"><i style={{fontSize: '1.4em'}} className="fa fa-paper-plane"></i></span>
                                                        </div>
                                                        </>
                                                    }
                                                </div>  

                                                <div className="row">
                                                    <div className="col-md-12" onClick={()=>{this.setState({commentsShow:this.state.commentsShow==0? 1:0})}}>
                                                        <span className="comment-click">View all Comments</span>
                                                    </div>
                                                    {
                                                        this.state.commentsShow==0 &&

                                                        <div className="col">
                                                    <div className="row pt-xl-2">
                                                        <div className="col-md-11">
                                                            <div className="media">
                                                            <div className="media-left">
                                                                <a href="#">
                                                                    <img className="media-object photo-profile" src="http://0.gravatar.com/avatar/38d618563e55e6082adf4c8f8c13f3e4?s=40&d=mm&r=g" width="40" height="40" alt="..."/>
                                                                </a>
                                                            </div>
                                                            <div className="media-body pl-xl-3">
                                                                <span className="font-13"><b>Zafar Iqbal</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras 
                                                                turpis sem, dictum id bibendum eget, malesuada ut massa. Ut scel
                                                                erisque nulla sed luctus dapibus. Nulla sit amet mi vitae purus sol
                                                                licitudin venenatis. Praesent et sem urna. Integer vitae lectus nis
                                                                l.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        <div className="col-md-1 text-right">
                                                            {/* <span><i className="fa fa-pencil"></i></span> */}
                                                            <span title="Delete Comment" className="comment-delete"><i className="pl-xl-1 fa fa-trash"></i></span>
                                                        </div>
                                                </div> 

                                                <div className="row pt-xl-2">
                                                    <div className="col-md-11">
                                                        <div className="media">
                                                        <div className="media-left">
                                                            <a href="#">
                                                                <img className="media-object photo-profile" src="http://0.gravatar.com/avatar/38d618563e55e6082adf4c8f8c13f3e4?s=40&d=mm&r=g" width="40" height="40" alt="..."/>
                                                            </a>
                                                        </div>
                                                        <div className="media-body pl-xl-3">
                                                            <span className="font-13"><b>Mahad Butt</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras 
                                                            turpis sem, dictum id bibendum eget, malesuada ut massa. Ut scel
                                                            erisque nulla sed luctus dapibus. Nulla sit amet mi vitae purus sol
                                                            licitudin venenatis. Praesent et sem urna. Integer vitae lectus nis
                                                            l.</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                        <div className="col-md-1 text-right">
                                                            {/* <span><i className="fa fa-pencil"></i></span> */}
                                                            <span title="Delete Comment" className="comment-delete"><i className="pl-xl-1 fa fa-trash color-red"></i></span>
                                                        </div>
                                                </div> 

                                                <div className="row pt-xl-2">
                                                    <div className="col-md-11">
                                                        <div className="media">
                                                        <div className="media-left">
                                                            <a href="#">
                                                                <img className="media-object photo-profile" src="http://0.gravatar.com/avatar/38d618563e55e6082adf4c8f8c13f3e4?s=40&d=mm&r=g" width="40" height="40" alt="..."/>
                                                            </a>
                                                        </div>
                                                        <div className="media-body pl-xl-3">
                                                            <span className="font-13"><b>Farooq Adullah</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras 
                                                            turpis sem, dictum id bibendum eget, malesuada ut massa. Ut scel
                                                            erisque nulla sed luctus dapibus. Nulla sit amet mi vitae purus sol
                                                            licitudin venenatis. Praesent et sem urna. Integer vitae lectus nis
                                                            l.</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                        <div className="col-md-1 text-right">
                                                            {/* <span><i className="fa fa-pencil"></i></span> */}
                                                            <span title="Delete Comment" className="comment-delete"><i className="pl-xl-1 fa fa-trash color-red"></i></span>
                                                        </div>
                                                </div>          

                                                </div>
                                                       
                                                    }
                                                </div>

                                                
                                                            {/* <div className="post-footer-option container">
                                                                        <ul className="list-unstyled">
                                                                            <li><a href="#"><i className="fa fa-thumbs-up"></i> Like</a></li>
                                                                            <li><a href="#"><i className="fa fa-comment"></i> Comment</a></li>
                                                                        </ul>
                                                                </div> */}

                                                {/* <div className="media pt-xl-5">
                                                    <div className="media-left">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/avatar-2.jpg"} className="media-object" style={{width:'45px'}}/>
                                                    </div>
                                                    <div className="media-body ml-xl-2">
                                                        <h4 className="media-heading">John Doe <small><i>Posted on February 19, 2016</i></small></h4>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                    
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <img src={process.env.PUBLIC_URL + "/assets/img/avatar-2.jpg"} className="media-object" style={{width:'45px'}}/>
                                                        </div>
                                                        <div className="media-body ml-xl-2">
                                                            <h4 className="media-heading">John Doe <small><i>Posted on February 19, 2016</i></small></h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                                                        <div className="media">
                                                            <div className="media-left">
                                                                <img src={process.env.PUBLIC_URL + "/assets/img/avatar-2.jpg"} className="media-object" style={{width:'45px'}}/>
                                                            </div>
                                                            <div className="media-body ml-xl-2">
                                                                <h4 className="media-heading">John Doe <small><i>Posted on February 19, 2016</i></small></h4>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            </div>
                                                        </div>
                                                        
                                                        </div>
                                                    </div>
                                                    
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div >
                                    </div>

                                    
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}