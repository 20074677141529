import React, { Component } from 'react';
import { AddStoreProps, AddStoreState } from '../../interfaces/store'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { addStore, brandsList, channelList, storeTypeList, areasList, countryList } from '../../redux'
import Topbar from '../topbar/topbar'
import config from '../config/setting'
import CheckChanges from '../confirmOnLeave';
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { Link, Redirect } from 'react-router-dom';
import { addStore, companyList } from '../../redux/actions/store';
import { logoutUser } from './../../redux/actions/user';

class AddStore extends Component<AddStoreProps, AddStoreState> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            brand: "",
            type: "",
            email: "",
            escalation: "",
            phone: "",
            website: "",
            address: "",
            opening: "",
            closing: "",
            company: "",
            ownerContact: "",
            ownerName: "",
            status: 1,
            isValidEmail: true,
            isValidPhone: true,
            isValidOwnerContact: true,
            isValidEscalation: true
        };
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.companyList();
        // this.props.channelsList();
        // this.props.storeTypeList();
        // this.props.areasList();
        // this.props.countryList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log("event",event);
        if(event.target.value[0] == " "){
            event.target.value = event.target.value.replace(/\s/g, "");
        }else{
          this.setState({
              [event.target.name]: event.target.value,
          });
        }
    }
    isStoreReady = () => {
        const { name, type, email, phone, ownerContact, ownerName, address, status, company } = this.state
        return (name !== "" && type !== "" && email !== "" && phone !== "" && ownerContact !== "" && ownerName !== "" && address !== "" && status !== "" && company !== "");
    }

    alphaOnly = (event:any) => {
        var key = event.keyCode;
        return ((key >= 65 && key <= 90) || key == 8);
      };

    onlyAlphabets = (e:any) => {
       var regex =  /^[a-zA-Z ]*$/
       let specialkey: any = e.keyCode;
       var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
       if (specialkey == 8 || specialkey == 9) {
           return true;
       } else {
           if (/\S/.test(e.target.value)) {
               // string is not empty and not just whitespace
               if (!regex.test(key)) {
                   if (specialkey == 32) {
                   } else {
                       e.preventDefault()
                   }
               }
           } else {
               ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
           }
       }
    }

    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }

    validateEmail = (event: { target: { name: any; value: any; }; }) => {
        var reg = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (reg.test(event.target.value) === false) {
            if(event.target.name == "email"){
                this.setState({ email: event.target.value, isValidEmail: false })
            }else{
                this.setState({ escalation: event.target.value, isValidEscalation: false })
            }
          
        } else {
            if(event.target.name == "email"){
                this.setState({ email: event.target.value, isValidEmail: true });
            }else {
                this.setState({ escalation: event.target.value, isValidEscalation: true });
            }
          
        }
    };
    validatePhone = (event: { target: { name: any; value: any; }; }) => {
        let maxNum = 11;
        if (event.target.value.length > maxNum) {
        event.target.value = event.target.value.slice(0, maxNum);
        }
        let reg = /[^0-9]/;
        if (reg.test(event.target.value) == false) {
            if(event.target.name == "phone"){
                this.setState({ phone: event.target.value, isValidPhone: true })
            }else {
                this.setState({ ownerContact: event.target.value, isValidOwnerContact: true })
            }
       
        } else {
            if(event.target.name == "phone"){
                this.setState({ phone: event.target.value.replace(''), isValidPhone: false });
            }else {
                this.setState({ ownerContact: event.target.value.replace(''), isValidOwnerContact: false });
            }
       
        }
    };

    handleSaveBtnClick = (event: any) => {
        let { name, brand, type, email, company, escalation, phone, website, address, opening, closing, ownerContact, ownerName, status } = this.state;
        let data = {
            name: name,
            brand: brand,
            type: type,
            email: email,
            escalation: escalation,
            phone: phone,
            website: website,
            address: address,
            opening: opening,
            closing: closing,
            company: company,
            ownerContact: ownerContact,
            ownerName: ownerName,
            status: status,
            isActive: config.ACTIVE
        }
        console.log(data)
        this.props.addStore(data);
    }
    render() {
        if (sessionStorage.token) {
                jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                    if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
                return <Redirect to="/" />
        }
            
        let storeTypes = ['Free standing', 'Drive Thru', 'Food Court', 'Inline', 'Kiosk'];
        const { companies } = this.props
        console.log("Company", companies);
        let msg;
        let messcolor;
        const { isInserted, message, brands, channels, types } = this.props;
        if (isInserted) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/add-store" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Stores Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/stores" className="text-primary">Stores</Link></li>
                                <li className="breadcrumb-item active">Add Store</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="form-horizontal">

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="name" type="text" name="name" required data-msg="Please enter Store Name" className="input-material" onKeyDown={this.blockInvalidChar} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Brand</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="brand" type="text" name="brand" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Store Type<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Store Type" onChange={this.handleInputChange}>
                                                                <option value="">Select Type</option>
                                                                {storeTypes &&
                                                                    storeTypes.map((channel, index) => (
                                                                        <option key={index} value={channel}>{channel}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Company<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="company" className="form-control text-capitalize mt-2" required data-msg="Please select Store Type" onChange={this.handleInputChange}>
                                                                <option value="">Select Company</option>
                                                                {companies &&
                                                                    companies.map((company: any, index: any) => (
                                                                        <option key={index} value={company.id}>{company.company_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Address<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="address" type="text" name="address" required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    {/* Email Info */}
                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Email Contact<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="email" type="email" name="email" required data-msg="Please enter Email Contact" className="input-material" onChange={this.validateEmail} />
                                                            {(this.state.isValidEmail == false && this.state.email !== "") &&
                                                            <small className="form-text text-danger">Invalid Email</small>}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Email Escalation</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="escalation" type="email" name="escalation" required data-msg="Please enter Email Escalation" className="input-material" onChange={this.validateEmail} />
                                                            {(this.state.isValidEscalation == false && this.state.escalation !== "") &&
                                                            <small className="form-text text-danger">Invalid Email</small>}
                                                        </div>
                                                    </div>

                                                    {/* Website nd Phone Info */}
                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Website</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="website" type="text" name="website" required data-msg="Please enter Website" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Phone<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="phone" type="text" name="phone" required data-msg="Please enter Phone" className="input-material" onChange={this.validatePhone} />
                                                            {(this.state.isValidPhone == false && this.state.phone !== "") &&
                                                            <small className="form-text text-danger">Invalid Phone Number</small>}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Opening Time</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="opening" type="time" name="opening" required data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Closing Time</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="closing" type="time" name="closing" required data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Owner Name<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="ownerName" type="text" name="ownerName" onKeyDown={this.onlyAlphabets} required data-msg="Please enter Owner Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Owner Contact<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <input id="ownerContact" type="text" name="ownerContact" required data-msg="Please enter Owner Contact" className="input-material" onChange={this.validatePhone} />
                                                            {(this.state.isValidOwnerContact == false && this.state.ownerContact !== "") &&
                                                            <small className="form-text text-danger">Invalid Phone Number</small>}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <label className="col-md-3 col-sm-3 form-control-label font-weight-bold">Status<span className="text-danger">*</span></label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select name="status" className="form-control text-capitalize mt-2" required data-msg="Please select status" onChange={this.handleInputChange}>
                                                                <option value={config.ACTIVE}>Active</option>
                                                                <option value={config.INACTIVE}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isStoreReady()} onClick={this.handleSaveBtnClick}>Create Store</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        message: state.store.message,
        isInserted: state.store.isInserted,
        brands: state.store.brands,
        companies: state.store.companyList,
        channels: state.store.channels,
        types: state.store.types,
        areas: state.store.areas,
        countries: state.store.countries,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        addStore: function (data: any) {
            dispatch(addStore(data));
        },
        companyList: function () {
            dispatch(companyList());
        },
        logoutUser: function () {
            dispatch(logoutUser())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddStore);