import {
    CAT_LIST, GET_CAT, GET_SUBCAT_BY_CAT_ID
} from '../actions/types';
const initialState: any = {
    catList: [] || '',
    category: {},
    sub_category: [] || ''
};
const CategoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CAT_LIST:
            return {
                ...state,
                catList: action.payload
            };

        case GET_CAT:
            return {
                ...state,
                category: action.payload
            };
        case GET_SUBCAT_BY_CAT_ID:
            return {
                ...state,
                sub_category: action.payload
            };
        default:
            return state;
    }
};
export default CategoryReducer;