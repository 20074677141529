import { ENGINEERS_REPORT, TICKET_BY_CATEGORY_REPORT, RE_OPENED_TICKET_REPORT, ERROR, TICKET_REPORT, RESOLVED_SLA_TICKET_REPORT, OPENED_CLOSED_TICKET_REPORT, OPENED_SINCE_DAYS_TICKET_REPORT, TICKET_BY_PERIORITY_REPORT } from '../actions/types';
const initialState: any = {
    ticket_report: [] || "",
    reOpenedTickets: [] || "",
    ticketsByPeriority: {} || "",
    ticketsByCategory: {} || "",
    resolvedSlaTickets: [] || "",
    opendedClosedTickets: [] || "",
    openedSinceDaysTickets: [] || "",
    msg: "",
    currentPage: 0,
    pageCount: 0,
    numOfRows: 0
}

const reportReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ENGINEERS_REPORT: return {
            ...state,
            storesReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case TICKET_REPORT: return {
            ...state,
            ticket_report: action.payload
        };
        case RE_OPENED_TICKET_REPORT: return {
            ...state,
            reOpenedTickets: action.payload
        };
        case RESOLVED_SLA_TICKET_REPORT : return {
            ...state,
            resolvedSlaTickets: action.payload
        };
        case OPENED_CLOSED_TICKET_REPORT : return {
            ...state,
            opendedClosedTickets: action.payload
        };
        case OPENED_SINCE_DAYS_TICKET_REPORT : return {
            ...state,
            openedSinceDaysTickets: action.payload
        };
        case TICKET_BY_PERIORITY_REPORT : return {
            ...state,
            ticketsByPeriority: action.payload
        };
        case TICKET_BY_CATEGORY_REPORT : return {
            ...state,
            ticketsByCategory: action.payload
        };
        case ERROR: return {
            ...state,
            msg: action.msg
        }
        
        default: return state;
    }
}
export default reportReducer;