import { GROUP_LIST,ENGINEER_LIST, ENGINEER_ADMINS, USER_GET, USER_LIST, ROLE_LIST, USER_ADD, LOGOUT_USER, LOGIN_USER, COURSE_GROUP_LIST, USERS_BY_COURSE_GROUPS } from './types'
import Api from '../../components/Api'
import jwt_decode from "jwt-decode";
import { Redirect } from 'react-router-dom';
import React from 'react';
import { ticketListEng } from './ticket';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();


export const authAzure = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let url: any = "https://login.microsoftonline.com/2e9ce4de-1961-453a-bbb3-55cf28409943/oauth2/v2.0/authorize?client_id=067dd641-4a24-4bf2-8561-0af097be210b&scope=user.read%20openid%20profile%20offline_access&redirect_uri=http%3A%2F%2Flocalhost%3A3005%2Fauthentication%2Fbrowser-callback&client-request-id=16e98268-c693-44d4-8a6e-cd1673bf18c6&response_mode=query&response_type=code&x-client-SKU=msal.js.node&x-client-VER=1.2.0&x-client-OS=win32&x-client-CPU=x64&client_info=1"
        Api.get(url, {
            headers: { 
                'Authorization': 'Bearer ' + token,
                'Access-Control-Allow-Origin': '*'
        }
        })
            .then((response) => {
                console.log("sessadsd",response);
                // if(response.data.success){
                //     //sessionStorage.setItem('ticketLength', response.data.successResponse.length);
                //     dispatch({
                //         type: TICKET_LIST,
                //         payload: response.data.successResponse
                //     })
                // }
            }).catch(err => {
                if (err.response) {
                    console.log(err)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const groupList = () => {
    return function (dispatch: any) {
        // console.log("JAWAD SHAH");

        let token: any = sessionStorage.getItem('token');
        Api.get('/user/getGroups', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("response", response.data.data);
                dispatch({
                    type: GROUP_LIST,
                    payload: response.data.data
                })

            }).catch(err => {
                console.log("err", err);
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const roleList = () => {
    return function (dispatch: any) {
        console.log("JAWAD SHAH");

        let token: any = sessionStorage.getItem('token');
        Api.get('/user/getAllRoles')
            // {
            //     headers: { 'Authorization': 'Bearer ' + token }
            // })
            .then((response) => {
                console.log("response", response);
                dispatch({
                    type: ROLE_LIST,
                    payload: response.data.data
                })

            }).catch(err => {
                console.log("err", err);
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const userList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/user/getAll', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                dispatch({
                    type: USER_LIST,
                    payload: response.data.data
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const allEngineers = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var user: any = jwt_decode(token);
        Api.get(`/user/getEngineers/${user.company}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                dispatch({
                    type: ENGINEER_LIST,
                    payload: response.data.successResponse
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const EngineersAdmins = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/user/engineers_admins', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                dispatch({
                    type: ENGINEER_ADMINS,
                    payload: response.data.successResponse
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const addUser = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        console.log("addUser", data);
         Api.post('/user/add', data, {
            headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                console.log("response", response);
                window.location.href = "/users";

            }).catch(err => {
                console.log("err", err);
                if (err.response) {
                    console.log("err.response", err.response)
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_DUP_ENTRY") {
                            // error = "UserName Already Exist"
                            // alert(error);
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        // error = err.response.data.message[0].replace(/_/g, " ");
                        // alert(error);
                    }
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                    // dispatch({
                    //     type: USER_ADD,
                    //     isInserted: false,
                    //     payload: error
                    // })


                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getUser = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/user/get/${id}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                console.log("response", response);
                dispatch({
                    type: USER_GET,
                    payload: response.data.data
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getUserProfile = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/user/getUserProfile/${id}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                console.log("response", response);
                dispatch({
                    type: USER_GET,
                    payload: response.data.data
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getCourseGroupByUser = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/user/getCourseGroupUser/${id}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                console.log("response", response);
                dispatch({
                    type: USERS_BY_COURSE_GROUPS,
                    payload: response.data.data
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateUser = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/user/update/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ',
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                console.log("response", response);
                window.location.href = "/users";

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateProfile = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/user/profile/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ',
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                console.log("response JUNAID SHEIKH", response);
                if(response.data.file!=""){

                    sessionStorage.setItem('profileImage',response.data.file);
                }
                toast.success("Profile Updated");
                console.log("SASDDDS")
                window.location.href = `/profile/${id}`;
                // window.location.href="/profile";

            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message)
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updatePassword = (id: any, data: any) => {
    return function (dispatch: any) {
        console.log("data", data);
        let token: any = sessionStorage.getItem('token');
        Api.put(`/user/changePassword/${id}`, data, {
            headers: {
                'Authorization': 'Bearer '
            }
        })
            .then((response) => {
                console.log("response", response);
                window.location.href = `/profile/${id}`;
                // window.location.href="/profile";

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deleteUser = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/user/delete/${id}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                console.log("response", response);
                window.location.href = "/users";
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const loginUser = (email: any, password: any) => {
    return function (dispatch: any) {
        console.log("email: ", email);
        console.log("password: ", password);
        Api.post('/authentication/auth', {
            email: email,
            password: password
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("response1: ", response.data);
                    let token = response.data.accessToken;
                    let id = response.data.userId;
                    let name = response.data.name;
                    let email = response.data.email;
                    let username = response.data.username;
                    let company = response.data.company;
                    let role = response.data.role;
                    let profileImage = response.data.profileImage;

                    sessionStorage.setItem('userId', id);
                    sessionStorage.setItem('name', name);
                    sessionStorage.setItem('role', role);
                    sessionStorage.setItem('email', email);
                    sessionStorage.setItem('username', username);
                    sessionStorage.setItem('company', company);
                    sessionStorage.setItem('profileImage', profileImage);
                    sessionStorage.setItem('token', token);
                    // console.log("sessionStorage: ",sessionStorage);
                    if (response.data.role == 5) {
                        window.location.href = "/performance";
                    }
                    if (response.data.role == 3) {
                        window.location.href = "/requester";
                    }
                    if (response.data.role == 4 || response.data.role == 1) {
                        window.location.href = "/dashboard";
                    }

                    //window.location.href = "/dashboard";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    dispatch({
                        type: LOGIN_USER,
                        payload: error,
                        isLoggedIn: false
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}

export const courseGroupList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/course/getAllCourseGroups')
            // {
            //     headers: { 'Authorization': 'Bearer ' + token }
            // })
            .then((response) => {
                console.log("course groups respone", response);

                dispatch({
                    type: COURSE_GROUP_LIST,
                    payload: response.data.data
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const logoutUser = () => {
    return function (dispatch: any) {
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('role');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('company');
        sessionStorage.removeItem('profileImage');
        sessionStorage.removeItem('token');
        sessionStorage.clear();
        window.location.href = "/"
    }
}

export const forgotPassword = (email: any) => {
    return function (dispatch: any) {
        Api.post('/user/forgot-password', {
            email: email
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info("Your password has been updated and We have sent the email on given email address", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}