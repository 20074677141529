import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { companyList } from './../../redux/actions/company';
import jwt from "jsonwebtoken";
import config from '../config/setting'

// import ClipLoader from "react-spinners/ClipLoader";
class Sidebar extends Component<{companyList: () => {}, companies: any[]}, { loading: any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    this.props.companyList();
  }
  render() {
    let page = window.location.pathname.split("/");
    // console.log("page: ",page);
    let name = sessionStorage.name;
    let profileImage = sessionStorage.profileImage;
    console.log("profileImage",profileImage);
    
    let roleId:any = sessionStorage.getItem("role");
    // let tokendata: any = jwt.dec`ode(sessionStorage.token);
    // let roleId = 1;
    const { companies } = this.props;
    let companyId = companies[0] && companies[0].id;
    
    return (
      // <!-- Side Navbar -->
      <nav className="side-navbar">
        {/* <!-- Sidebar Header--> */}
        <div className="sidebar-header d-flex align-items-center">
          <div className="avatar">
            {
              profileImage && profileImage!= "undefined" ? 
              <img
              src={config.IMAGES_URL + profileImage}
              // src={'assets/img/profile_vector.png'}
              alt="..."
              className="img-fluid rounded-circle"
              style={{ height: '100%' }}
            />
            :
            <img
              src={process.env.PUBLIC_URL + "/assets/img/profile_vector.png"}
              // src="%PUBLIC_URL%/assets/img/profile_vector.png"
              alt="..."
              className="img-fluid rounded-circle"
              style={{ height: '100%' }}
            />
            }
          </div>
          <div className="title">
            <h1 className="h5">{name}</h1>
          </div>
        </div>
        {/* <!-- Sidebar Navidation Menus--> */}
        <ul className="list-unstyled">

          {(roleId == 1 || roleId == 4) &&
          <li className={page[1] === "dashboard" ? "active" : ""}>
            <Link to="/dashboard">
              {" "}
              <i className="fa fa-home"></i>Dashboard
            </Link>
          </li>
          }
          {(roleId == 5) &&
          <li className={page[1] === "performance" ? "active" : ""}>
            <Link to="/performance">
              {" "}
              <i className="fa fa-home"></i>Dashboard
            </Link>
          </li>
          }
          <li className={page[1] === "tickets" ? "active" : ""}>
            <Link to="/tickets">
              {" "}
              <i className="fa fa-ticket"></i>Tickets
            </Link>
          </li>
          {roleId == 3 &&
            <li className={page[1] === "requester" ? "active" : ""}>
              <Link to="/requester">
                {" "}
                <i className="fa fa-briefcase"></i>Requester
            </Link>
            </li>
          }

          {/* <li className={page[1] === "categories" ? "active" : ""}>
            <Link to="/categories">
              {" "}
              <i className="fa fa-briefcase"></i>Category
            </Link>
          </li> */}
          { roleId == 1 &&
          <>
            <li>
              <a
                href="#corporateDropDown"
                aria-expanded="false"
                data-toggle="collapse"
              >
                {" "}
                <i className="fa fa-business-time">&#xf0b1;</i>Corporate
            </a>
              {/* Conditions to open that ul if it's seected */}
              <ul
                id="corporateDropDown"
                className={
                  page[1] === "company" ||
                    page[1] === "stores" || page[1] === "add-store" || page[1] === "edit-store" ||
                    page[1] === "messages" || page[1] === "view-message" || page[1] === "add-message"
                    ? ""
                    : "collapse list-unstyled"
                }
              >
                <li className={page[1] === "companies" || page[1] === "add-company" || page[1] === "edit-company" ? "active" : ""}>
                  <Link to={`/edit-company/${companyId}`}>Company</Link>
                </li>
                <li className={page[1] === "stores" || page[1] === "add-store" || page[1] === "edit-store" ? "active" : ""}>
                  <Link to="/stores">Stores</Link>
                </li>
                {/* <li className={page[1] === "messages" || page[1] === "view-message" || page[1] === "add-message" ? "active" : ""}>
                  <Link to="/messages">Messages</Link>
                </li> */}
                {/* <li className={page[1] === 'global-settings' ? 'active' : ''}><Link to="/dashboard">Global Settings</Link></li> */}
              </ul>
            </li>
            <li>
            <a
              href="#securityDropDown"
              aria-expanded="false"
              data-toggle="collapse"
            >
              {" "}
              <i className="fa fa-lock"></i>Security
          </a>
            {/* Conditions to open that ul if it's seected */}
            <ul
              id="securityDropDown"
              className={
                page[1] === "users" || page[1] === "add-user" || page[1] === "edit-user" ||
                  page[1] === "groups" || page[1] === "add-group" || page[1] === "edit-group" ||
                  page[1] === "zones" || page[1] === "add-zone" || page[1] === "edit-zone"
                  ? ""
                  : "collapse list-unstyled"
              }
            >
              <li className={page[1] === "users" || page[1] === "add-user" || page[1] === "edit-user" ? "active" : ""}>
                <Link to="/users">Users</Link>
              </li>
              <li className={page[1] === "groups" || page[1] === "add-group" || page[1] === "edit-group" ? "active" : ""}>
                <Link to="/groups">Groups</Link>
              </li>
              {/* <li className={page[1] === "zones" || page[1] === "add-zone" || page[1] === "edit-zone" ? "active" : ""}>
                <Link to="/zones">Zones</Link>
              </li> */}
              {/* <li className={page[1] === "usergroups" || page[1] === "add-usergroup" || page[1] === "edit-usergroup" ? "active" : ""}>
                <Link to="/usergroups">User Groups</Link>
              </li> */}
              <li className={page[1] === "categories" || page[1] === "add-category" || page[1] === "edit-category" ? "active" : ""}>
                <Link to="/categories">Category</Link>
              </li>
              {/* <li className={page[1] === "subCategories" || page[1] === "add-subcategory" || page[1] === "edit-subcategory" ? "active" : ""}>
                <Link to="/subCategories">Sub Category</Link>
              </li> */}
              <li className={page[1] === "priorities" || page[1] === "add-priority" || page[1] === "edit-priority" ? "active" : ""}>
                <Link to="/priorities">Priority</Link>
              </li>

            </ul>
          </li>
            
          </>
        }
        {(roleId == 1 || roleId == 4) &&
          <li className={page[1] === "performance" ? "active" : ""}>
          <Link to="/performance">
            {" "}
            <i className="fa fa-line-chart"></i>Engineer's Performance
          </Link>
          </li>
        }
          <li className={page[1] === "reports" ? "active" : ""}>
            <Link to="/reports">
              {" "}
              <i className="fa fa-newspaper-o"></i>Analytics
            </Link>
          </li>
          {/* <li>
          <Link to="/forum">
              {" "}
              <i className="fa fa-calendar"></i>Forum
            </Link>
          </li> */}


          {/* {roleId == "1" &&
          <>
          <li className={page[1] === "calendar" ? "active" : ""}>
            <Link to="/calendar">
              {" "}
              <i className="fa fa-calendar"></i>Calendar
            </Link>
          </li>
          <li className={page[1] === "analytics" ? "active" : ""}>
            <Link to="/reports">
              {" "}
              <i className="fa fa-newspaper-o"></i>Analytics
            </Link>
          </li>
          <li className={page[1] === "forum" ? "active" : ""}>
            <Link to="/forum">
              {" "}
              <i className="fa fa-calendar"></i>Forum
            </Link>
          </li>
          </>
          } */}
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    companies: state.company.companies
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    companyList: function () {
      dispatch(companyList())
  }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);