import { STORE_GET, STORE_GET_BY_COMPANY, STORE_LIST, COMPANY_LIST } from './types'
import Api from '../../components/Api'
import { Redirect } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import React from 'react';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { POSITION } from 'react-toastify/dist/utils';
toast.configure();

export const storesList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let user: any = jwt_decode(token);
        console.log("user",user);
        let data={
            role: user.role,
            company: user.company
        }
        console.log("data",data);
        // Api.get(`/tickets/groups_company/${user.company}`, {
            // Api.get('/store/getAll',
        
        Api.post(`/store/getAll`, data, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                dispatch({
                    type: STORE_LIST,
                    payload: response.data.data
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const companyList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/store/companies', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                dispatch({
                    type: COMPANY_LIST,
                    payload: response.data.successResponse
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addStore = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/store/add', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                // console.log("response",response);
                window.location.href = "/stores";

            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_DUP_ENTRY") {
                            error = "Store Name Already Exist"
                            alert(error);
                            //toast.error(error);
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
                // if(err.response.data.message === 'ER_DUP_ENTRY') {
                //     toast.error('Store Name already Exist!');
                // }
            });
    }
}
export const getStore = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/store/get/${id}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {

                dispatch({
                    type: STORE_GET,
                    payload: response.data.data
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getStorsByCompany = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/store/stores_by_companies/${id}`,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {

                dispatch({
                    type: STORE_GET_BY_COMPANY,
                    payload: response.data.successResponse,
                    store_count: response.data.store_count
                })

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateStore = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/store/update/${id}`, data,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then((response) => {
                console.log("response", response);
                window.location.href = "/stores";

            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deleteStore = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/store/delete/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("response", response);
                window.location.href = "/stores";
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}