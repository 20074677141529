import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Chart from 'chart.js'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { deleteUser, userList } from '../../redux/actions/user';
import { deleteZone, getGroupByZones, zoneList } from '../../redux/actions/zone';


function viewFormatter(cell: any, row: any) {
    return (
        <div>
            <a href="#" title="View Learner" className="btn btn-outline-primary"><i className="fa fa-eye"></i></a>
        </div>
    );
}

class ActionFormatter extends Component<{ row: any, data:any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id:any) => {
        console.log("id",this.props);

        this.props.data.deleteZone(id);
    };
    render() {
        const { row } = this.props;
        return (
            <div>
                <Link to="#" title="Delete Zone" data-toggle="modal" data-target={`#deleteZone${row.id}`} className="btn btn-outline-danger mx-2"><i className="fa fa-trash"></i></Link>
                <Link to={`/edit-zone/${row.id}`}  title="Edit Zone"  className="btn btn-outline-primary"><i className="fa fa-edit"></i></Link>
                {/* <Link title="Edit Store" className="btn btn-outline-primary" to={`/edit-store/${row.store_id}`}><i className="fa fa-edit"></i></Link> */}
                {/* <!-- B/U Modal--> */}
                <div id={`deleteZone${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Zone</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to Delete this Zone?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button type="button" data-dismiss="modal" className="btn btn-primary" onClick={()=>{this.handleDelete(row.id)}}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function actionFormatter(cell: any, row: any, props: any) {
    return (
        // <ActionFormatter row={row} blockunblock={props.blockunblockstore} />
        <ActionFormatter row={row} data={props}/>
    );
}

class Users extends Component<{zones:any[],zoneChild:any[],zoneList:()=>{},deleteZone:()=>{},getGroupByZones:(id:any)=>{}},{showDiv:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {showDiv:''}
    }
    componentDidMount(){
        this.props.zoneList();
    }
    render() {
     
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-zone" /> },
            noDataText: 'User Not Found'
        };

        return (
          <div className="page">
            <Topbar />
            <div className="page-content d-flex align-items-stretch">
              <Sidebar />
              <div className="content-inner">
                {/* <!-- Page Header--> */}
                <header className="page-header py-0">
                  <div className="container-fluid">
                    <div className="d-flex justify-content-between py-3">
                      <h4 className="mt-2">Zones Management</h4>
                    </div>
                  </div>
                </header>
                
                   <section className="tables">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="card">
                                    <div className="card-body text-capitalize">
                                        <BootstrapTable  version='4' data={this.props.zones} search={true} pagination={this.props.zones.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='users.csv' hover>
                                            <TableHeaderColumn dataField='id' csvHeader='Sr. #' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                            <TableHeaderColumn dataField='name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                            <TableHeaderColumn dataField='zoneGroup' csvHeader='Parent' width='160' formatExtraData={this.props} dataFormat={zoneParent} columnTitle>Parent</TableHeaderColumn>
                                            <TableHeaderColumn dataField='zoneGroup' csvHeader='Child' width='160' formatExtraData={this.props} dataFormat={zoneChild} columnTitle>Child</TableHeaderColumn>
                                            <TableHeaderColumn dataField='description' csvHeader='Description' width='100' columnTitle>Description</TableHeaderColumn>
                                            <TableHeaderColumn dataField='status' csvHeader='Status' width='100' formatExtraData={this.props} dataFormat={statusFormatter} columnTitle>Status</TableHeaderColumn>
                                            <TableHeaderColumn dataField='action' width='170' formatExtraData={this.props} dataFormat={actionFormatter} export={false}>Actions</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <Footer />
              </div>
            </div>
          </div>
        );
    }
};

function statusFormatter(cell: any) {
    // console.log("cell",cell);
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 1 ? "Active" : "Inactive"}</span>
        </div>
    )
}

function zoneParent(cell: any) {
    return (
        <span>{cell[0].parent.name}</span>
    )
}
function zoneChild(cell: any,row:any,props:any) {
    // console.log("props",props);
    return (
        <CountGroups row={row} data={props}/>
    )
}
class CountGroups extends Component<{ row: any, data:any}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    getGroupNames = (id:any) => {
        this.props.data.getGroupByZones(id);
    };
    render() {
        const { row,data } = this.props;
        return (
            
            <div>
                <Link to="#" title="Groups" data-toggle="modal" data-target={`#countGroups${row.id}`} className="mx-2" onClick={()=>{this.getGroupNames(row.id)}}>{row.zoneGroup.length == 1 ? '1 Member'  : row.zoneGroup.length +' Members'}</Link>
                <div id={`countGroups${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Groups</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                { data.zoneChild.map((element:any,index:any) => {
                                    return <p>{`${index+1}. ${element.child.name}`}</p>    
                                })}
                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    console.log("state",state);
    return {
        zones:state.zone.zoneList,
        zoneChild:state.zone.getGroupByZones,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        zoneList: function () {
            dispatch(zoneList())
        },
        getGroupByZones: function (id:any) {
            dispatch(getGroupByZones(id))
        },
        deleteZone: function (id:any) {
            dispatch(deleteZone(id))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Users);