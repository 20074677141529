import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import './App.css';

import Login from '././components/login/login'
import Forgot from '././components/forgot-password/forgot'
import Dashboard from '././components/dashboard/dashboard'

import Store from '././components/store/store'
import StoreAdd from '././components/store/add-store'
import StoreEdit from '././components/store/edit-store'
import AddCompany from './components/company/add-company'
import Messages from '././components/messages/messages'
import ViewMessages from '././components/messages/view-messages'
import AddMessages from '././components/messages/add-message'
import Users from '././components/users/users'
import UserAdd from '././components/users/add-user'
import UserEdit from '././components/users/edit-user'
import UserView from '././components/users/view-user'
import UserProfile from '././components/users/user-profile'
import UserRecord from '././components/users/user-record'
import Groups from '././components/groups/groups'
import GroupAdd from '././components/groups/add-group'
import GroupEdit from '././components/groups/edit-group'
import Zones from '././components/zones/zones'
import ZoneAdd from '././components/zones/add-zone'
import ZoneEdit from '././components/zones/edit-zone'
import Reports from '././components/reports/report'
import Forum from '././components/forum/forum'
import Calendar from '././components/calendar/calendar'

// TimeAgo
// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en'

// Learner Routes
import store from './redux/store/store';
import Ticket from './components/tickets/ticket';
import TicketDetails from './components/tickets/ticketDetails';
import EditTicket from './components/tickets/edit-ticket';
import Requester from './components/requester/requester';
import AddCategory from './components/category/add-category';
import Category from './components/category/category';
import EditCategory from './components/category/edit-category';
import SubCategory from './components/sub-category/sub-category';
import AddSubCategory from './components/sub-category/add-subcategory';
import EditSubCategory from './components/sub-category/edit-sub-category';
import Priority from './components/priority/priority';
import AddPriority from './components/priority/add-priority';
import EditPriority from './components/priority/edit-priority';
import Company from './components/company/company';
import EditCompany from './components/company/edit-company';
import SendFeedBack from './components/feedback';
import Performance from './components/engineer-performance/engineer-performance';




//TimeAgo.addDefaultLocale(en)
const Notfound = () => <h1 className="text-center">Not found</h1>
function App() {
  return (
    // forceRefresh is used to force React Router to reload the page on every navigation
    <div className="App">
      <Provider store={store} >
        <Router forceRefresh>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/forgot" component={Forgot} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/add-store" component={StoreAdd} />
            <Route exact path="/edit-store/:id" component={StoreEdit} />
            <Route exact path="/stores" component={Store} />
            <Route exact path="/add-company" component={AddCompany} />
            <Route exact path="/edit-company/:id" component={EditCompany} />
            <Route exact path="/companies" component={Company} />
            <Route exact path="/messages" component={Messages} />
            <Route exact path="/view-message" component={ViewMessages} />
            <Route exact path="/add-message" component={AddMessages} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/add-user" component={UserAdd} />
            <Route exact path="/edit-user/:id" component={UserEdit} />
            <Route exact path="/profile/:id" component={UserProfile} />
            <Route exact path="/zones" component={Zones} />
            <Route exact path="/add-zone" component={ZoneAdd} />
            <Route exact path="/edit-zone/:id" component={ZoneEdit} />
            <Route exact path="/view-user" component={UserView} />
            <Route exact path="/user-record" component={UserRecord} />
            <Route exact path="/groups" component={Groups} />
            <Route exact path="/add-group" component={GroupAdd} />
            <Route exact path="/edit-group/:id" component={GroupEdit} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/forum" component={Forum} />
            <Route exact path="/edit-ticket/:id" component={EditTicket} />
            <Route exact path="/tickets" component={Ticket} />
            <Route exact path="/requester" component={Requester} />
            <Route exact path="/ticket-details/:id" component={TicketDetails} />
            <Route exact path="/categories" component={Category} />
            <Route exact path="/add-category" component={AddCategory} />
            <Route exact path="/edit-category/:id" component={EditCategory} />
            <Route exact path="/subCategories" component={SubCategory} />
            <Route exact path="/add-subcategory" component={AddSubCategory} />
            <Route exact path="/edit-subcategory/:id" component={EditSubCategory} />
            <Route exact path="/calendar" component={Calendar} />
            <Route exact path="/priorities" component={Priority} />
            <Route exact path="/performance" component={Performance} />
            <Route exact path="/add-priority" component={AddPriority} />
            <Route exact path="/edit-priority/:id" component={EditPriority} />
            <Route exact path="/feedback/:id" component={SendFeedBack} />

            <Route component={Notfound} />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;