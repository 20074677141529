import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import config from '../config/setting';
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { deleteGroup, getStoreByGroup, groupList } from '../../redux/actions/groupStore';
import { logoutUser } from '../../redux/actions/user';


function viewFormatter(cell: any, row: any) {
    return (
        <div>
            <a href="#" title="View Learner" className="btn btn-outline-primary"><i className="fa fa-eye"></i></a>
        </div>
    );
}

class ActionFormatter extends Component<{ row: any, data:any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id:any) => {
        this.props.data.deleteGroup(id);
    };
    render() {
        const { row } = this.props;
        return (
            <div>
                <a href="#" title="Delete Group" data-toggle="modal" data-target={`#deleteGroup${row.id}`} className="btn btn-outline-danger mx-2"><i className="fa fa-trash"></i></a>
                { row.default == 0 && <Link to={`/edit-group/${row.id}`}  title="Edit Group"  className="btn btn-outline-primary"><i className="fa fa-edit"></i></Link> }
                { row.default == 1 && <a href="#" data-toggle="modal" data-target={`#defaultGroup${row.id}`} title="Can't Edit Default Group"  className="btn btn-outline-primary"><i className="fa fa-edit"></i></a> }
                {/* <Link title="Edit Store" className="btn btn-outline-primary" to={`/edit-store/${row.store_id}`}><i className="fa fa-edit"></i></Link> */}
                {/* <!-- B/U Modal--> */}
                <div id={`deleteGroup${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Group</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to Delete this Group?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button type="button" data-dismiss="modal" className="btn btn-primary" onClick={()=>{this.handleDelete(row.id)}}>Confirm</button>
                                {/* <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button> */}
                            </div>
                        </div>
                    </div>
                </div>


                <div id={`defaultGroup${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Default Group</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>You cannot Edit Default Group</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


function actionFormatter(cell:any, row: any, props: any) {
    return (
        // <ActionFormatter row={row} blockunblock={props.blockunblockstore} />
        <ActionFormatter row={row} data={props}/>
    );
}

function statusFormatter(cell: any) {
    // console.log("cell",cell);
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 1 ? "Active" : "Inactive"}</span>
        </div>
    )
}
function territoryFormatter(cell: any) {
    // console.log("cell",cell);
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 ? "Enabled" : "Disabled")}>{cell == 1 ? "Enabled" : "Disabled"}</span>
        </div>
    )
}
function defaultFormatter(cell: any) {
    // console.log("cell",cell);
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 ? "True" : "False")}>{cell == 1 ? "True" : "False"}</span>
        </div>
    )
}
function groupStores(cell: any,row:any,props:any) {
    return (
        <CountStores row={row} data={props}/>
    )
}
class CountStores extends Component<{ row: any, data:any}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    getStoreNames = (id:any) => {
        this.props.data.getStoreByGroup(id);
    };
    render() {
        const { row,data } = this.props;
        return (
            <div>
                <a href="#" title="Stores" data-toggle="modal" data-target={`#countStores${row.id}`} className="mx-2" onClick={()=>{this.getStoreNames(row.id)}}>{row.groupStore.length == 1 ? '1 Member'  : row.groupStore.length +' Members'}</a>
                <div id={`countStores${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Stores</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                { data.groupStores.map((element:any,index:any) => {
                                    return <p>{`${index+1}. ${element.store.name}`}</p>    
                                })}
                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Group extends Component<{groups:any[],groupStores:any[],groupList:()=>{},deleteGroup:()=>{},getStoreByGroup:(id:any)=>{}, logoutUser: () => {}},{showDiv:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {showDiv:''}
    }
    componentDidMount(){
        this.props.groupList();
    }
    render() {
        // console.log(this.props.groups);
        let roleId: any = ""
       
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }

        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-group" /> },
            noDataText: 'Store Not Found'
        };

        return (
          <div className="page">
            <Topbar />
            <div className="page-content d-flex align-items-stretch">
              <Sidebar />
              <div className="content-inner">
                {/* <!-- Page Header--> */}
                <header className="page-header py-0">
                  <div className="container-fluid">
                    <div className="d-flex justify-content-between py-3">
                      <h4 className="mt-2">Groups Management</h4>
                    </div>
                  </div>
                </header>
                
                   <section className="tables">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="card">
                                    <div className="card-body text-capitalize">
                                        <BootstrapTable  version='4' data={this.props.groups} search={true} pagination={this.props.groups.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='groups.csv' hover>
                                            <TableHeaderColumn dataField='id' csvHeader='Sr. #' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                            <TableHeaderColumn dataField='name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                            <TableHeaderColumn dataField='groupStore' csvHeader='Store' width='100' formatExtraData={this.props} dataFormat={groupStores} columnTitle export={false}>Store</TableHeaderColumn>
                                            <TableHeaderColumn dataField='territary' csvHeader='Territory' formatExtraData={this.props} dataFormat={territoryFormatter} width='100' columnTitle>Territory</TableHeaderColumn>
                                            <TableHeaderColumn dataField='default' csvHeader='Default' formatExtraData={this.props} dataFormat={defaultFormatter} width='100' columnTitle>Default</TableHeaderColumn>
                                            <TableHeaderColumn dataField='status' csvHeader='Status' formatExtraData={this.props} dataFormat={statusFormatter} width='100' columnTitle>Status</TableHeaderColumn>
                                            <TableHeaderColumn dataField='action' width='120' formatExtraData={this.props}  dataFormat={actionFormatter} export={false}>Actions</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <Footer />
              </div>
            </div>
          </div>
        );
    }
};

const mapStateToProps = (state: any) => {
    return {
        groups: state.group.groupList,
        groupStores: state.group.storesByGroupGet,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        groupList: function () {
            dispatch(groupList())
        },
        deleteGroup: function (id:any) {
            dispatch(deleteGroup(id))
        },
        getStoreByGroup: function (id:any) {
            dispatch(getStoreByGroup(id))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Group);