/* eslint-disable react/require-render-return */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Select from 'react-select'
import config from '../config/setting'
import Topbar from '../topbar/topbar'
import Sidebar from '../sidebar/sidebar'
import Footer from '../footer/footer'
import ReactPaginate from 'react-paginate';
import { currency } from '../config/setting'
import { ReportsProps, ReportsState } from '../../interfaces/reports';
import moment from 'moment';
import { toast } from 'react-toastify';
import { OpenedClosedTicketReport, OpenedSinceDaysTicketReport, ReOpenedTicketReport, ResolvedSLATicketReport, TicketByCategoryReport, TicketByPeriorityReport, ticketListReport } from './../../redux/actions/reportAction';
//Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// import JSZip from 'jszip';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import jwt_decode from 'jwt-decode';
import { logoutUser } from '../../redux/actions/user';
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
const $ = require("jquery");
$.DataTable = require("datatables.net");

class Reports extends Component<ReportsProps, ReportsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      reportType: "",
      storeType: "",
      showDate: false,
      validTimeFlag: false,
      startDate: "",
      isLoad: false,
      validTimeFlagFrom: false,
      validTimeFlagTo: false,
      endDate: "",
      settings: {
        dom: 'Bfrtip',
        "paginate": true,
        "bInfo": false,
        buttons: [
          'copy',
          'excel',
          'print',
          {
            extend: 'pdfHtml5',
            orientation: 'landscape',
            pageSize: 'LEGAL',
            text: 'PDF',
            footer: true,
            customize: function (doc: any) {
              doc.content[1].table.widths =
                Array(doc.content[1].table.body[0].length + 1).join('*').split('');
            },
          }
        ],
        // Footer settings
        // "footerCallback": function (tfoot: any, data: any, start: any, end: any, display: any) {
        //   var api = this.api(), data;
        //   // Total over all pages
        //   $(api.column(5).footer()).html(
        //     `${currency}.` +
        //     api.column(5).data().reduce(function (a: any, b: any) {
        //       return parseInt(a) + parseInt(b);
        //     }, 0)
        //   );
        // }
      }
    }
    this.handleValidTime = this.handleValidTime.bind(this)
    this.reportGenerator = this.reportGenerator.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    document.title = "EathosServices | Reports"
    //this.props.storesList();
  }

  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.value > 0) {
      this.setState({ storeType: e.value });
    } else {
      this.setState({ storeType: " " });
    }
    //console.log("E ", e.value);
  };

  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
    if (event.target.value === "tickets") {
      this.setState({ showDate: true })
    } else if (event.target.value === "reOpened") {
      this.setState({ showDate: true })
    } else if (event.target.value === "OpendClosed") {
      this.setState({ showDate: true })
    } else if (event.target.value === "resovedSLA") {
      this.setState({ showDate: true })
    } else if (event.target.value === "sinceDays") {
      this.setState({ showDate: true })
    } else if (event.target.value === "ByPeriority") {
      this.setState({ showDate: true })
    } else if (event.target.value === "ByCategory") {
      this.setState({ showDate: true })
    }
  }

  handleValidTime(event: { target: { name: any; value: any } }) {
    let selectedDateTime = new Date(event.target.value)
    let validMinDateTime = new Date();
    if (selectedDateTime <= validMinDateTime) {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name == 'startDate') {
        this.setState({ validTimeFlagFrom: true })
      }
      else {
        this.setState({ validTimeFlagTo: true })
      }
    } else {
      toast.error("Invalid date and time selected");
      if (event.target.name == 'startDate') {
        this.setState({ validTimeFlagFrom: false })
      }
      else {
        this.setState({ validTimeFlagTo: false })
      }
    }
  }

  isReportReady = () => {
    let { reportType, startDate, endDate, validTimeFlagFrom, validTimeFlagTo } = this.state;
    if (reportType === "tickets") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    } else if (reportType === "reOpened") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    } else if (reportType === "OpendClosed") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    } else if (reportType === "resovedSLA") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    } else if (reportType === "sinceDays") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    } else if (reportType === "ByPeriority") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    } else if (reportType === "ByCategory") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
  }

  handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    let { reportType, startDate, endDate, settings } = this.state;
    let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
    let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
    let data: any = {
      start: startDateUtc,
      end: endDateUtc,
      // assignee_id: 
      // store_id: (this.state.storeType == '' ? 0 : this.state.storeType)
    }

    if (reportType === "tickets") {
      // this.props.ticketListReport(data, selectedPage);
      setTimeout(
        function () {
          table = $('#example').DataTable(settings, { responsive: true, "deferRender": true });
        }.bind(this), 1500);
      var table = $('#example').DataTable();
      table.destroy();
    } else if (reportType === "reOpened") {
      // this.props.ReOpenedTicketReport(data, selectedPage);
      setTimeout(
        function () {
          $('#smix').DataTable(settings, { responsive: true, "deferRender": true });
        }.bind(this), 1500);
      var table = $('#smix').DataTable();
      table.destroy();
    }
  };

  validateTimeFromTo = () => {
    let { startDate, endDate } = this.state;
    if (startDate >= endDate) {
      toast.error("End date cannot be less than start date")
    }
    else {
      this.reportGenerator();
    }
    // this.setState({ isLoad: true })
  }

  reportGenerator() {
    const token:any = sessionStorage.getItem("token");
    const assignee:any = jwt_decode(token);
    let roleId = assignee.role;
    let userId = assignee.userId;
    let { reportType, startDate, endDate, settings } = this.state;
    let { ticket_report, ticketsByCategory, ticketsByPeriority, reOpenedTickets, resolvedSlaTickets, openedSinceDaysTickets, opendedClosedTickets } = this.props;
    ticket_report.length = 0;
    reOpenedTickets.length = 0;
    ticketsByCategory.length = 0;
    ticketsByPeriority.length = 0;
    resolvedSlaTickets.length = 0;
    opendedClosedTickets.length = 0;
    openedSinceDaysTickets.length = 0;
    let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
    let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
    
    let data: any = {
      start: startDateUtc,
      end: endDateUtc,
      assignee_id: (roleId === 5 ? userId : "")
      // store_id: (this.state.storeType == '' ? 0 : this.state.storeType)
    }
    if (reportType === "tickets") {
      this.props.ticketListReport(data);
      setTimeout(
        function () {
          $('#example').DataTable(settings, { responsive: true, "deferRender": true });
        }.bind(this), 1500);
      var table = $('#example').DataTable();
      table.destroy();
    } else if (reportType === "reOpened") {
      this.props.ReOpenedTicketReport(data);
      setTimeout(
        function () {
          table = $('#re_open').DataTable(settings, { responsive: true, "deferRender": true });
        }.bind(this), 1500);
      var table = $('#re_open').DataTable();
      table.destroy();
    }else if (reportType === "OpendClosed") {
      this.props.OpenedClosedTicketReport(data);
      setTimeout(
        function () {
          table = $('#open_close').DataTable(settings, { responsive: true, "deferRender": true });
        }.bind(this), 1500);
      var table = $('#open_close').DataTable();
      table.destroy();
    }else if (reportType === "resovedSLA") {
      this.props.ResolvedSLATicketReport(data);
      setTimeout(
        function () {
          table = $('#resolved_sla').DataTable(settings, { responsive: true, "deferRender": true });
        }.bind(this), 1500);
      var table = $('#resolved_sla').DataTable();
      table.destroy();
    }else if (reportType === "sinceDays") {
      this.props.OpenedSinceDaysTicketReport(data);
      setTimeout(
        function () {
          table = $('#since_days').DataTable(settings, { responsive: true, "deferRender": true });
        }.bind(this), 1500);
      var table = $('#since_days').DataTable();
      table.destroy();
    }else if (reportType === "ByPeriority") {
      this.props.TicketByPeriorityReport(data);
      setTimeout(
        function () {
          table = $('#by_periority').DataTable(settings, { responsive: true, "deferRender": true });
        }.bind(this), 1500);
      var table = $('#by_periority').DataTable();
      table.destroy();
    }else if (reportType === "ByCategory") {
      this.props.TicketByCategoryReport(data);
      setTimeout(
        function () {
          table = $('#by_category').DataTable(settings, { responsive: true, "deferRender": true });
        }.bind(this), 1500);
      var table = $('#by_category').DataTable();
      table.destroy();
    }

    this.setState({ isLoad: true })
  }

  render() {
    let { msg, ticket_report, reOpenedTickets, ticketsByCategory, ticketsByPeriority, resolvedSlaTickets, openedSinceDaysTickets, opendedClosedTickets } = this.props;
    console.log("re_penenticket_report:   ---------", openedSinceDaysTickets);
    let { reportType } = this.state;
    // console.log("reportType", reportType)
    let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')

    //   console.log("numOfRows", numOfRows)
    //   console.log("pageCount", pageCount)
    //   console.log("currentPage", currentPage)


    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, config.secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />
    }
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header pb-1">
              <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>Reports Management</h4>
                </div>
              </div>
            </header>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p className="text-center font-weight-light text-danger mb-4">
                            {msg}
                          </p>
                        </strong>

                        <div className="form-horizontal">
                          <div className="row form-group">
                            <label
                              className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                              style={{ paddingTop: "1em" }}
                            >
                              Report Type
                                  <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9 col-sm-9">
                              <select
                                name="reportType"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select brand"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Report Type</option>
                                {/* <optgroup label="Sales"> */}
                                <option value="tickets">Incident Report</option>
                                <option value="OpendClosed">Open and Closed Incidents by Category</option>
                                <option value="reOpened">Reopened Incidents</option>
                                <option value="sinceDays">Incident Aging Report</option>
                                <option value="resovedSLA">Resolved Incidents Meeting SLA Targets</option>
                                <option value="ByPeriority">Incidents by Priority</option>
                                <option value="ByCategory">Incidents by Category</option>
                                {/* </optgroup> */}

                              </select>
                            </div>
                          </div>
                          {/* {(this.state.reportType !== "customer" && this.state.reportType !== "discount" && this.state.reportType !== "store") &&
                            <div className="row form-group">
                              <label
                                className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                                style={{ paddingTop: "1em" }}
                              >
                                Report By Stores
                            </label>
                              <div className="col-md-9 col-sm-9">
                                <Select
                                  //isMulti
                                  name="storeType"
                                  isClearable
                                  options={options}
                                  className="text-capitalize select mt-2"
                                  classNamePrefix="select"
                                  onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                />
                              </div>
                            </div>
                          } */}
                          {/* {this.state.showDate == true && ( */}
                          {/* {this.state.showDate == true && ( */}
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label font-weight-bold">
                                  Start Date
                                        <small className="text-danger">*</small>
                                </label>
                                <input
                                  id="start"
                                  type="datetime-local"
                                  name="startDate"
                                  max={maxDate}
                                  required
                                  data-msg="Please enter Start Date"
                                  className="input-material"
                                  onChange={this.handleValidTime}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label font-weight-bold">
                                  End Date
                                        <small className="text-danger">*</small>
                                </label>
                                <input
                                  id="end"
                                  type="datetime-local"
                                  name="endDate"
                                  max={maxDate}
                                  required
                                  data-msg="Please enter End Date"
                                  className="input-material"
                                  onChange={this.handleValidTime}
                                />
                              </div>
                            </div>
                          </div>
                          {/* )} */}
                        </div>

                        <div className="form-group float-right mt-3">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isReportReady()}
                            onClick={this.validateTimeFromTo}
                          >
                            Generate Report
                          </button>
                        </div>
                      </div>

                      <div className="row">
                        {/* Reports Here */}
                        {(ticket_report && ticket_report.length > 0) ?
                          <div className="col-lg-12 col-12">
                            <div className="card">
                              <div className="card-body text-capitalize">
                                <table
                                  className="table-custom"
                                  id="example"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Incident Id</th>
                                      <th>Owner</th>
                                      <th>Reporter</th>
                                      <th>Status</th>
                                      <th>Priority</th>
                                      <th>Category</th>
                                      <th>Sub Category</th>
                                      <th>Date Created</th>
                                      <th>Last Updated</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ticket_report.map(
                                      (data: any, index: any) => (
                                        <tr key={index}>
                                          <td>{data.id}</td>
                                          <td>{data.assignee_name}</td>
                                          <td>{data.requester_name}</td>
                                          <td>{data.ticket_status_description}</td>
                                          <td>{data.ticket_priority_description}</td>
                                          <td>{data.cat_name}</td>
                                          <td>{data.sub_category_name}</td>
                                          <td>{moment(data.createdAt).local().format('DD MMM YYYY HH:mm')}</td>
                                          <td>{moment(data.updatedAt).local().format('DD MMM YYYY HH:mm')}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        :(reOpenedTickets && reOpenedTickets.length > 0) ?
                          <div className="col-lg-12 col-12">
                            <div className="card">
                              <div className="card-body text-capitalize">
                                <table
                                  className="table-custom"
                                  id="re_open"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Incident Id</th>
                                      <th>Owner</th>
                                      <th>Reporter</th>
                                      <th>Status</th>
                                      <th>Priority</th>
                                      <th>Category</th>
                                      <th>Sub Category</th>
                                      <th>Date Created</th>
                                      <th>Last Updated</th>
                                      <th>ReOpened Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {reOpenedTickets.map(
                                      (data: any, index: any) => (
                                        <tr key={index}>
                                          <td>{data.id}</td>
                                          <td>{data.assignee_name}</td>
                                          <td>{data.requester_name}</td>
                                          <td>{data.ticket_status_description}</td>
                                          <td>{data.ticket_priority_description}</td>
                                          <td>{data.cat_name}</td>
                                          <td>{data.sub_category_name}</td>
                                          <td>{moment(data.createdAt).local().format('DD MMM YYYY HH:mm')}</td>
                                          <td>{moment(data.updatedAt).local().format('DD MMM YYYY HH:mm')}</td>
                                          <td>{moment(data.re_assigned_time).local().format('DD MMM YYYY HH:mm')}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        :(resolvedSlaTickets && resolvedSlaTickets.length > 0) ?
                          <div className="col-lg-12 col-12">
                            <div className="card">
                              <div className="card-body text-capitalize">
                                <table
                                  className="table-custom"
                                  id="resolved_sla"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Incident Id</th>
                                      <th>Status</th>
                                      <th>Priority</th>
                                      <th>Response SLA Target</th>
                                      <th>Response SLA Status</th>
                                      <th>Resolution SLA Target</th>
                                      <th>Resolution SLA Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {resolvedSlaTickets.map(
                                      (data: any, index: any) => (
                                        <tr key={index}>
                                          <td>{data.id}</td>
                                          <td>{data.ticket_status_description}</td>
                                          <td>{data.ticket_priority_description}</td>
                                          <td>{data.priority_response_target} minutes</td>
                                          {/* <td><span {...(data.response_sla_status == 'Meet Time' ? {style: { background: "green", padding: 3, borderRadius: 5, color: "#fff" }} : {style: { background: "red", padding: 3, borderRadius: 5, color: "#fff" }})}>{data.response_sla_status}</span></td> */}
                                          <td>{data.response_sla_status}</td>
                                          <td>{data.priority_resolution_target} minutes</td>
                                          {/* <td><span {...(data.priority_resolution_target == 'Meet Time' ? {style: { background: "green", padding: 3 , borderRadius: 5, color: "#fff"  }} : {style: { background: "red", padding: 3, borderRadius: 5, color: "#fff" }})}>{data.resolution_sla_status}</span></td> */}
                                          <td>{data.resolution_sla_status}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        :(opendedClosedTickets && opendedClosedTickets.length > 0) ?
                          <div className="col-lg-12 col-12">
                            <div className="card">
                              <div className="card-body text-capitalize">
                                <table
                                  className="table-custom"
                                  id="open_close"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Incident Id</th>
                                      <th>Owner</th>
                                      <th>Reporter</th>
                                      <th>Status</th>
                                      <th>Category</th>
                                      <th>Sub Category</th>
                                      <th>Date Created</th>
                                      <th>Last Updated</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {opendedClosedTickets.map(
                                      (data: any, index: any) => (
                                        <tr key={index}>
                                          <td>{data.id}</td>
                                          <td>{data.assignee_name}</td>
                                          <td>{data.requester_name}</td>
                                          <td>{data.ticket_status_code == 1 ? "Opened" : (data.ticket_status_code == 8) ? "Closed" : (data.ticket_status_code == 2) ? "Assigned": ""}</td>
                                          <td>{data.cat_name}</td>
                                          <td>{data.sub_category_name}</td>
                                          <td>{moment(data.createdAt).local().format('DD MMM YYYY HH:mm')}</td>
                                          <td>{moment(data.updatedAt).local().format('DD MMM YYYY HH:mm')}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        :(openedSinceDaysTickets && openedSinceDaysTickets.length > 0) ?
                          <div className="col-lg-12 col-12">
                            <div className="card">
                              <div className="card-body text-capitalize">
                                <table
                                  className="table-custom"
                                  id="since_days"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Incident Id</th>
                                      <th>Status</th>
                                      <th>Priority</th>
                                      <th>Open Since</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {openedSinceDaysTickets.map(
                                      (data: any, index: any) => (
                                        <tr key={index}>
                                          <td>{data.id}</td>
                                          <td>{data.ticket_status_description}</td>
                                          <td>{data.ticket_priority_description}</td>
                                          <td><span className="ml-3">{`${data.since_days == 0 ? "Today" : data.since_days + " days"}`}</span> </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        :(ticketsByPeriority && ticketsByPeriority.length > 0) ?
                          <div className="col-lg-12 col-12">
                            <div className="card">
                              <div className="card-body text-capitalize">
                                <table
                                  className="table-custom"
                                  id="by_periority"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Priority</th>
                                      <th>Total Tickets</th>
                                      <th>Percentage (%)</th>
                                      {/* <th>Open Since</th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ticketsByPeriority.map(
                                      (data: any, index: any) => (
                                        <tr key={index}>
                                          <td>{data.ticket_priority_description}</td>
                                          <td>{data.periority_total}</td>
                                          <td><span className="ml-3">{Math.round(data.percentage).toFixed(2)}</span></td>
                                          {/* <td><span className="ml-3">{data.since_days} days</span> </td> */}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        :(ticketsByCategory && ticketsByCategory.length > 0) ?
                          <div className="col-lg-12 col-12">
                            <div className="card">
                              <div className="card-body text-capitalize">
                                <table
                                  className="table-custom"
                                  id="by_category"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Category</th>
                                      <th>Total Tickets</th>
                                      <th>Percentage (%)</th>
                                      {/* <th>Open Since</th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ticketsByCategory.map(
                                      (data: any, index: any) => (
                                        <tr key={index}>
                                          <td><span className="ml-3">{data.cat_name}</span></td>
                                          <td>{data.category_total}</td>
                                          <td><span className="ml-3">{Math.round(data.percentage).toFixed(2)}</span></td>
                                          {/* <td><span className="ml-3">{data.since_days} days</span> </td> */}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        : null
                      }
                      </div>

                      {/* Pagination */}
                      {this.props.pageCount > 1 &&
                        <div className="row">
                          <div className="col-12 d-flex flex-row-reverse">
                            <div className="mr-4">
                              <ReactPaginate
                                //initialPage={this.props.currentPage}
                                breakLinkClassName={'page-link'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"...."}
                                breakClassName={"page-item"}
                                pageCount={this.props.pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick}
                                disableInitialCallback={true}  //Disable onPageChange callback with initial page. Default: false
                                containerClassName={"pagination"}
                                activeClassName={"active"} />
                            </div>
                          </div>
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
;
const mapStateToProps = (state: any) => {
  return {
    ticket_report: state.report.ticket_report,
    reOpenedTickets: state.report.reOpenedTickets,
    resolvedSlaTickets: state.report.resolvedSlaTickets,
    opendedClosedTickets: state.report.opendedClosedTickets,
    openedSinceDaysTickets:  state.report.openedSinceDaysTickets,
    ticketsByPeriority: state.report.ticketsByPeriority,
    ticketsByCategory: state.report.ticketsByCategory
    // currentPage: state.report.currentPage,
    // pageCount: state.report.pageCount,
    // numOfRows: state.report.numOfRows,
    // msg: state.report.msg
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    ticketListReport: function (data: any) {
      dispatch(ticketListReport(data))
    },
    ReOpenedTicketReport: function (data: any) {
      dispatch(ReOpenedTicketReport(data))
    },
    ResolvedSLATicketReport: function (data: any) {
      dispatch(ResolvedSLATicketReport(data))
    },
    OpenedClosedTicketReport: function (data: any) {
      dispatch(OpenedClosedTicketReport(data))
    },
    OpenedSinceDaysTicketReport: function (data: any) {
      dispatch(OpenedSinceDaysTicketReport(data))
    },
    TicketByPeriorityReport: function (data: any) {
      dispatch(TicketByPeriorityReport(data))
    },
    TicketByCategoryReport:function (data: any) {
      dispatch(TicketByCategoryReport(data))
    },
    logoutUser: function () {
      dispatch(logoutUser());
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Reports);